import { CircularProgress } from "@material-ui/core";
import { selectAuth, selectLoginStatus } from "app/common/auth/selectors";
import { GeneralComponent } from "app/common/slice";
import countdown from "countdown";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal, { DefaultModalProps } from ".";
import ButtonLoader from "../ButtonLoader";
import InputField from "../InputField";

// Assets
import logo from 'assets/img/landing-page/jpt-logo.svg';

interface Props extends DefaultModalProps {
}

const TwoFAModal = memo((props: Props) => {
    const dispatch  = useDispatch();
    const general   = useSelector(selectLoginStatus);
    const auth      = useSelector(selectAuth);

    const [otp, setOTP]               = useState('');
    const [isEmpty, checkEmpty]       = useState(false);
    const [isValid, checkValid]       = useState(true);
    const [validating, setValidating] = useState(false);
    const [error, setError]           = useState(false);
    const [timer, setTimer] = useState('');
    const [timeInterval, setTimeInterval] = useState<number>();

    const verificationExpiry = sessionStorage.getItem('verificationExpiry') || ""

    const validate = () => {
        setValidating(true);
        checkValid(otp.length == 6);
        checkEmpty(!otp);
        if (!otp || otp.length != 6) {
            setValidating(false);
        } else {
            dispatch(GeneralComponent.validateOTP({otp, tokenize: true}))
        }
    }

    useEffect(() => {
        if (validating && auth.access_token) {
            window.location.href = "/user-dashboard"
        }
    }, [auth])

    useEffect(() => {
        let json_auth = sessionStorage.getItem('auth')
        if (json_auth) {
            dispatch(GeneralComponent.setAuth(JSON.parse(json_auth)))
            sessionStorage.removeItem('auth')
        }
    }, [props.state])

    useEffect(() => {
        if (general.otpError) {
            setError(true);
            setValidating(false);
        }
    }, [general.otpError])
    
    // WARNING: DO NOT TOUCH THIS CODE, YOUR COMPUTER WILL EXPLODE. YOU HAVE BEEN WARNED ('_')
    useEffect(() => {
        if (verificationExpiry) {
            let date = verificationExpiry;
            if (timeInterval) {
                clearInterval(timeInterval);
            }
            let intervalId: number = setInterval(() => {
                if (date) {
                    let time = countdown(new Date(date));
                    if (time.minutes == 0 && time.seconds == 0) {
                        setTimer('')
                        clearInterval(intervalId)
                        setTimeInterval(undefined)
                        dispatch(GeneralComponent.otpSent(""))
                    } else {
                        let minutes = time.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
                        let seconds = time.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
                        let timer = `${minutes}:${seconds}`
                        setTimer(timer)
                    }
                }
            }, 1000);
            setTimeInterval(intervalId)
        }
    }, [verificationExpiry])

    useEffect(() => {
        if (moment(verificationExpiry).isSameOrBefore(moment())) {
            setTimer("")
            clearInterval(timeInterval)
            setTimeInterval(undefined)
            sessionStorage.removeItem('verificationExpiry')
        }
    }, [timer])

    return (
        <Modal disableBackdropClick disableCloseButton disableEscapeKeyDown
            state = {props.state}
            style = 'twofa action-box content-box'
        >
            <img className="logo" src={logo} alt={process.env.REACT_APP_NAME}/>
            <div className="pseudo-form">
                <h1 style={{margin: '20px auto', lineHeight: '1'}}>Verification Code</h1><br/>
                <h3>Enter the verification code<br/>sent to your mobile number.</h3>
                <InputField
                    max         = {6}
                    error       = {isEmpty || !isValid || error}
                    type        = 'text'
                    name        = 'mobile_code'
                    value       = {otp}
                    placeholder = 'e.g. EA0310'
                    style       = {{inputContainer: 'icon icon-password'}}
                    onChange    = {e => {
                        let value = e.target.value;
                        checkEmpty(!value);
                        if (!isValid && value.length == 6) {
                            checkValid(true);
                        }
                        if (!value.match(/[^0-9a-zA-Z]/i)) {
                            setOTP(value.toUpperCase());
                        }
                    }}
                />
                {
                    (isEmpty || !isValid || error) &&
                    <div className="error-container" data-error-container>
                        {
                            isEmpty? "Code is required":
                            !isValid? "Code must be 6 characters":
                            error? "Wrong verification code":
                            ""
                        }
                    </div>
                }
                {timer ?
                    <p className="twofa-timer" data-verification-countdown="true">Code expires in <span data-verification-countdown-timer>{timer}</span></p>:
                    <p className="twofa-timer" data-verification-countdown="false">Code expired {
                        general.gettingOtp?
                        <CircularProgress size={15}/>:
                        <>(<span className="pointer" data-verification-resend onClick={() => dispatch(GeneralComponent.sendOTP())}>Resend code</span>)</>
                    }</p>
                }
                <ButtonLoader hasLoader
                    loading = {validating}
                    name  = 'verify'
                    style = 'filled blue'
                    text  = 'Verify'
                    onClick = {validate}
                />
                <p className="blue pointer twofa-timer"><a href="mailto:hello@justpay.to">Contact Support</a></p>
            </div>
        </Modal>
    )
})

export default TwoFAModal