import React, { useEffect, useState } from "react";
import { memo } from "react";
import { capitalize } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { selectSignUpState } from "../selectors";
import MasterPage from "../../MasterPage";
import AccountInfo from "./AccountInfo";
import { Redirect } from "react-router";
import ProgressModal from "../ProgressModal";
import DepositoryDetails from "./DepositoryDetails";
import Documents from "./Documents";
import Review from "./Review";
import { signUpActions } from "../slice";
import Progress from "./Progress";
import { useStyles } from "./styles";

const AccountSetup = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const signUp = useSelector(selectSignUpState);
    const [openProgessModal, setOpenProgressModal] = useState(false);


    useEffect(() => setOpenProgressModal(signUp.step == 'review'), [signUp.step])
    useEffect(() => {
        setOpenProgressModal(
            signUp.step == "depository_account" ||
            signUp.step == "documents" ||
            signUp.step == "review"
        )
    }, [signUp.verificationResult, signUp.step])

    const nextFromDepository = (data) => {
        if (
            data.country &&
            data.category &&
            data.bank &&
            data.account_name &&
            data.account_number
        ) {
            dispatch(signUpActions.setDepositoryDetails(data))
            dispatch(signUpActions.setDepositoryStatus('complete'))
            dispatch(signUpActions.nextStep())
            dispatch(signUpActions.saveState())
        } else {
            console.log('FAILED')
        }
    }

    const skipDepository = () => {
        dispatch(signUpActions.setDepositoryStatus('incomplete'))
        dispatch(signUpActions.setDepositoryDetails({
            bank          : '',
            country       : '',
            account_name  : '',
            account_number: ''
        }))
        dispatch(signUpActions.nextStep())
        dispatch(signUpActions.saveState())
    }

    const backFromDepository = () => {
        dispatch(signUpActions.previousStep());
        dispatch(signUpActions.setDepositoryStatus('blank'));
        dispatch(signUpActions.setDocumentStatus('blank'));
    }

    const nextFromDocuments = (data) => {
        if (
            data?.type &&
            data?.front &&
            data?.back &&
            data?.signature &&
            data?.selfie && (
                signUp.user_info?.account_type?.type == 'individual' ||
                data.business_documents.every(doc => !!doc)
            )
        ) {
            dispatch(signUpActions.setDocumentMeta(data))
            dispatch(signUpActions.setDocumentStatus('complete'))
            dispatch(signUpActions.nextStep())
            dispatch(signUpActions.saveState())
        }
    }

    const skipDocuments = () => {
        dispatch(signUpActions.setDocumentStatus('incomplete'))
        dispatch(signUpActions.setDocumentMeta({type: ''}))
        dispatch(signUpActions.nextStep())
        dispatch(signUpActions.saveState())
    }

    const backFromDocuments = () => {
        dispatch(signUpActions.previousStep());
        dispatch(signUpActions.setDocumentStatus('blank'));
    }

    // useEffect(() => setSignature(signup.user_info?.document_meta?.signature || ''), [signup.user_info?.document_meta?.signature])

    return (
        <MasterPage title={`Sign Up - ${capitalize(signUp.user_info?.account_type?.type)}`} hasHeader>
            <section className={classes.container}>
                <form jpt-form="signup">
                    <section className="panels">
                    <Progress step={signUp.step}/>
                    {
                        signUp.step == 'account_info' ?
                        <section className="signup-content">
                            <AccountInfo/>
                        </section>:
                        signUp.step == 'depository_account' ?
                        <section className="signup-content">
                            <DepositoryDetails
                                formData = {signUp.user_info?.depository_details || {}}
                                next = {nextFromDepository}
                                skip = {skipDepository}
                                back = {backFromDepository}
                            />
                        </section>:
                        signUp.step == 'documents' ?
                        <section className="signup-content">
                            <Documents
                                formData = {signUp.user_info?.document_meta}
                                account_type = {signUp.user_info?.account_type?.type}
                                business_type = {signUp.user_info?.account_type?.business.type}
                                next = {nextFromDocuments}
                                skip = {skipDocuments}
                                back = {backFromDocuments}
                            />
                        </section>:
                        signUp.step == 'review' ?
                        <section className="signup-content">
                            <Review/>
                        </section>:
                        <Redirect to={`/signup?email=${signUp.email}`}/>
                    }
                    </section>
                    {/* <section className="page-loader">
                        <span className="spinner-container">
                            <div className="spinner"></div>
                        </span>
                    </section> */}
                </form>
                <ProgressModal state={[openProgessModal, setOpenProgressModal]}/>
            </section>
        </MasterPage>
    )
});

export default AccountSetup;