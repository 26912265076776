import success_icon from 'assets/img/success.svg';
import React, { memo } from "react";
import Modal, { DefaultModalProps } from '.';
import ButtonLoader from "../ButtonLoader";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import 'styles/sass/dialog.scss';

interface Props extends DefaultModalProps {
    title?: any
    message?: any
    className?: string
    onClose?: () => void
}

const SuccessModal = memo((props: Props) => {
    const classes = useStyles();
    return (
        <Modal
            disableEscapeKeyDown
            disableBackdropClick
            disableCloseButton
            state = {props.state}
            style = {`action-box content-box success ${props.className} ${classes.modal}`}
            dialogClassname = {classes.dialog}
        >
            <h2 className="center" style={{fontWeight: 800}}>{props.title || 'Success'}</h2>
            <img className="logo" src={success_icon} style={{width: '100px', height: 'auto'}}/>
            <p className="center" style={{margin: '1rem 0', color: 'black', fontSize: '12px', letterSpacing: 0}}>{props.message || ''}</p>
            <ButtonLoader hasLoader
                name  = 'ok'
                style = 'filled blue'
                text  = 'OK'
                onClick = {() => {
                    props.state[1](false)
                    if (props.onClose !== undefined) {
                        props.onClose();
                    }
                }}
            />
        </Modal>
    )
});

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            width: "500px",
            height: "fit-content",
            maxHeight: "400px",
        },
        modal: {
            width: "100%",
            minHeight: "300px",
            ["& .logo"]: {
                width: "120px!important",
                height: "120px!important",
                ["& + p"]: {
                    marginBottom: "1.5rem"
                }
            }
        }
    })
)

export default SuccessModal;