import React from 'react';
import TopBar from './TopBar';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.color.pureWhite,
        overflow: 'hidden',
    },
    defaultSection: {
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
        paddingTop: '64px',
    },
    paymentSection: {
        height: '100%',
        width: '100%',
    },
}));

const MainLayout = ({ currentUser, children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                !currentUser?.isTopBarHidden ?
                    <>
                        <TopBar currentuser={currentUser} />
                        <div className={classes.defaultSection}>{children}</div>
                    </> :
                    <>
                        <div className={classes.paymentSection}>{children}</div>
                    </>
            }
        </div>
    );
};

export default MainLayout;
