/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
import './locales/i18n';
import './fonts/fonts.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import * as Sentry from '@sentry/react';

import theme from './theme';
import CacheBuster from 'react-cache-buster';

import { Provider } from 'react-redux';
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
import { ThemeProvider } from '@material-ui/core';
import { Integrations } from '@sentry/tracing';
import { version } from '../package.json';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const isProduction = process.env.NODE_ENV === 'production'

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <HelmetProvider>
                <React.StrictMode>
                    <CacheBuster
                        currentVersion = {version}
                        isEnabled      = {isProduction}  //If false, the library is disabled.
                        isVerboseMode  = {false}         //If true, the library writes verbose logs to console.
                    >
                        <App />
                    </CacheBuster>
                </React.StrictMode>
            </HelmetProvider>
        </ThemeProvider>
    </Provider>,
    MOUNT_NODE,
);

// Hot reloadable translation json files
// if (module.hot) {
//     module.hot.accept(['./locales/i18n'], () => {
//         // No need to render the App again because i18next works with the hooks
//     });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
