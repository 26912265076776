import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

export interface ResetPasswordPageState {
    error?   : string
    validKey?: boolean
    result?  : boolean
}

export const initialState: ResetPasswordPageState = {}

const resetPasswordPageSlice = createSlice({
    name: 'resetPasswordPage',
    initialState,
    reducers: {
        checkKey(state, action: PayloadAction<{
            username: string,
            key     : String
        }>) {},
        validKey(state, action: PayloadAction<{
            result: boolean,
            error?: string
        }>) {
            state.validKey = action.payload.result
            state.error    = action.payload.error
        },
        resetPassword(state, action: PayloadAction<{
            access_token?: string,
            key?         : string | null,
            username?    : string | null,
            newPassword  : String
        }>) {},
        successReset(state) {
            state.result = true;
            state.error  = undefined;
        },
        errorReset(state, action: PayloadAction<{
            result: boolean,
            error: string
        }>) {
            state.result = action.payload.result;
            state.error  = action.payload.error;
        },
        clearError(state) {
            state.result   = undefined
            state.validKey = undefined
            state.error    = undefined
        }
    },
});

export const {
    actions: ResetPasswordPageActions,
    reducer,
    name: sliceKey,
} = resetPasswordPageSlice;
