export class ResponseError extends Error {
    public response: Response;
    public data;

    constructor(response: Response, data) {
        super(response.statusText);
        this.response = response;
        this.data = data;
    }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
    if (response.status === 204 || response.status === 205) {
        return null;
    }

    return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
 async function checkStatus(response: Response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    throw new ResponseError(response, await response.text());
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
    url: string,
    options?: RequestInit,
): Promise<{} | { err: ResponseError }> {
    const fetchResponse = await fetch(url, options);
    const response = await checkStatus(fetchResponse);

    return parseJSON(response);
}

export async function encryptedRequest(
    url: string,
    options?: RequestInit,
): Promise<{} | { err: ResponseError }> {
    let {algorithm, privateKey, publicKeyString: publicKey} = await generateKey();

    options = options || {headers: {}};
    options.headers = options.headers || {};
    options.headers['Client-Key'] = publicKey;
    
    let response = await request(url, options);

    return decrypt(algorithm, privateKey, response);
}

export const decrypt = async (algorithm, privateKey, response) => {
    response = b642ab(response);
    response = await window.crypto.subtle.decrypt(algorithm, privateKey, response as any);
    response = new TextDecoder().decode(response as any);
    return response;
}

export const generateKey = async () => {
    let algorithm = {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: "SHA-256",
    },
    {privateKey, publicKey} = await window.crypto.subtle.generateKey(algorithm, true, ["encrypt", "decrypt"]);
    let exportPublicKeyBytes  = await window.crypto.subtle.exportKey("spki", publicKey),
        exportPublicKeyString = btoa(String.fromCharCode(...new Uint8Array(exportPublicKeyBytes)));
    return {
        algorithm, privateKey, publicKey,
        publicKeyString: exportPublicKeyString
    }
}

const b642ab = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}
