import { Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { selectUserInfo } from "app/common/auth/selectors";
import ButtonLoader from "app/components/ButtonLoader";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip } from "recharts";
import { numberFormat } from "utils";

interface Props {
    current_datetime: Moment
    component?: boolean 
}

const options = {
    LAST_7_DAYS  : "average_7_days",
    LAST_30_DAYS : "average_30_days",
    LAST_180_DAYS: "average_180_days",
    YESTERDAY    : "yesterday",
    LIFETIME     : "average_lifetime"
}

const Sales = ({current_datetime, component}: Props) => {
    const dispatch      = useDispatch();
    const user          = useSelector(selectUserInfo);
    const userDashboard = useSelector(selectUserDashboard)
    const current_time  = current_datetime.format('h:mm A')

    const [isPremium, setIsPremium]         = useState(false);
    const [compareSelect, setCompareSelect] = useState(options.YESTERDAY);
    const [anchorEl, setAnchorEl]           = useState<null | HTMLElement>(null);

    // Manually set last tick of the Total Sales graph's text color (can't be applied through css)
    useEffect(() => document.getElementsByClassName("recharts-cartesian-axis-tick")[1]?.getElementsByTagName('text')[0]?.getElementsByTagName('tspan')[0]?.setAttribute('fill', '#0099dd'))

    useEffect(() => setIsPremium(!!(
        user?.groups?.includes('Premium') ||
        user?.groups?.includes('Partners')
    )), [user])

    const openChangePlanModal = () => dispatch(userDashboardPageActions.setChangePlan())

    const clickSalesDropdown = e => setAnchorEl(e.currentTarget);
    const selectSalesDropdown = select => {
        setCompareSelect(select || compareSelect)
        setAnchorEl(null)
    }

    return <>
        {component && <Grid container spacing={3}>
            <Grid item xs={8}>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <div style={{height: "36px"}}>
                            <Typography variant="h5" color="primary" style={{padding: "0 8px", lineHeight: isPremium? "36px": "normal"}}>Today</Typography>
                        </div>
                        <Typography variant="h3" color="primary" style={{display: "inline-block"}}> ₱{ numberFormat(userDashboard?.overview?.salesOverview?.today || 0) }</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {
                            isPremium?
                            <Typography variant="h5">
                                <Button onClick={clickSalesDropdown} style={{font: "inherit", letterSpacing: "inherit", textTransform: "initial"}}>
                                    <span style={{color: "#484848"}}>{
                                        compareSelect == options.YESTERDAY    ? "Yesterday":
                                        compareSelect == options.LAST_7_DAYS  ? "Average of Last 7 days":
                                        compareSelect == options.LAST_30_DAYS ? "Average of Last 30 days":
                                        compareSelect == options.LAST_180_DAYS? "Average of Last 180 days":
                                        compareSelect == options.LIFETIME     ? "Lifetime Average": ""
                                    } <ExpandMore htmlColor="#484848"/></span>
                                </Button>
                                <Menu keepMounted
                                    anchorEl = {anchorEl}
                                    open     = {Boolean(anchorEl)}
                                    onClose  = {() => setAnchorEl(null)}
                                >
                                    <MenuItem selected={compareSelect == options.YESTERDAY}     onClick={() => selectSalesDropdown(options.YESTERDAY)}>Yesterday</MenuItem>
                                    <MenuItem selected={compareSelect == options.LAST_7_DAYS}   onClick={() => selectSalesDropdown(options.LAST_7_DAYS)}>Average of Last 7 days</MenuItem>
                                    <MenuItem selected={compareSelect == options.LAST_30_DAYS}  onClick={() => selectSalesDropdown(options.LAST_30_DAYS)}>Average of Last 30 days</MenuItem>
                                    <MenuItem selected={compareSelect == options.LAST_180_DAYS} onClick={() => selectSalesDropdown(options.LAST_180_DAYS)}>Average of Last 180 days</MenuItem>
                                    <MenuItem selected={compareSelect == options.LIFETIME}      onClick={() => selectSalesDropdown(options.LIFETIME)}>Lifetime Average</MenuItem>
                                </Menu>
                            </Typography>:
                            <div style={{height: "36px"}}>
                                <Typography variant="h5" style={{padding: "6px 8px"}}>Yesterday</Typography>
                            </div>
                        }
                        <Typography variant="h3" style={{display: "inline-block"}}> ₱{ numberFormat(userDashboard?.overview?.salesOverview?.[compareSelect] || 0) }</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h3" align="right">Total Sales</Typography>
            </Grid>
        </Grid>}
        {
            userDashboard?.overview?
            <Grid container style={{position: "relative"}}>
                <ResponsiveContainer width={'100%'} height={180} className={isPremium? '': 'blur'} >
                    <LineChart
                        data   = {userDashboard?.overview?.hourlySales || []}
                        margin = {{
                            top: 30,
                            right: 60,
                            left: 60,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="4 0" horizontal={false}  />
                        <CartesianGrid />
                        {component && <XAxis dataKey="name" ticks={['12:00 AM', userDashboard?.overview?.hourlySales?.at(-1).name]} />}
                        <YAxis hide/>
                        
                        <Tooltip />
                        <Line type="linear" name="Today" dataKey="today" stroke="#0099DD" />
                    </LineChart>
                </ResponsiveContainer>
                {!isPremium && <ButtonLoader
                    style   = "filled round blue upgrade-button"
                    text    = "Upgrade"
                    onClick = {openChangePlanModal}
                />}
            </Grid>:
            <Grid container justifyContent="center">
                <CircularProgress style={{margin: "4rem auto"}}/>
            </Grid>
        }
    </>
}

export default Sales