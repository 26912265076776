/* eslint-disable */
import { PayloadAction } from '@reduxjs/toolkit';
import Partner from 'app/common/types/Partner';
import { createSlice } from 'utils/@reduxjs/toolkit';

export interface LandingPage{
    announcement?    : string
    partners         : Partner[]
    loadingPartners? : boolean
    invalidEmail?    : boolean
    checkEmailResult?: boolean
    signUpResult?    : boolean
    apiError?: {
        code: number,
        body: string
    }
}

export const initialState: LandingPage = {
    partners: []
};

const landingPageSlice = createSlice({
    name: 'landingPage',
    initialState,
    reducers: {
        getAnnouncement() {},
        loadedAnnouncement(state, action: PayloadAction<string>) {
            state.announcement = action.payload
        },
        getPartners(state) {
            state.loadingPartners = true
        },
        loadedPartners(state, action: PayloadAction<Partner[]>) {
            state.loadingPartners = false
            state.partners        = action.payload
            state.apiError        = undefined
        },
        getEarlyAccess(state, action: PayloadAction<string>) {
            state.invalidEmail     = undefined
            state.checkEmailResult = undefined
            state.signUpResult     = undefined
        },
        earlyAccessResult(state, action: PayloadAction<{
            invalidEmail?: boolean,
            checkEmailResult?: boolean,
            signUpResult?: boolean
        }>) {
            state.invalidEmail     = action.payload.invalidEmail
            state.checkEmailResult = action.payload.checkEmailResult
            state.signUpResult     = action.payload.signUpResult
        }
    },
});

export const {
    actions: landingPageActions,
    reducer,
    name: sliceKey,
} = landingPageSlice;
