import { Grid } from "@material-ui/core";
import { selectUserInfo } from "app/common/auth/selectors";
import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import Modal from "app/components/Modal";
import ErrorModal from "app/components/Modal/ErrorModal";
import SuccessModal from "app/components/Modal/SuccessModal";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import imageCompression from "browser-image-compression";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "styles/sass/user-dashboard.scss"
import Swal from "sweetalert2";

class Category {
    icon_url   : string = ""
    name       : string = ""
    description: string = ""
    api_version: string = ""
    error?     : boolean

    constructor(api_version) {
        this.api_version = api_version
    }
}

class Subcategory {
    biller_name      : string = ""
    biller_code      : string = ""
    service_code     : string = ""
    description      : string = ""
    page_description : string = ""
    slug             : string = ""
    api_version      : string = ""
    icon_url         : string = ""
    background_url   : string = ""
    category_slug    : string = ""
    error?           : boolean
    
    constructor(api_version) {
        this.api_version = api_version
    }
}

const AddModal = memo(() => {

    const dispatch = useDispatch();
    
    const username      = useSelector(selectUserInfo)?.username
    const userDashboard = useSelector(selectUserDashboard)
    const oldCategories = userDashboard.categories
    const api_version   = userDashboard.api_version

    const newCategory = new Category(api_version)
    const newSubcategory   = new Subcategory(api_version)

    const [open, setOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const [add, setAdd] = useState<'category' | 'subcategory'>('category')

    const [categories,  setCategories]       = useState<Category[]>([{...newCategory}]);
    const [subcategories,  setSubcategories] = useState<Subcategory[]>([{...newSubcategory}]);

    const processImage = async (file, callback) => {
        if (!file) {
            callback("")
            return;
        }
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 5,
            useWebWorker: true
        });
        const imageUri = await imageCompression.getDataUrlFromFile(compressedFile)
        callback(imageUri)
    }

    const reset = () => {
        setCategories([{...newCategory}])
        setSubcategories([{...newSubcategory}])
        setAdd('category')
    }

    const categorySetter = (index, key, value) => {
        const newCategories = [...categories]
        const newCategory = {...newCategories[index]}
        newCategory[key] = value
        newCategories[index] = newCategory
        setCategories(newCategories)
    }

    const billerSetter = (index, key, value) => {
        const newSubcategories = [...subcategories]
        const newSubcategory = {...newSubcategories[index]}
        newSubcategory[key] = value
        newSubcategories[index] = newSubcategory
        setSubcategories(newSubcategories)
    }

    const addCategory = () => {
        const newCategories = [...categories]
        newCategories.push({...newCategory})
        setCategories(newCategories)
    }

    const addSubcategory = () => {
        const newSubcategories = [...subcategories]
        newSubcategories.push({...newSubcategory})
        setSubcategories(newSubcategories)
    }

    const save = () => {
        switch (add) {
            case 'category'   : dispatch(userDashboardPageActions.addCategories(categories)); break;
            case 'subcategory': dispatch(userDashboardPageActions.addSubcategories(subcategories)); break;
            default: break;
        }
    }

    useEffect(() => {
        if (userDashboard.addingMultiInput === false) {
            if (userDashboard.existingBillers?.length != subcategories.length) {
                setSuccess(true)
            }
            if ((userDashboard.existingBillers?.length || 0) > 0) {
                setError(true)
            }
        }
    }, [userDashboard.addingMultiInput])

    return <>
        <SuccessModal state = {[success, setSuccess]} message = {`Added new ${
            add == "category"    && categories.length > 1? "categories":
            add == "subcategory" && subcategories.length    > 1? `subcategories`:
            add
        }`} onClose = {() => {
            dispatch(userDashboardPageActions.resetMultiInput())
            dispatch(userDashboardPageActions.getCategories())
        }}/>
        <ErrorModal state = {[error, setError]} title="Failed" message = {`Biller/s [${userDashboard.existingBillers}] already exists`} onClose={() => {
            dispatch(userDashboardPageActions.resetMultiInput())
            dispatch(userDashboardPageActions.failedMultiInput([]))
        }}/>
        <ButtonLoader
            style   = "filled blue is-uppercase"
            text    = "Add"
            onClick = {() => setOpen(true)}
        />
        <Modal
            disableBackdropClick
            disableEscapeKeyDown
            state = {[open, setOpen]}
            style = "larger_modal multi-input-add-modal"
            onClose={reset}
        >
            <div className="category pseudo-form input-group">
                <div className="category-row">
                    <section className="checkbox-container">
                        {api_version == "1" && <InputField
                            type     = "radio"
                            name     = "add"
                            checked  = {add == "category"}
                            onChange = {() => setAdd("category")}
                        />}
                    </section>
                    <div style={{flexGrow: 1}}>
                    {
                        categories.map(
                            (category, i) => (
                                <div style={{display: 'flex', marginBottom: '1rem'}}>
                                    <div className="category-image" onClick = {(event) => event.stopPropagation()}>
                                        <InputField
                                            name     = {`icon_url_${i}`}
                                            type     = "file"
                                            accept   = 'image/jpeg, image/jpg, image/png'
                                            style    = {{container: 'image-upload'}}
                                            value    = {category.icon_url}
                                            onChange = {e => e.target.files && processImage(e.target.files[0], image => categorySetter(i, "icon_url", image))}
                                            readonly = {add != 'category'}
                                        />
                                    </div>
                                    <div className="category-fields-container" onClick = {(event) => event.stopPropagation()}>
                                        <InputField
                                            type        = "text"
                                            placeholder = "Category"
                                            style       = {{container: 'text-field'}}
                                            value       = {category.name}
                                            onChange    = {e => categorySetter(i, "name", e.target.value)}
                                            readonly    = {add != 'category'}
                                        />
                                        <InputField
                                            type        = "text"
                                            placeholder = "Description"
                                            style       = {{container: 'text-field'}}
                                            value       = {category.description}
                                            onChange    = {e => categorySetter(i, "description", e.target.value)}
                                            readonly    = {add != 'category'}
                                        />
                                    </div>
                                </div>
                            )
                        )
                    }
                    </div>
                </div>
                <ButtonLoader
                    style    = "filled blue"
                    text     = "ADD MORE"
                    disabled = {add != 'category'}
                    onClick  = {addCategory}
                />
            </div>
            {api_version == "1" && <div className="category pseudo-form input-group">
                <div className="subcategory-row">
                    <section className="checkbox-container">
                        <InputField
                            type     = "radio"
                            name     = "add"
                            checked  = {add == "subcategory"}
                            onChange = {() => setAdd("subcategory")}
                        />
                    </section>
                    <div style={{flexGrow: 1}}>
                    {
                        subcategories.map(
                            (biller, i) => (
                                <Grid container>
                                    <Grid item xs={6} container style={{padding: "0 0.5rem"}}>
                                        <Grid item xs={5}>
                                            <InputField
                                                name     = {`biller_icon_${i}`}
                                                type     = "file"
                                                accept   = 'image/jpeg, image/jpg, image/png'
                                                style    = {{container: 'biller-logo'}}
                                                value    = {biller.icon_url}
                                                onChange = {e => e.target.files && processImage(e.target.files[0], image => billerSetter(i, "icon_url", image))}
                                                readonly = {add != 'subcategory'}
                                            />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <InputField
                                                name     = {`biller_bg_${i}`}
                                                type     = "file"
                                                accept   = 'image/jpeg, image/jpg, image/png'
                                                style    = {{container: 'biller-bg'}}
                                                value    = {biller.background_url}
                                                onChange = {e => e.target.files && processImage(e.target.files[0], image => billerSetter(i, "background_url", image))}
                                                readonly = {add != 'subcategory'}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="input-group">
                                            <InputField
                                                type        = "text"
                                                placeholder = {`${username == 'bayadcenter'? 'Biller ' : ''}Code`}
                                                style       = {{container: 'full-width'}}
                                                value       = {biller.biller_code}
                                                onChange    = {e => billerSetter(i, "biller_code", e.target.value)}
                                                readonly = {add != 'subcategory'}
                                            />
                                            <InputField
                                                type     = "text"
                                                placeholder = "Service Code"
                                                style    = {{container: 'full-width'}}
                                                value    = {biller.service_code}
                                                onChange    = {e => billerSetter(i, "service_code", e.target.value)}
                                                readonly = {add != 'subcategory'}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} container justifyContent="space-between"  style={{padding: "0 0.5rem"}}>
                                        <Grid item xs={12}>
                                            <InputField
                                                type        = "text"
                                                placeholder = {`${username == 'bayadcenter'? 'Biller ' : ''}Name`}
                                                style       = {{container: 'full-width'}}
                                                value       = {biller.biller_name}
                                                onChange    = {e => billerSetter(i, "biller_name", e.target.value)}
                                                readonly    = {add != 'subcategory'}
                                            />
                                            <InputField
                                                type        = "text"
                                                placeholder = "Description"
                                                style       = {{container: 'full-width'}}
                                                value       = {biller.description}
                                                onChange    = {e => billerSetter(i, "description", e.target.value)}
                                                readonly    = {add != 'subcategory'}
                                            />
                                            <InputField
                                                type        = "text"
                                                placeholder = "Page Description"
                                                style       = {{container: 'full-width'}}
                                                value       = {biller.page_description}
                                                onChange    = {e => billerSetter(i, "page_description", e.target.value)}
                                                readonly    = {add != 'subcategory'}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="input-group">
                                            <InputField
                                                type           = "select"
                                                placeholder    = "Select Category"
                                                style          = {{container: 'full-width'}}
                                                value          = {biller.category_slug}
                                                onSelectChange = {e => billerSetter(i, "category_slug", e.target.value)}
                                                readonly       = {add != 'subcategory'}
                                                options        = {oldCategories?.map(
                                                    cat => (
                                                        <option value={cat.slug} selected={cat.slug == biller.category_slug}>
                                                            {cat.name}
                                                        </option>
                                                    )
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        )
                    }
                    </div>
                </div>
                <ButtonLoader
                    style    = "filled blue"
                    text     = "ADD MORE"
                    disabled = {add != 'subcategory'}
                    onClick  = {addSubcategory}
                />
            </div>}
            <ButtonLoader hasLoader
                loading  = {userDashboard.addingMultiInput}
                disabled = {!add}
                style    = "filled blue save-button"
                text     = "SAVE"
                onClick  = {save}
            />
        </Modal>
    </>
})

export default AddModal