import { PayloadAction } from '@reduxjs/toolkit';
import {
    CONFIRM_DEPOSIT,
    ONBOARDING_USER
} from 'app/common/core_api/resources';
import { url } from 'inspector';
import { delay, call, put, takeLatest, select } from 'redux-saga/effects';
import { RootState } from 'types';
import { request, ResponseError } from 'utils/request';
import { bankVerificationActions } from './slice';

export function* getBankDetails(action: PayloadAction<{username: string, key: string}>) {
    yield delay(1000);
    try {
        let options: RequestInit = {
            method: "GET",
            headers: {'Content-type': 'application/json'}
        }
        const url = new URL(ONBOARDING_USER.replace(':username', action.payload.username))
        url.searchParams.append('key', action.payload.key);
        let user = yield call(request, url.toString(), options);
        yield put(bankVerificationActions.setBankDetails({
            ...user.user_info.depository_details,
            email: user.email
        }))
    } catch (err) {
        console.log(err)
    }
}

export function* confirmDeposit(action: PayloadAction<{username_email: string, amount: number}>) {
    yield delay(1000);
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(action.payload)
        }
        const response = yield call(request, CONFIRM_DEPOSIT, options);
        if (response?.error) {
            yield put(bankVerificationActions.confirmDepositResult(false))
            yield put(bankVerificationActions.confirmDepositError(response.error))
        } else {
            yield put(bankVerificationActions.confirmDepositResult(true))
        }
    } catch (err) {
        console.log(err)
    }
}

function* bankVerificationSaga() {
    yield takeLatest(bankVerificationActions.confirmDeposit.type, confirmDeposit)
}

export default bankVerificationSaga;