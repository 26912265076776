import React, { memo, useEffect, useState } from 'react'

interface Props {
    pages  : number[]
    page   : number
    setPage: (value) => void
}
const TablePagination = memo(({pages, page, setPage}: Props) => {

    const [shownPages, setShownPages] = useState<any[]>([])

    const lastPage = pages.length
    useEffect(() => {
        const start = Math.floor((page-1)/5)*5
        const end   = start + 6
        const shownPages = pages.slice(start, end)
    
        setShownPages(shownPages)
    }, [page, pages])

    return !lastPage? <></>: <div className="dataTables_paginate paging_simple_numbers">
        <a className="paginate_button previous" onClick={() => setPage(page-1 > 0? page-1: page)}></a>
        <span>
            {!shownPages.includes(1) && <>
                <a
                    className = {`paginate_button ${page == 1 ? 'current' : ''}`}
                    onClick   = {() => setPage(1)}>
                    1
                </a>
                <a className = "paginate_button">
                    ...
                </a>
            </>}
            {shownPages.map(i =>
            <a
                className = {`paginate_button ${page == i ? 'current' : ''}`}
                onClick   = {() => setPage(i)}>
                {i}
            </a>
            )}
            {!shownPages.includes(lastPage) && <>
                <a className = "paginate_button">
                    ...
                </a>
                <a
                    className = {`paginate_button ${page == lastPage ? 'current' : ''}`}
                    onClick   = {() => setPage(lastPage)}>
                    {lastPage}
                </a>
            </>}
        </span>
        <a className="paginate_button next" onClick={() => setPage(page+1 <= lastPage? page+1: page)}></a>
    </div>
})
export default TablePagination