import ButtonLoader from "app/components/ButtonLoader";
import DropdownField from "app/components/DropdownField";
import InputField from "app/components/InputField";
import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatMobile } from "utils";
import { business_types, business_categories } from "utils/options";
import { selectIP, selectSignUpState } from "../selectors";
import { signUpActions } from "../slice";
import { useStyles } from "./styles";

const BusinessModal = memo(({state}: DefaultModalProps) => {
    const dispatch = useDispatch();
    const classes  = useStyles();
    const ip_info  = useSelector(selectIP);
    const signUp   = useSelector(selectSignUpState);

    const required_field = "This field is required";
    const invalid_format = "Invalid Format";
    const [errorBusinessType    , setErrorBusinessType]     = useState(false);
    const [errorBusinessCategory, setErrorBusinessCategory] = useState(false);
    const [errorBusinessName    , setErrorBusinessName]     = useState(false);
    const [errorFirstName       , setErrorFirstName]        = useState(false);
    const [errorLastName        , setErrorLastName]         = useState(false);
    const [errorMobileNumber    , setErrorMobileNumber]     = useState(false);
    const [errorVerification    , setErrorVerification]     = useState(false);

    const [businessType    , setBusinessType]           = useState(signUp.user_info?.account_type?.business.type || '');
    const [businessCategory, setBusinessCategory]       = useState(signUp.user_info?.account_type?.business.category || '');
    const [businessSubcategory, setBusinessSubcategory] = useState(signUp.user_info?.account_type?.business.subcategory || '');
    const [businessName    , setBusinessName]           = useState(signUp.user_info?.name || '');
    const [firstName       , setFirstName]              = useState(signUp.firstName || '');
    const [lastName        , setLastName]               = useState(signUp.lastName || '');
    const [mobileNumber    , setMobileNumber]           = useState(signUp.user_info?.mobile_number?.number || '');
    const [checkedVerification, setCheckVerification]   = useState(false);

    const validateFields = () => {
        setErrorBusinessType    (businessType  === '');
        setErrorBusinessCategory(businessCategory  === '');
        setErrorBusinessName    (businessName  === '');
        setErrorFirstName       (firstName  === '');
        setErrorLastName        (lastName  === '');
        setErrorMobileNumber    (mobileNumber  === '' || mobileNumber.length != 11);
        setErrorVerification    (!checkedVerification);

        return (
            businessType &&
            businessCategory &&
            businessCategory &&
            firstName &&
            lastName &&
            mobileNumber &&
            mobileNumber.length == 11 &&
            checkedVerification
        )
    }

    const submit = () => {
        if (!validateFields()){ return;}
        if (
            errorBusinessType ||
            errorBusinessCategory ||
            errorBusinessName ||
            errorFirstName ||
            errorLastName ||
            errorMobileNumber ||
            errorVerification
        ){ return;}
        dispatch(signUpActions.setAccountType('business'))
        dispatch(signUpActions.setBasicInfo({
            firstName, lastName, mobileNumber: {
                prefix: "+63",
                number: mobileNumber
            }
        }))
        dispatch(signUpActions.setBusinessInfo({
            businessType,
            businessCategory,
            businessSubcategory,
            businessName
        }))
        dispatch(signUpActions.nextStep())
        dispatch(signUpActions.saveState())
    }
    return (
        <Modal
            disableBackdropClick
            disableEscapeKeyDown
            state                = {state}
            containerClassname   = {`modal-container account-selection`}
            dialogClassname      = {classes.modal}
            modalClassname       = {classes.modalContainer}
            closeButtonClassname = {classes.modalCloseButton}
        >
            <div className="pseudo-form" jpt-form="business_form">
                <section>
                    <h1 className={classes.modalHeader}>Get paid now!</h1>
                    <p className={classes.modalSubheader}>See yourself why businesses love JustPayto. Create an account for free. No commitments or long-term contracts.</p>
                </section>
                <section className={classes.modalForm}>
                    <h3 className={classes.modalFormHeader}>Create an Account</h3>
                    <InputField
                        type        = "select"
                        name        = "business_type"
                        placeholder = "Select Business Type"
                        options     = {Object.keys(business_types).map(type => <option value={type}>{business_types[type]}</option>)}
                        label       = "Business Type"
                        style       = {{
                            container: `full-width ${classes.modalFormInputContainer}`,
                            input    : classes.modalFormInput
                        }}
                        value          = {businessType}
                        error          = {errorBusinessType}
                        error_message  = {errorBusinessType ? required_field : ''}
                        onSelectChange = {e => {
                            setBusinessType(e.target.value)
                            setErrorBusinessType(e.target.value.trim() === '')
                        }}
                    />
                    <DropdownField
                        label       = "Business Category"
                        placeholder = "Select Business Category"
                        style       = {{
                            container: `full-width ${classes.modalFormInputContainer}`,
                            input    : classes.modalFormInput
                        }}
                        value         = {businessSubcategory}
                        data          = {business_categories}
                        error         = {errorBusinessCategory}
                        error_message = {errorBusinessCategory ? required_field : ''}
                        onChange      = {(value, selected) => {
                            setBusinessCategory(value);
                            setBusinessSubcategory(selected);
                            setErrorBusinessCategory(value.trim() === '')
                        }}
                    />
                    <InputField
                        type        = "text"
                        name        = "business_name"
                        label       = "Business Name"
                        placeholder = "Store/Company/Business Name"
                        style       = {{
                            container: `full-width ${classes.modalFormInputContainer}`,
                            input    : classes.modalFormInput
                        }}
                        value         = {businessName}
                        error         = {errorBusinessName}
                        error_message = {errorBusinessName ? required_field : ''}
                        onChange      = {e => {
                            setBusinessName(e.target.value)
                            setErrorBusinessName(e.target.value.trim() === '')
                        }}
                    />
                    <div className="userinfo">
                        <InputField
                            type        = "text"
                            name        = "first_name"
                            label       = "First Name"
                            placeholder = "Juan"
                            style       = {{
                                container: classes.modalFormInputContainer,
                                input    : classes.modalFormInput
                            }}
                            error         = {errorFirstName}
                            value         = {firstName}
                            error_message = {errorFirstName ? required_field : ''}
                            onChange      = {e => {
                                setFirstName(e.target.value)
                                setErrorFirstName(e.target.value.trim() === '')
                            }}
                        />
                        <InputField
                            type        = "text"
                            name        = "last_name"
                            label       = "Last Name"
                            placeholder = "Dela Cruz"
                            style       = {{
                                container: classes.modalFormInputContainer,
                                input    : classes.modalFormInput
                            }}
                            error         = {errorLastName}
                            value         = {lastName}
                            error_message = {errorLastName ? required_field : ''}
                            onChange      = {e => {
                                setLastName(e.target.value)
                                setErrorLastName(e.target.value.trim() === '')
                            }}
                        />
                        <InputField readonly
                            type  = "email"
                            name  = "email"
                            label = "Your Email"
                            style = {{
                                container: `valid ${classes.modalFormInputContainer}`,
                                input    : classes.modalFormInput
                            }}
                            value = {signUp.email}
                        />
                        <InputField
                            type        = "mobile"
                            name        = "mobile_number"
                            label       = "Mobile Number"
                            country     = "PH"             //{ip_info?.country_code}
                            placeholder = "912 345 6789"
                            style       = {{
                                container: classes.modalFormInputContainer,
                                input    : classes.modalFormInput
                            }}
                            value         = {formatMobile(mobileNumber.substring(1))}
                            error         = {errorMobileNumber}
                            error_message = {errorMobileNumber ? (!mobileNumber || mobileNumber == '0'? required_field: invalid_format): ''}
                            onChange      = {e => {
                                const value = "0"+e.target.value.replaceAll(' ', '').trim();
                                setMobileNumber(value)
                                setErrorMobileNumber(value === '' || value.length != 11)
                            }}
                        />
                    </div>
                    <InputField
                        type  = "checkbox"
                        name  = "representative_terms"
                        title = "I verify that I am the official representative of this company and have the rights to act on behalf of the company in the creation of this page."
                        style = {{
                            container: `full-width representative_terms`,
                            input    : classes.modalFormInput
                        }}
                        checked  = {checkedVerification}
                        error    = {errorVerification}
                        onChange = {e => {
                            setCheckVerification(e.target.checked)
                            setErrorVerification(!e.target.checked)
                        }}
                    />
                    <ButtonLoader hasLoader submit
                        name  = 'business_form_submit'
                        style = {`filled blue ${classes.button} ${classes.modalButton}`}
                        text  = 'Continue'
                        onClick = {() => submit()}
                    />
                    <div className={classes.modalFooter}>
                        By clicking "Continue" you agree to JustPayto's
                        <a target="pdf-frame" href={`${process.env.REACT_APP_S3_JUSTPAYTO_WEB_ASSETS_URL}/terms-of-service.pdf`} className={classes.modalLinks}> Terms of Service</a> and
                        <a target="pdf-frame" href={`${process.env.REACT_APP_S3_JUSTPAYTO_WEB_ASSETS_URL}/privacy-policy.pdf`} className={classes.modalLinks}> Privacy Policy</a>.
                    </div>
                </section>
            </div>
        </Modal>
    )
})

export default BusinessModal;