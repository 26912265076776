import { selectUserInfo } from "app/common/auth/selectors";
import InputField from "app/components/InputField";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { business_types, clients } from "utils/options";
import Section from "./Section";

interface Form {
    businessType  : string
    name          : string
    address       : string
    pickup_location: string
}

interface Props {
    onChange: (form: Form) => void
}

const BusinessInformation = memo(({onChange}: Props) => {
    const user = useSelector(selectUserInfo)
    const [businessType, setBusinessType]     = useState(user?.account_type?.business.type || "individuals_online_sellers")
    const [businessName, setBusinessName]     = useState(user?.name || "")
    const [address, setAddress]               = useState(user?.address || "");
    const [pickupLocation, setPickupLocation] = useState(user?.pickup_location || "");

    const isClient = clients.includes(user?.username || "")
    const isApi    = user?.username && user?.groups?.includes(user.username)

    // const [businessCategory, setBusinessCategory]       = useState(user?.account_type?.business.category);
    // const [businessSubcategory, setBusinessSubcategory] = useState(user?.account_type?.business.subcategory);
    const update = () => onChange({
        businessType,
        name: businessName,
        address,
        pickup_location: pickupLocation
    })
    useEffect(() => update(), [])
    useEffect(() => update(), [
        businessType,
        businessName,
        address,
        pickupLocation
    ])

    return <Section title="BUSINESS INFORMATION">
        <InputField readonly
            type        = "select"
            name        = "business_type"
            placeholder = "Business Type"
            options     = {Object.keys(business_types).map(type => <option value={type}>{business_types[type]}</option>)}
            label       = "Business Type"
            style       = {{container: 'full-width'}}
            value       = {businessType}
            valid       = {!!businessType}
            onChange    = {e => setBusinessType(e.target.value)}
        />
        {/* <DropdownField
            label       = "Business Category"
            placeholder = "Select Business Category"
            value       = {businessSubcategory}
            data        = {business_categories}
            onChange    = {(value, selected) => {
                setBusinessCategory(value);
                setBusinessSubcategory(selected);
            }}
        /> */}
        <InputField
            type        = "text"
            name        = "business_name"
            label       = "Business Name"
            placeholder = "Store/Company/Business Name"
            style       = {{container: 'full-width'}}
            value       = {businessName}
            valid       = {!!businessName}
            onChange    = {e => setBusinessName(e.target.value)}
        />
        {!isClient && !isApi && <InputField
            checkAddress     = {address}
            textAreaCheckbox = {user?.account_type?.type === 'business' ? "Use this address as pickup location": undefined}
            textAreaChecked  = {address == pickupLocation}
            type             = "textarea"
            name             = "address"
            label            = "Address (optional)"
            placeholder      = "Your current complete address"
            value            = {address}
            style            = {{container: `full-width ${address? 'valid': ''}`}}
            onTextAreaChange = {e => setAddress(e.target.value)}
            onTextAreaCheckboxChange = {() => {
                setPickupLocation(address != pickupLocation? address: user?.pickup_location || "")
            }}
        />}
    </Section>
})

export default BusinessInformation