import React, { memo } from "react";
import Modal, { DefaultModalProps } from '.';
import ButtonLoader from "../ButtonLoader";
import success_icon from 'assets/img/success.svg';

import 'styles/sass/dialog.scss';

interface Props extends DefaultModalProps {
    title?     : string
    message?   : string
    className? : string
    buttonText?: string
    onClose?   : () => void
}

const InfoModal = memo((props: Props) => {
    return (
        <Modal
            disableEscapeKeyDown
            disableBackdropClick
            disableCloseButton
            state = {props.state}
            style = {`action-box content-box success ${props.className}`}
        >
            <h1 className="center">{props.title}</h1>
            <img className="logo" src={success_icon} alt="Error Icon"/>
            <p className="center">{props.message || ''}</p>
            <ButtonLoader hasLoader
                name  = 'ok'
                style = 'filled blue'
                text  = {props.buttonText || 'OK'}
                onClick = {() => {
                    props.state[1](false)
                    if (props.onClose !== undefined) {
                        props.onClose();
                    }
                }}
            />
        </Modal>
    )
});

export default InfoModal;