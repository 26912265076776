import React, { memo, useEffect, useState } from "react";

// Styles
import 'styles/sass/general.scss';

interface FooterLinkSetProps {
    title: string
    links: object
}

export const FooterLinkSet = memo((props: FooterLinkSetProps) => {
    const {title, links}      = props;
    const [status, setStatus] = useState(false)
    const click               = () => setStatus(!status)

    return (
        <section className="link-set">
            <div data-menu-toggle onClick={click}>{title}</div>
            <ul data-menu className={status? "show": ""}>
            {Object.keys(links).map(
                (label, i) => (
                    <li key={i}>
                        <a href={links[label]}>{label}</a>
                    </li>
                )
            )}
            </ul>
        </section>
    )
})

const Footer = memo(() => {
    const [year, setYear] = useState('');
    const footerLinkSets = {
        'Platform': {
            'Infrastracture'    : '#',
            'Security'          : '#',
            'PCI Compliance'    : '#',
            'Regulatory'        : '#',
            'Certifications'    : '#',
            'Licenses'          : '#',
            'Terms & Conditions': '/terms-conditions',
            'Privacy Policy'    : '#',
            'Disclaimers'       : '#',
            'Developers'        : '#',
            'API Status'        : '#',
        },
        'Solutions': {
            'Payment Platform'       : '#',
            'Payment Address'        : '#',
            'Payment Page'           : '#',
            'Payment Button'         : '#',
            'Invoicing'              : '#',
            'Mass Payouts'           : '#',
            'Mobile App'             : '#',
            'Cash Card'              : '#',
            'Payment Gateway'        : '#',
            'Developer API'          : '#',
            'Pickup/Delivery'        : '#',
            'Buyer/Seller Protection': '#',
            'Capital'                : '#',
            'Business Starter'       : '#',
            'Business Services'      : '#',
        },
        'Industries': {
            'Online Sellers'       : '#',
            'Professional Services': '#',
            'Bazaars'              : '#',
            'Retail'               : '#',
            'Restaurants'          : '#',
            'Transportation'       : '#',
            'Non-Profit'           : '#',
            'Government'           : '#',
            'Billers'              : '#',
            'Schools'              : '#',
        },
        'Resources': {
            'How it Works'     : '#',
            'Get Started'      : '#',
            'Send Money Safely': '#',
            'Rates & Fees'     : '#',
            'FAQs'             : '/faqs',
            'Guides'           : '#',
            'Support'          : '#',
            'API Reference'    : '#',
            'Locations'        : '#',
        }
    }
    useEffect(() => {
        fetch('/').then(
            resp => resp.headers.get('date') || ''
        ).then(
            date => new Date(date)
        ).then(
            date => setYear(date.getFullYear().toString())
        )
    }, [])
    const [companyStatus, setCompanyStatus] = useState(false)
    const clickCompany = () => setCompanyStatus(!companyStatus)
    return (
    <footer className="footer">
        <section className="container">
            <section className="links">
                <section className="link-set">
                    <div data-menu-toggle onClick={clickCompany}>Company</div>
                    <ul data-menu className={companyStatus? "show": ""}>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Careers</a></li>
                        <li><a href="#">Ecosystem</a></li>
                        <li><a href="#">Contact Us</a></li>
                        <li><a href="https://www.facebook.com/JustPayto-PH-2289070334640914/" target="_blank" rel="noreferrer">Facebook</a></li>
                        <li><a href="https://www.x.com/justpayto" target="_blank" rel="noreferrer">X</a></li>
                        <li><a href="https://www.instagram.com/justpayto" target="_blank" rel="noreferrer">Instagram</a></li>
                        <li><a href="https://www.linkedin.com/company/justpayto/" target="_blank" rel="noreferrer">LinkedIn</a></li>
                    </ul>
                </section>
                {Object.keys(footerLinkSets).map(
                    (title, i) => <FooterLinkSet key={i} title={title} links={footerLinkSets[title]}/>
                )}
            </section>
            <hr/>
            <p>&copy; {year} JustPayto, Inc.</p>
        </section>
    </footer>)
})

export default Footer;