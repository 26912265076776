import React, { memo, useEffect, useState } from 'react';

// Assets
import logo from 'assets/img/landing-page/jpt-logo.svg';

// Components
import Modal, { DefaultModalProps } from '.';
import InputField   from '../InputField';
import ButtonLoader from '../ButtonLoader';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralComponent } from 'app/common/slice';
import { selectAuth, selectLoginStatus, selectUser, selectUserInfo } from 'app/common/auth/selectors';

import { partners } from 'app/containers/HomePage';
import ForceResetPassword from 'app/containers/UserAuthentication/ForceResetPassword';
interface Props extends DefaultModalProps {
    twoFAModalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const LogInModal = memo((props: Props) => {
    const dispatch    = useDispatch();
    const auth        = useSelector(selectAuth);
    const loginStatus = useSelector(selectLoginStatus);

    const [email, setEmail]                 = useState('');
    const [password, setPassword]           = useState('');
    const [rememberMe, setRememberMe]       = useState(false);
    const [loggingIn, setLoggingIn]         = useState<boolean|undefined>();
    const [emptyUsername, setEmptyUsername] = useState(false);
    const [emptyPassword, setEmptyPassword] = useState(false);

    const [resetmodal, setResetModal] = useState(false);

    const login = () => {
        if (email && password) {
            setLoggingIn(true)
            dispatch(GeneralComponent.login({email, password, rememberMe, tokenize: true}))
        }
    }

    //  Login Success
    useEffect(() => {
        if (loggingIn === true && (auth?.otp_key || auth?.access_token)) {
            setLoggingIn(false)
            props.state[1](false)
            if (auth?.access_token) {
                window.location.href = "/user-dashboard"
            }
            if (auth?.otp_key) {
                props.twoFAModalState[1](true)
            }
        }
    }, [auth?.otp_key, auth?.access_token])

    // Login Failed
    useEffect(() => {
        if (loggingIn === true && loginStatus.loginError) {
            setLoggingIn(false);
        }
        if (loginStatus.loginError == "User needs to reset password") {
            props.state[1](false)
            setResetModal(true)
        }
    }, [loginStatus.loginError])

    const Partners = () => (
        <section className="partners">
        {
            partners.map(partner =>
                <a href={`/${partner.slug}`} title={partner.name} target="_blank" rel="noreferrer">
                    <figure className="avatar">
                        <img src={partner.logo_url} alt={partner.name} /> 
                    </figure>
                </a>
            )
        }
        </section>
    )

    return <>
        <ForceResetPassword state={[resetmodal, setResetModal]} />
        <Modal disableBackdropClick
            state = {props.state}
            style = 'login action-box content-box'
        >
            <img className="logo" src={logo} alt={process.env.APP_NAME}/>
            <div jpt-form="login" className="pseudo-form">
                <InputField type="hidden" name="login" />
                <InputField
                    error       = {!!loginStatus.loginError || emptyUsername}
                    value       = {email}
                    type        = "text"
                    name        = "username"
                    placeholder = "Your Username or Email"
                    onChange    = {e => {
                        setEmail(e.target.value)
                        setEmptyUsername(!e.target.value)
                    }}
                    style       = {{inputContainer: 'icon icon-user ignore'}}
                />
                <InputField
                    showPassword = {false}
                    error        = {!!loginStatus.loginError || emptyPassword}
                    value        = {password}
                    type         = "password"
                    name         = "password"
                    placeholder  = "Your Password"
                    onChange     = {e => {
                        setPassword(e.target.value)
                        setEmptyPassword(!e.target.value)
                    }}
                    style       = {{inputContainer: 'icon icon-password ignore'}}
                />
                <section className="input-group">
                    <InputField
                        type     = "checkbox"
                        name     = "remember_me"
                        title    = "Remember Me"
                        checked  = {rememberMe}
                        onChange = {e => setRememberMe(e.target.checked)}
                    />
                    <section className="input-box">
                        <section className="input-prepend">
                            <section className="forgot-password link">
                            <a href="/forgot-password">Forgot Password?</a>
                            </section>
                        </section>
                    </section>
                </section>
                <div className="error-container" data-error-container>
                    {loginStatus.loginError || ''}
                </div>
                <section className="footer">
                    <ButtonLoader hasLoader
                        loading = {loggingIn}
                        name  = 'log-in'
                        style = 'filled blue'
                        text  = 'Log In'
                        onClick = {login}
                    />
                </section>
            </div>
            <section className="divider"><span>OR</span></section>
            <h3>Start Paying Bills</h3>
            <Partners/>
            <p className="biller_link">
                <a href="#partners" className="close" onClick={() => props.state[1](false)}>
                    See all Billers
                </a>
            </p>
        </Modal>
    </>
});

export default LogInModal;