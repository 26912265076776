import { selectUserInfo } from "app/common/auth/selectors";
import InputField from "app/components/InputField";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { depository_accounts } from "utils/options";
import Section from "./Section";

const DepositoryAccount = memo(() => {
    const user = useSelector(selectUserInfo)

    return <Section title="DEPOSITORY ACCOUNT">
        <InputField readonly
            type  = "text"
            name  = "depository_account"
            label = "Depository Account"
            value = {
                !user?.depository_details?.bank? "":
                user?.depository_details.bank in depository_accounts.bank    ? depository_accounts.bank[user?.depository_details.bank]:
                user?.depository_details.bank in depository_accounts.e_wallet? depository_accounts.e_wallet[user?.depository_details.bank]:
                user?.depository_details.bank in depository_accounts.otc     ? depository_accounts.otc[user?.depository_details.bank]: ""
            }
            style = {{container: 'valid full-width'}}
        />
        <InputField readonly
            type  = "text"
            name  = "account_name"
            label = "Account Name"
            value = {user?.depository_details?.account_name}
            style = {{container: 'valid full-width'}}
        />
        <InputField readonly
            type  = "text"
            name  = "account_number"
            label = "Account Number"
            value = {`•••••••${user?.depository_details?.account_number.substring(user?.depository_details?.account_number?.length-3)}`}
            style = {{container: 'valid full-width'}}
        />
        <section className="footer-notes">
            <small>For Depository Account modification, please email our support center at <a href="mailto:hello@justpay.to" style={{textDecoration: 'none'}}>hello@justpay.to</a></small>
        </section>
    </Section>
})

export default DepositoryAccount