import { Grid, Typography } from "@material-ui/core";
import { WatchLater } from "@material-ui/icons";
import { selectUserInfo } from "app/common/auth/selectors";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberFormat } from "utils";
import checkIcon from 'assets/img/success.png'
import Sales from "./Sales";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import { Link } from "react-router-dom";
import BarChart from "../Analytics/BarChart";
import UserInfo from "./UserInfo";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";

const BusinessOverview = React.memo(() => {
    const dispatch  = useDispatch();
    const userInfo  = useSelector(selectUserInfo);
    const dashboard = useSelector(selectUserDashboard);

    const current_datetime = moment();

    const v2        = process.env.REACT_APP_USE_KEYCLOAK == 'True';
    const isPremium = v2 && (userInfo?.groups?.includes("Premium") || userInfo?.groups?.includes("Partners"))
    const {lastDeposit, lastDeposited, nextDeposit} = dashboard?.overview;

    return <>
        <UserInfo/>
        <Grid container justifyContent="space-between">
            <Grid item xs={12} md={8} className="item-grid partial">
                <Sales current_datetime={current_datetime} component/>
            </Grid>
            <Grid item xs={12} md={4} className="item-grid partial">
                {
                    isPremium? <div style={{color: "#484848", marginBottom: "2rem"}}>
                        <Typography variant="body1" color="textSecondary">Latest Deposit</Typography><br /><br />
                        <Typography variant="h2"><img src={checkIcon} width="25px"/> ₱{ lastDeposited?.payout_amount || 0.00 }</Typography><br /><br />
                        {lastDeposited?.updated_at && <Typography variant="body1" color="textSecondary">
                            From {lastDeposited?.sender_details?.name}<br/>
                            {moment(lastDeposited?.updated_at || undefined).format("MMMM DD, YYYY | hh:mm A")}
                        </Typography>}
                    </div>:<>
                        <Typography variant="body1" color="textSecondary">Deposited {lastDeposit?.date}</Typography><br />
                        <Typography variant="h2"><img src={checkIcon} width="25px"/> ₱{ numberFormat(lastDeposit?.amount || 0.00) }</Typography><br /><br />
                        <Typography variant="body1" color="textSecondary">Expected {nextDeposit?.date}</Typography><br />
                        <Typography variant="h2"><WatchLater style={{fontSize: "28px", color: '#777', marginBottom: '5px'}}/> ₱{ numberFormat(nextDeposit?.amount || 0.00) }</Typography><br /><br />
                    </>
                }
                <span><Link to="/user-dashboard/analytics" className="current" onClick={() => dispatch(userDashboardPageActions.updateActionTime())}>View Analytics</Link></span>
                <p></p>
            </Grid>
        </Grid>
        {v2 && <BarChart component/>}
    </>
})

export default BusinessOverview;