import { red } from '@material-ui/core/colors';
import { ErrorOutlineOutlined } from '@material-ui/icons';
import Modal, { DefaultModalProps } from 'app/components/Modal';
import React from 'react';

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        forceResetPassword: {
            width: "500px!important",
            fontFamily: "Arial",
            ['& h2']: {
                fontSize: "33px",
                color: "#484848"
            },
            ['& .MuiSvgIcon-root']: {
                marginBottom: "2rem"
            }
        }
    })
);

const ForceResetPassword = (props: DefaultModalProps) => {
    const classes = useStyles();
    return (
        <Modal {...props} disableBackdropClick disableEscapeKeyDown disableCloseButton style = {`action-box content-box larger_modal center ${classes.forceResetPassword}`}>
            <h2>Reset Password</h2>
            <ErrorOutlineOutlined style={{ fontSize: 120, color: red[600] }}/>
            <div className="body">
                <p>For security purposes, you have to reset your password.</p>
                <p>Instructions will be sent to your Email Address.
            </p>
            </div>
        </Modal>
    )
}

export default ForceResetPassword