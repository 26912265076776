import { createSelector } from '@reduxjs/toolkit';
import { RootState }      from 'types';
import { initialState }   from './slice';

const selectDomain = (state: RootState) =>
    state.signUp || initialState;

export const selectIP = createSelector(
    [selectDomain],
    GeneralState => GeneralState.ip_info,
);
    
export const selectSignUpState = createSelector(
    [selectDomain],
    signUpState => signUpState,
);
