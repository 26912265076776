import React, { memo } from "react";

const TableHead = memo((props: {
    className    : string
    label?       : string
    children?    : React.ReactNode
    sortSelected?: boolean
    sortOrder?   : string
    onClick?     : () => void
}) => {
    const {className, label, children, sortSelected, sortOrder, onClick} = props;
    const sortClass = sortSelected? sortOrder: '';
    return (
        <th className={className + ` ${sortClass}`} tabIndex={0} rowSpan={1} colSpan={1} onClick={onClick}>
            {children}
            {label}
        </th>
    )
})

export default TableHead