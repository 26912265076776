import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { User } from 'oidc-client';
import { createSlice } from 'utils/@reduxjs/toolkit';
import Country from './types/Country';
import UserInfo from './types/UserInfo';

// const MAX_LOGIN_ATTEMPTS = 5;
// const LOGIN_DELAY_TIME   = 3; //minutes

export interface GeneralState {
    countries?: Country[]
    page: string
    user: User | null
    user_info?: UserInfo
    loading: boolean
    loginError?: string
    otpError?: string
    gettingOtp?: boolean
    auth?: any
    logInTime?: moment.Moment
}

export const initialState: GeneralState = {
    user: null,
    page: '/user-dashboard',
    loading: true,
}

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setAuth(state, action: PayloadAction<{
            access_token?: string,
            otp_key?: string,
            refresh_expires_in?: number
        }>) {
            state.auth = {...state.auth, ...action.payload}
            state.logInTime = moment().add(action.payload.refresh_expires_in, 'seconds')
            state.loading = false;
        },
        removeAuth(state){
            state.auth = {}
        },
        refreshToken(state, action: PayloadAction<any>){},
        getUserInfo() {},
        getIp() {},
        getCountries() {},
        setCountries(state, action: PayloadAction<Country[]>) {
            state.countries = action.payload
        },
        getUser(state, action: PayloadAction<string>) {},
        setPage(state, action: PayloadAction<string>) {
            state.page = action.payload;
        },
        setUser(state, action: PayloadAction<User | null>) {
            state.user = action.payload;
            // sessionStorage.setItem('user', JSON.stringify(action.payload));
        },
        setUserInfo(state, action: PayloadAction<UserInfo | null | undefined>) {
            state.user_info = action.payload || undefined;
            // sessionStorage.setItem('user_info', JSON.stringify(action.payload));
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        tokenLogin(state, action: PayloadAction<string>) {},
        login(state, action: PayloadAction<{
            email: string,
            password: string,
            rememberMe: boolean,
            tokenize?: boolean
        }>){
            state.loginError = undefined;
        },
        loginSuccess(state, action: PayloadAction<{
            user: User, user_info: UserInfo
        }>) {
            state.user      = action.payload.user;
            state.user_info = action.payload.user_info;
            // sessionStorage.setItem('user', JSON.stringify(action.payload.user));
            // sessionStorage.setItem('user_info', JSON.stringify(action.payload.user_info));
        },
        loginFailed(state, action: PayloadAction<string>) {
            state.loginError = action.payload;
        },
        sendOTP(state) {
            state.gettingOtp = true;
        },
        otpSent(state, action: PayloadAction<string>) {
            state.gettingOtp = false;
        },
        validateOTP(state, action: PayloadAction<{otp: string, tokenize?: boolean}>) {
            state.otpError = undefined;
        },
        invalidOTP(state, action: PayloadAction<string>) {
            state.otpError = action.payload;
        },
        clearError(state) {
            state.otpError = undefined;
            state.loginError = undefined;
        }
    },
});

export const {
    actions: GeneralComponent,
    reducer,
    name: sliceKey,
} = generalSlice;
