import ButtonLoader from "app/components/ButtonLoader";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import moment from "moment";
import React, { createRef } from "react"
import ReactExport from 'react-data-export'
import { useDispatch } from "react-redux";
import { numberFormat } from "utils";

const File  = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;

const CollectionReport = ({data, filename, total, loading}) => {
    const transactions   = data.filter(t => !isNaN(t.sender_details?.message))
    const dispatch       = useDispatch()
    const ref            = createRef<HTMLButtonElement>();
    const style          = width => ({width: {wpx: width}, style:{font: {bold: false}}})
    const count          = transactions.length
    const sum            = transactions.reduce((total, transaction) => total + +transaction.payout_amount, 0)
    const hash_total     = transactions.reduce((total, transaction) => total + +transaction.sender_details.message, 0)
    const control_record = [{
        columns: [
            {title: "Detail Record Count"                 , ...style(160)},
            {title: "Payment Amount Sum"                  , ...style(160)},
            {title: "Hash Total (Contract Account Number)", ...style(280)},
        ],
        data: [[
            {value: count},
            {value: numberFormat(sum)},
            {value: hash_total.toString()}
        ]]
    }]
    const detail_record = [{
        columns: [
            {title: "Contract Account Number", ...style(160)},
            {title: "Payment Amount"         , ...style(120)},
            {title: "Payment Method"         , ...style(120)},
            {title: "Payment Channel"        , ...style(120)},
            {title: "Collecting Branch"      , ...style(120)},
            {title: "Bank Code"              , ...style(120)},
            {title: "Cheque Number"          , ...style(120)},
            {title: "Payment Date"           , ...style(120)},
            {title: "Payment Time"           , ...style(120)},
        ],
        data: transactions.map(transaction => [
            {value: transaction.sender_details.message},
            {value: numberFormat(+transaction.payout_amount)},
            {value: "XF"},
            {value: "Desktop"},
            {value: "<Collecting Branch>"},
            {value: "N/A"},
            {value: "N/A"},
            {value: moment(transaction.updated_at).format("MM/DD/YYYY")},
            {value: moment(transaction.updated_at).format("HH:mm")},
        ])
    }]

    return <>
        <File filename = {filename} element = {<button ref={ref}/>}>
            <Sheet dataSet={control_record} name="Control Record"/>
            <Sheet dataSet={detail_record} name="Detail Record"/>
        </File>
        <ButtonLoader hasLoader
            loading = {loading}
            onClick = {() => {
                dispatch(userDashboardPageActions.updateActionTime())
                ref.current?.click()
            }}
            style   = "filled blue"
            text    = {data.length != total ? `Download Selected Collection Report (${data.length})` : 'Download Collection Report'}
        />
    </>
}

export default CollectionReport;