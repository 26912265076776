import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';

import { PayloadAction } from '@reduxjs/toolkit';
import {
    AUTOSWEEP_VALIDATE,
    BAYADCENTER_ACCOUNT,
    BEEP_CARD_STATUS,
    BOOK_DELIVERY,
    CALCULATE_DELIVERY,
    EASYTRIP_CUSTOMER_INFO,
    FETCH_COUNTRY_DETAILS,
    GET_ANNOUNCEMENT,
    MCC_CALLBACK,
    MCC_CREDIT_INITIATE_SESSION,
    MCC_CREDIT_PROCESS_CHECKOUT,
    ALLBANK_QRPH_STATUS,
    PAYMENT_TOKEN,
    SEND_KYC_OTP,
    SEND_NOTIFICATION,
    PROCESS_CALLBACK,
    STORE_FILE_UPLOAD,
    STORE_INTENT_REPORT,
    STORE_PAYMENT_INFORMATION,
    STORE_SENDER_KYC,
    VALIDATE_KYC_OTP
} from 'app/common/core_api/resources';
import { paymentPageRequestActions } from 'app/containers/PaymentPage/slice';
import {
    call,
    delay,
    put,
    select,
    takeLatest,
    takeLeading,
} from 'redux-saga/effects';
import { RootState } from 'types';
import { dataURItoBlob } from 'utils';
import { decrypt, generateKey, request } from 'utils/request';

const signRequest = (request, signToken) => Base64.stringify(hmacSHA256(JSON.stringify({
    payload : request,
    token   : signToken,
}), process.env.REACT_APP_PAYMENT_SIGNATURE_TOKEN));

export function* getAnnouncementBanner(action: PayloadAction<object | string>) {
    yield delay(500);

    try {
        if (action.payload !== 'hide') {
            let query = new URLSearchParams({
                    type    : action.payload?.['type'] || 'banner',
                    partner : action.payload?.['partner'] || 'all',
                }),
                announcementBannerRequest: RequestInit = {
                method  : 'GET',
                headers : {
                    'Content-type': 'application/json',
                },
            };

            const announcementBannerResponse = yield call(request, `${GET_ANNOUNCEMENT}?${query}`, announcementBannerRequest);

            yield put(paymentPageRequestActions.getAnnouncementBanner(announcementBannerResponse));
        }
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Announcement Banner',
                status  : 500,
                message : err,
                display : false,
            }));
        }
    }
}

export function* storeMCCCheckoutSession(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let checkoutSessionRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Content-type': 'application/json',
            },
            body    : JSON.stringify({
                mid_code            : action.payload['mid_code'],
                merchant            : action.payload['merchant'],
                order_id            : action.payload['order_id'],
                amount              : action.payload['amount'],
                currency            : action.payload['currency'],
                notification_url    : MCC_CALLBACK,
                // notification_url    : 'https://webhook.site/910b2b4f-b29a-4288-8fcc-df724234d3cd',
            }),
        };

        const checkoutSessionResponse = yield call(request, MCC_CREDIT_INITIATE_SESSION, checkoutSessionRequest);

        yield put(paymentPageRequestActions.storeMCCCheckoutSession(checkoutSessionResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'MCC Checkout Session',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* fetchMCCRetrieveOrder(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let retrieveOrderRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Content-type': 'application/json',
            },
            body    : JSON.stringify({
                mid_code    : action.payload['mid_code'],
                merchant    : action.payload['merchant'],
                order_id    : action.payload['order_id'],
            }),
        };

        const retrieveOrderResponse = yield call(request, MCC_CREDIT_PROCESS_CHECKOUT, retrieveOrderRequest);

        yield put(paymentPageRequestActions.fetchMCCRetrieveOrder(retrieveOrderResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'MCC Retrieve Order',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* storeXenditCreateToken(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let successResponse = null,
            failResponse    = null;

        window.Xendit.card.createToken({
            'amount'                    : action.payload['amount'],
            'currency'                  : action.payload['currency'],
            'card_number'               : action.payload['cardNumber'],
            'card_exp_month'            : action.payload['cardExpMonth'],
            'card_exp_year'             : action.payload['cardExpYear'],
            'card_cvn'                  : action.payload['cardCvn'],
            'card_holder_first_name'    : action.payload['firstName'],
            'card_holder_last_name'     : action.payload['lastName'],
            'card_holder_phone_number'  : action.payload['phoneNumber'],
            'card_holder_email'         : action.payload['emailAddress'],
            'is_multiple_use'           : action.payload['isMultipleUse'],
            'should_authenticate'       : action.payload['shouldAuthenticate'],
        }, (fail, success) => {
            successResponse = success;
            failResponse    = fail;
        });

        yield delay(5000);

        yield put(paymentPageRequestActions.storeXenditCreateToken({
            success : successResponse,
            fail    : failResponse,
        }));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Xendit Create Token',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* getAllBankQRPHStatus(action: PayloadAction<object>) {
    yield delay(500);

    if ('status' in action.payload) {
        return action.payload;
    }

    try {
        let allbankQRPHResponse = yield call(request, `${ALLBANK_QRPH_STATUS}?merc_token=${action.payload['merc_token']}`);

        yield put(paymentPageRequestActions.getAllBankQRPHStatus(allbankQRPHResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'AllBank QRPH Status',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* storeIntentReport(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let intentReportRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Content-type': 'application/json',
            },
            body    : JSON.stringify({
                report: action.payload['report'],
                ...('intent_id' in action.payload ? { intent_id: action.payload['intent_id']} : {}),
            }),
        };

        const intentReportResponse = yield call(request, STORE_INTENT_REPORT, intentReportRequest);

        yield put(paymentPageRequestActions.storeIntentReport(intentReportResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Intent Report',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* fetchCountryDetails(action: PayloadAction<object>) {
    yield delay(500)

    try {
        let query = 'country_code' in action.payload ? `?country_code=${action.payload['country_code']}` : '',
            countryDetailsRequest: RequestInit  = {
            method  : 'GET',
            headers : {
                'Content-type': 'application/json',
            },
        };

        const countryDetailsResponse = yield call(request, `${FETCH_COUNTRY_DETAILS}${query}`, countryDetailsRequest);

        if (countryDetailsResponse.status_code == 200) {
            if ('country' in countryDetailsResponse) {
                let countryDetails = countryDetailsResponse.country;

                yield put(paymentPageRequestActions.storePaymentPayload({
                    sender_details: {
                        mobile: {
                            prefix  : countryDetails.mobile_code,
                            country : countryDetails.code,
                        },
                    },
                } as any));
            }
        }
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Country Details',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* fetchModalAPI(action: PayloadAction<object>) {
    if ('status_code' in action.payload) {
        return;
    }

    yield delay(500);

    try {
        if ('data' in action.payload) {
            yield put(paymentPageRequestActions.fetchModalAPI({
                ...action.payload,
                status_code: 200,
            }));
        } else {
            let query       = {},
                url         = action.payload['url'],
                parameters  = action.payload['parameters'];

            switch (action.payload['method']) {
                case 'POST':
                    query = {body: JSON.stringify(parameters)};
                break;
                case 'GET':
                    parameters  = new URLSearchParams(parameters);
                    url         = `${url}${parameters}`;
                break;
            }

            let modalAPIRequest: RequestInit  = {
                method  : action.payload['method'] || 'GET',
                headers : {
                    'Content-type': 'application/json',
                },
                ...query,
            };

            const modalAPIResponse = yield call(request, url, modalAPIRequest);

            yield put(paymentPageRequestActions.fetchModalAPI(modalAPIResponse));
        }
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Modal API',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* storeSenderKYC(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let senderKYCRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Content-type': 'application/json',
            },
            body    : JSON.stringify(action.payload),
        };

        const senderKYCResponse = yield call(request, STORE_SENDER_KYC, senderKYCRequest);

        yield put(paymentPageRequestActions.storeSenderKYC(senderKYCResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Sender KYC',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* sendOTP(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let sendOTPRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Content-type': 'application/json',
            },
            body    : JSON.stringify(action.payload),
        };

        const sendOTPResponse = yield call(request, SEND_KYC_OTP.replace(/:type/g, action.payload['type']), sendOTPRequest);

        yield put(paymentPageRequestActions.sendOTP(sendOTPResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Send OTP',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* validateOTP(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let validateOTPRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Content-type': 'application/json',
            },
            body    : JSON.stringify(action.payload),
        };

        const validateOTPResponse = yield call(request, VALIDATE_KYC_OTP.replace(/:type/g, action.payload['type']), validateOTPRequest);

        yield put(paymentPageRequestActions.validateOTP(validateOTPResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Validate OTP',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* processCallback(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let processCallbackRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Accept'        : 'application/json, text/plain, */*',
                'Content-type'  : 'application/json',
            },
            body    : JSON.stringify(action.payload),
        };

        const paymentInformationResponse = yield call(request, PROCESS_CALLBACK, processCallbackRequest);

        yield put(paymentPageRequestActions.processCallback(paymentInformationResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Process Callback',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* storePaymentInformation(action: PayloadAction<object>) {
    yield delay(500);

    try {
        let paymentInformationRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Accept'            : 'application/json, text/plain, */*',
                'Content-type'      : 'application/json',
                'X-JPT-TIMESTAMP'   : action.payload['timestamp'],
            },
            body    : JSON.stringify({
                payload_id  : signRequest({transaction: action.payload['transaction']}, action.payload['timestamp']),
                transaction : action.payload['transaction'],
            }),
        };

        const paymentInformationResponse = yield call(request, STORE_PAYMENT_INFORMATION, paymentInformationRequest);

        yield put(paymentPageRequestActions.storePaymentInformation(paymentInformationResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Payment Information',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* bookDelivery(action: PayloadAction<string>) {
    yield delay(500);

    try {
        yield call(request, BOOK_DELIVERY.replace(':payment_id', action.payload), {method: 'POST'});
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Book Delivery',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* calculateDeliveryFee(action: PayloadAction<any>) {
    yield delay(500);

    try {
        let getPayload                                  = (state?: RootState) => state?.paymentPageRequest?.PaymentPayload,
            payload                                     = yield select(getPayload),
            calculateDeliveryFeeRequest: RequestInit    = {
                method  : 'POST',
                headers : {'content-type': 'application/json'},
                body    : JSON.stringify({
                    sender      : {address: payload?.['receiver_details']?.address},
                    receiver    : {...action.payload, address: payload?.['additional_details']?.order_details?.shipping_address},
                })
            };

        const calculateDeliveryFeeResponse = yield call(request, CALCULATE_DELIVERY, calculateDeliveryFeeRequest);

        if (calculateDeliveryFeeResponse.is_successful) {
            let grossAmount         = parseFloat(payload?.['amount_details']?.gross || 0),
                smsFee              = parseFloat(payload?.['amount_details']?.fees?.sms || 0),
                systemFee           = parseFloat(payload?.['amount_details']?.fees?.system || 0),
                sendingFee          = parseFloat(payload?.['amount_details']?.fees?.sending?.fee || 0),
                deliveryFee         = parseFloat(calculateDeliveryFeeResponse?.order?.payment_amount || 0),
                chargeFeesTo        = payload?.['receiver_details']['options']['charge_fees_to'],
                systemDeliveryFee   = 10.00;

            yield put(paymentPageRequestActions.storePaymentPayload({
                key     : 'amount_details',
                value   : {
                    net     : grossAmount + smsFee + deliveryFee + systemDeliveryFee + (chargeFeesTo == 'sender' ? systemFee + sendingFee : 0),
                    fees    : {
                        delivery : {
                            actual_fee  : deliveryFee,
                            system      : systemDeliveryFee,
                        },
                    },
                },
            }));
        } else {
            throw Error('Failed to calculate delivery fee');
        }
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Calculate Book Delivery',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* storeFileUploadToS3(action: PayloadAction<any>) {
    yield delay(500);

    try {
        let data        = new FormData(),
            toReplace   = {
                ':email'        : action.payload?.email,
                ':file_type'    : action.payload?.file_type,
            },
            fileUploadToS3Request: RequestInit = {
                method: 'POST'
            };

        data.append('blob', dataURItoBlob(action.payload?.file), `blob.${action.payload?.file.split(';')[0].split('/')[1]}`);

        fileUploadToS3Request.body = data;

        const fileUploadToS3Response = yield call(request, STORE_FILE_UPLOAD.replace(/:email|:file_type/gi, matched => toReplace[matched]), fileUploadToS3Request);

        yield put(paymentPageRequestActions.storeFileUploadToS3({sender_details: {[action.payload?.file_type]: fileUploadToS3Response}}));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'File Upload to S3',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* sendNotification(action: PayloadAction<any>) {
    yield delay(500);

    try {
        let sendNotificationRequest: RequestInit = {
            method  : 'POST',
            headers : {
                'Content-type': 'application/json',
            },
            body    : JSON.stringify(action.payload),
        };

        const sendNotificationResponse = yield call(request, SEND_NOTIFICATION, sendNotificationRequest);

        yield put(paymentPageRequestActions.sendNotification(sendNotificationResponse));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Notification',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* fetchPaymentToken(action: PayloadAction<string>) {
    yield delay(500);

    try {
        let getPayload      = (state: RootState) => state?.paymentPageRequest?.PaymentPayload,
            paymentToken    = yield call(request, PAYMENT_TOKEN.replace(':token', action.payload)),
            payload         = yield select(getPayload);

        if (payload?.receiver == paymentToken?.username && paymentToken?.status == 'unused') {
            let dispatcherFee       = paymentToken?.additional_details?.dispatcher_fee || null,
                totalDispatcherFee  = parseFloat(dispatcherFee?.fee_value);

            switch (dispatcherFee?.fee_type) {
                case 'percentage':
                    totalDispatcherFee = parseFloat(paymentToken?.amount) + parseFloat(dispatcherFee?.fee_value);
                break;
            }

            yield put(paymentPageRequestActions.storePaymentPayload({
                key     : 'amount_details',
                value   : {
                    ...payload.amount_details,
                    currency    : paymentToken?.currency,
                    gross       : paymentToken?.amount,
                    ...(paymentToken?.mobile_number?.country != 'PH' ? {fees: {sms: 5}} : {}),
                    ...(dispatcherFee ? {
                        fees: {
                            other_charges: {
                                dispatcher_fee: totalDispatcherFee,
                            },
                        },
                    } : {}),
                },
            }));

            yield put(paymentPageRequestActions.storePaymentPayload({
                key     : 'sender_details',
                value   : {
                    ...payload.sender_details,
                    message : paymentToken?.message,
                    email   : paymentToken?.email,
                    name    : paymentToken?.name,
                    mobile  : {...paymentToken?.mobile_number, carrier: ''},
                },
            }));

            yield put(paymentPageRequestActions.storePaymentPayload({
                key     : 'additional_details.order_details',
                value   : {
                    ...paymentToken?.additional_details,
                    token: paymentToken?.token,
                },
            }));
        }

        yield put(paymentPageRequestActions.storePaymentToken(paymentToken));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Fetch Payment Token',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* validateAutosweep(action: PayloadAction<any>) {
    yield delay(1000);

    try {
        let {algorithm, privateKey, publicKeyString}    = yield call(generateKey),
            {account_number, plate_number}              = action.payload;

        if (account_number || plate_number) {
            let options = {
                method  : 'POST',
                headers : {
                    'Content-Type'  : 'application/json',
                    'Client-Key'    : publicKeyString,
                },
                body    : JSON.stringify(action.payload),
            },
            response    = yield call(request, AUTOSWEEP_VALIDATE, options);

            if (response?.result?.InquireBalanceMerchantResult?.Balance) {
                response.result.InquireBalanceMerchantResult.Balance = yield call(decrypt, algorithm, privateKey, response.result.InquireBalanceMerchantResult.Balance);

                yield put(paymentPageRequestActions.isClientValid({
                    target  : response?.result?.InquireBalanceMerchantResult?.Code != 0 ? 'plateNumber' : '',
                    message : response?.result?.InquireBalanceMerchantResult?.Desc || '',
                    status  : response?.result?.InquireBalanceMerchantResult?.Code == 0
                }));
            } else {
                yield put(paymentPageRequestActions.isClientValid({
                    target  : 'plateNumber',
                    message : response?.result?.InquireBalanceMerchantResult?.Desc || 'Failed to verify plate / card number. Please contact support',
                    status  : false,
                }));
            }

            yield put(paymentPageRequestActions.validatingClient(false));
            yield put(paymentPageRequestActions.storePaymentPayload({
                key     : 'additional_details.client_details.autosweeprfid.validate',
                value   : response,
            }));
        }
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Validate Autosweep',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* validateBeep(action: PayloadAction<any>) {
    yield delay(1000);
    try {
        let url      = BEEP_CARD_STATUS + '?' + new URLSearchParams(action.payload as any),
            response = yield call(request, url);
        yield put(paymentPageRequestActions.validatingClient(false));
        yield put(paymentPageRequestActions.storePaymentPayload({
            key: 'additional_details.client_details.beep.validate',
            value: {
                payload: action.payload,
                result : response
            }
        }));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Validate Beep',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* validateEasytrip(action: PayloadAction<any>) {
    yield delay(1000);
    try {
        let {algorithm, privateKey, publicKeyString} = yield call(generateKey),
        params  = new URLSearchParams(action.payload as any).toString(),
        url     = new URL(`${EASYTRIP_CUSTOMER_INFO}?${params}`),
        options = {
            method : 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Client-Key'  : publicKeyString
            },
        },
        response = yield call(request, url.toString(), options);
        response = yield call(decrypt, algorithm, privateKey, response);
        yield put(paymentPageRequestActions.validatingClient(false));
        yield put(paymentPageRequestActions.storePaymentPayload({
            key: 'additional_details.client_details.easytriprfid.validate',
            value: {
                payload: action.payload,
                result : response
            }
        }));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Validate Easytrip',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* validateBayadcenter(action: PayloadAction<any>) {
    yield delay(1000);

    try {
        let url         = BAYADCENTER_ACCOUNT.replace(':biller', action.payload.biller).replace(':account_number', action.payload.account_number),
            response    = yield call(request, url, {
                method  : 'POST',
                headers : {'Content-type': 'application/json'},
                body    : JSON.stringify(action.payload.payload),
            });

        yield put(paymentPageRequestActions.validatingClient(false));

        if (response.data) {
            yield put(paymentPageRequestActions.storePaymentPayload({
                key     : 'amount_details.gross',
                value   : +action.payload.payload.amount,
            }));

            yield put(paymentPageRequestActions.storePaymentPayload({
                key     : 'amount_details.fees.other_charges.biller_fee',
                value   : +response.data.otherCharges,
            }));
        }

        yield put(paymentPageRequestActions.storePaymentPayload({
            key     : 'additional_details.client_details.bayadcenter.account_number',
            value   : action.payload.account_number,
        }));

        yield put(paymentPageRequestActions.storePaymentPayload({
            key     : 'additional_details.client_details.bayadcenter.amount_due',
            value   : action.payload.payload.amount,
        }));

        yield put(paymentPageRequestActions.storePaymentPayload({
            key     : 'additional_details.client_details.bayadcenter.verify_account',
            value   : {
                payload : action.payload,
                result  : response,
            },
            replace : 'verify_account',
        }));
    } catch (err) {
        if (err) {
            yield put(paymentPageRequestActions.apiError({
                source  : 'Validate Bayadcenter',
                status  : 500,
                message : err,
                display : true,
            }));
        }
    }
}

export function* paymentPageRequestSaga() {
    yield takeLeading(paymentPageRequestActions.getAnnouncementBanner.type, getAnnouncementBanner);
    yield takeLeading(paymentPageRequestActions.storeMCCCheckoutSession.type, storeMCCCheckoutSession);
    yield takeLeading(paymentPageRequestActions.fetchMCCRetrieveOrder.type, fetchMCCRetrieveOrder);
    yield takeLeading(paymentPageRequestActions.storeXenditCreateToken.type, storeXenditCreateToken);
    yield takeLeading(paymentPageRequestActions.getAllBankQRPHStatus.type, getAllBankQRPHStatus);
    yield takeLeading(paymentPageRequestActions.storeIntentReport.type, storeIntentReport);
    yield takeLeading(paymentPageRequestActions.fetchCountryDetails.type, fetchCountryDetails);
    yield takeLeading(paymentPageRequestActions.fetchModalAPI.type, fetchModalAPI);
    yield takeLeading(paymentPageRequestActions.storeSenderKYC.type, storeSenderKYC);
    yield takeLeading(paymentPageRequestActions.sendOTP.type, sendOTP);
    yield takeLeading(paymentPageRequestActions.validateOTP.type, validateOTP);
    yield takeLeading(paymentPageRequestActions.processCallback.type, processCallback);
    yield takeLeading(paymentPageRequestActions.storePaymentInformation.type, storePaymentInformation);
    yield takeLeading(paymentPageRequestActions.storeFileUploadToS3.type, storeFileUploadToS3);
    yield takeLeading(paymentPageRequestActions.sendNotification.type, sendNotification);
    yield takeLeading(paymentPageRequestActions.calculateDeliveryFee.type, calculateDeliveryFee);
    yield takeLeading(paymentPageRequestActions.bookDelivery.type, bookDelivery);
    yield takeLeading(paymentPageRequestActions.fetchPaymentToken.type, fetchPaymentToken);
    yield takeLatest(paymentPageRequestActions.validateBayadcenter.type, validateBayadcenter);
    yield takeLatest(paymentPageRequestActions.validateAutosweep.type, validateAutosweep);
    yield takeLatest(paymentPageRequestActions.validateEasytrip.type, validateEasytrip);
    yield takeLatest(paymentPageRequestActions.validateBeep.type, validateBeep);
}
