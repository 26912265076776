import { Grid, Typography } from "@material-ui/core";
import { WatchLater } from "@material-ui/icons";
import { selectUserInfo } from "app/common/auth/selectors";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { numberFormat } from "utils";
import checkIcon from 'assets/img/success.png'
import UserInfo from "./UserInfo";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";

const IndividualOverview = React.memo(() => {
    const userInfo  = useSelector(selectUserInfo);
    const dashboard = useSelector(selectUserDashboard);
    const current_datetime = moment();

    const v2 = process.env.REACT_APP_USE_KEYCLOAK == 'True';
    const isPremium  = v2 && (userInfo?.groups?.includes("Premium") || userInfo?.groups?.includes("Partners"))

    const longDateFormat = date => moment(date).format('MMMM DD, YYYY')

    return <>
        <UserInfo/>
        <Grid container className="container-grid individual" justifyContent="space-between">
            {
                isPremium?
                <Grid item md={3} sm={6} xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">Last Login</Typography><br />
                    <Typography variant="h3">{
                        userInfo?.log_in_records?.length == 0? current_datetime.format('MMMM DD, YYYY'):
                        userInfo?.log_in_records?.length == 1? moment(userInfo?.log_in_records?.[1]).format('MMMM DD, YYYY'):
                        moment(userInfo?.log_in_records?.at(-2)).format('MMMM DD, YYYY')
                    }</Typography>
                </Grid>:
                <Grid item md={3} sm={6} xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">Total Since [{moment(userInfo?.updated_at).format('MMMM DD, YYYY')}]</Typography><br />
                    <Typography variant="h3"> ₱{ numberFormat(dashboard?.overview?.totalSales || 0) }</Typography>
                </Grid>
            }
            <Grid item md={3} sm={6} xs={12}>
                <Typography variant="subtitle1" color="textSecondary">Deposited {longDateFormat(dashboard?.overview?.lastDeposit?.date)}</Typography><br />
                <Typography variant="h3"><img src={checkIcon} width="25px"/> ₱{ numberFormat(dashboard?.overview?.lastDeposit?.amount || 0) }</Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
                <Typography variant="subtitle1" color="textSecondary">Expected {longDateFormat(dashboard?.overview?.nextDeposit?.date)}</Typography><br />
                <Typography variant="h3"><WatchLater style={{fontSize: "28px", color: '#777', marginBottom: '5px'}}/> ₱{ numberFormat(dashboard?.overview?.nextDeposit?.amount || 0) }</Typography>
            </Grid>
            {isPremium && <Grid item md={3} sm={6} xs={12}>
                {/* <Typography variant="subtitle1" color="textSecondary">Total Since [{moment(userInfo?.updated_at).format('MMMM DD, YYYY')}]</Typography><br /> */}
                <Typography variant="subtitle1" color="textSecondary">Total Since [signing date]</Typography><br />
                <Typography variant="h3"> ₱{ numberFormat(dashboard?.overview?.totalSales || 0) }</Typography>
            </Grid>}
        </Grid>
    </>
})

export default IndividualOverview;