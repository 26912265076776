import moment from "moment"
import { clients } from "utils/options";

export const transaction_status = {
    'pending_payment': 'Pending',
    'review'         : 'Pending',
    'verified'       : 'For Settlement',
    'reject'         : 'Failed',
    'invalid'        : 'Failed',
    'expired'        : 'Failed',
    'complete'       : 'Completed',
    'hold'           : 'On Hold'
}
export const payment_methods = {
    'online_banking_desktop': 'Online Banking (Desktop)',
    'online_banking_mobile' : 'Online Banking (Mobile)',
    'xendit'                : 'Bank Transfer',
    'bank_fund_transfer'    : 'Bank Transfer',
    'over_the_counter'      : 'Over-the-counter',
    'e_wallet'              : 'E-Wallet',
    'e_wallet_xendit'       : 'E-Wallet',
    'mastercard_visa'       : 'Mastercard Visa',
    'credit_card'           : 'Credit Card',
    'debit_card'            : 'Debit Card',
    'bank_account'          : 'Bank Account',
    'cash_cheque_deposit'   : 'Cash Cheque Deposit',
    'others'                : 'Others'
}

export const fulfillment_methods = {
    'regular'         : 'Regular Payment',
    'instore_purchase': 'For Dine-In / On-site',
    'pick_up'         : 'For Pick-up',
    'delivery'        : 'For Delivery',
}

export const SORT_ASC  = 'sorting_asc';
export const SORT_DESC = 'sorting_desc';

export const computeFees = transaction => {
    return clients.includes(transaction.receiver || "")? (
        +(transaction.fees.system_fee || 0) +
        +(transaction.fees.sending_fee || 0) +
        +(transaction.fees.receiving_fee || 0) +
        +(transaction.fees.delivery_fee || 0) +
        +(transaction.fees.delivery_system_fee || 0)
    ):
    +(transaction.total_fees || 0)
}
export const sort = (transactions, sortBy, sortOrder) => [...transactions].sort((a, b) => {
    var value1, value2;

    if (sortBy == 'status') {

        let status1 = a.additional_details?.conditions?.is_onhold? 'hold': a.status
        let status2 = a.additional_details?.conditions?.is_onhold? 'hold': b.status

        value1 = transaction_status[status1]
        value2 = transaction_status[status2]
    } else if(sortBy == 'amount') {

        value1 = +a.payout_amount
        value2 = +b.payout_amount
    } else if(sortBy == 'fees') {

        value1 = computeFees(a)
        value2 = computeFees(b)
    } else if(sortBy == 'net') {

        let fees1 = computeFees(a)
        let fees2 = computeFees(b)

        value1 = +a.payout_amount - fees1
        value2 = +b.payout_amount - fees2
    } else if(sortBy == 'amount') {

        value1 = +a[sortBy]
        value2 = +b[sortBy]
    } else {

        value1 = a[sortBy]
        value2 = b[sortBy]
    }

    if (sortOrder == SORT_ASC) {
        return (
            value1 < value2? -1:
            value1 > value2?  1: 0
        )
    }
    if (sortOrder == SORT_DESC) {
        return (
            value1 > value2? -1:
            value1 < value2?  1: 0
        )
    }
    return 0
})

export const getLast7Days = transactions => transactions?.filter(
    transaction => (
        moment().subtract(6, 'days').isSameOrBefore(transaction.updated_at) &&
        moment().isSameOrAfter(transaction.updated_at)
    )
) || []

export const getLast20Days = transactions => transactions?.filter(
    transaction => (
        moment().subtract(19, 'days').isSameOrBefore(transaction.updated_at) &&
        moment().isSameOrAfter(transaction.updated_at)
    )
) || []

export const getByRange = (transactions, start, end) => transactions?.filter(
    transaction => (
        moment(start).isSameOrBefore(transaction.updated_at) &&
        moment(end  ).isSameOrAfter(transaction.updated_at)
    )
) || []

export const search = (transactions, value) => value? (
    transactions?.filter(
        transaction => (
            transaction?.payment_id?.includes(value) ||
            transaction?.source_of_funds?.payment_method?.includes(value) ||
            transaction?.source_of_funds?.payment_instrument?.includes(value) ||
            transaction?.additional_details?.order_details?.method.includes(value) ||
            transaction?.additional_details?.order_details?.shipping_address?.includes(value) ||
            transaction?.sender_details?.mobile?.number?.includes(value) ||
            transaction?.sender_details?.name?.includes(value) ||
            transaction?.sender_details?.email?.includes(value) ||
            transaction?.sender_details?.message?.includes(value) ||
            transaction?.amount_details?.currency?.includes(value) ||
            transaction?.receiver_details?.bank_details?.account_number?.includes(value)
        )
    ) || []
): transactions || []