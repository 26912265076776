import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { selectUserInfo } from "app/common/auth/selectors";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, countryName, numberFormat } from "utils";
import { depository_accounts } from "utils/options";

const UserInfo = memo(() => {

    const user             = useSelector(selectUserInfo);
    const dispatch         = useDispatch();
    const updateActionTime = () => dispatch(userDashboardPageActions.updateActionTime())

    const [isPremium, setIsPremium] = useState(false);

    useEffect(() => setIsPremium(!!(
        user?.groups?.includes('Premium') ||
        user?.groups?.includes('Partners')
    )), [user])

    const openChangePlanModal = () => {
        dispatch(userDashboardPageActions.setChangePlan())
        dispatch(userDashboardPageActions.updateActionTime())
    }

    const remaining_premium_days = moment(user?.last_renewal_date?.split(' ')[0]).add((user?.is_trial? 90: 30), 'd').diff(moment(new Date().toDateString()), 'days')

    const v2 = process.env.REACT_APP_USE_KEYCLOAK == 'True';
    return (
        <Grid container className="container-grid user-info">
            <Grid item xs={4}>
                <p>{user? <>First Name:          <span>{user.firstName}</span></>: <Skeleton variant="text" animation="wave"/>}</p>
                <p>{user? <>Last Name:           <span>{user.lastName}</span></>: <Skeleton variant="text" animation="wave"/>}</p>
                <p>{user? <>Email:               <span>{user.email}</span></>: <Skeleton variant="text" animation="wave"/>}</p>
                <p>{user? <>Mobile Number:       <span>{user.mobile_number?.prefix}{user.mobile_number?.number}</span></>: <Skeleton variant="text" animation="wave"/>}</p>
            </Grid>
            <Grid item xs={4} style={{paddingLeft: !user? '5rem': undefined}}>
                <p>{user? <>Country:             <span>{countryName(user.depository_details?.country || user.nationality || '')}</span></>: <Skeleton variant="text" animation="wave"/>}</p>
                <p>{user? <>Depository Account:  <span>{
                    !user.depository_details?.bank? "":
                    user.depository_details?.bank in depository_accounts.bank    ? depository_accounts.bank[user.depository_details?.bank]:
                    user.depository_details?.bank in depository_accounts.e_wallet? depository_accounts.e_wallet[user.depository_details?.bank]:
                    user.depository_details?.bank in depository_accounts.otc     ? depository_accounts.otc[user.depository_details?.bank]:
                    ""
                }</span></>: <Skeleton variant="text" animation="wave"/>}</p>
                <p>{user? <>Account Name:        <span>{user.depository_details?.account_name}</span></>: <Skeleton variant="text" animation="wave"/>}</p>
                <p>{user? <>Account Number:      <span>{user.depository_details?.account_number}</span></>: <Skeleton variant="text" animation="wave"/>}</p>
            </Grid>
            <Grid item xs={4}>
                <p>{user? <>Payment Address:     <a href={`/${user.preferred_username || user.username}`} target="__blank" onClick={updateActionTime}>justpay.to/{user.preferred_username || user.username}</a></>: <Skeleton variant="text" animation="wave"/>}</p>
                <p>{user? <>Fees Paid by:        <span style={{textTransform: 'capitalize'}}>{v2? user.charge_fees_to: 'Recipient'}</span></>: <Skeleton variant="text" animation="wave"/>}</p>
                {v2 && <p>{user? <>Account Type:        <span>
                    {isPremium? 'Premium': 'Standard'}{' '}
                    {capitalize(user.account_type?.type)}{' '}
                    {isPremium && `(${remaining_premium_days} ${remaining_premium_days == 1? 'day': 'days'})`}
                </span></>: <Skeleton variant="text" animation="wave"/>}</p>}
                {/* {v2 && <p>{user? (
                    user.account_type?.type == "individual" && !isPremium?
                    user.premium_balance > 0?
                    <a style={{textDecoration: "underline"}} onClick={openChangePlanModal}>Upgrade for real-time deposit</a>:
                    <button className="filled round blue half" onClick={openChangePlanModal}>{isPremium? 'Change your account': 'Upgrade'}</button>
                ): <Skeleton variant="text" animation="wave"/>}</p>} */}
                {
                    v2 && <p>{
                        user? (
                            isPremium?
                            <DowngradeButton onClick={openChangePlanModal}/>:
                            <UpgradeButton onClick={openChangePlanModal} type={user.account_type} balance={user.premium_balance}/>
                        ):
                        <Skeleton variant="text" animation="wave"/>
                    }</p>
                }
            </Grid>
        </Grid>
    )
})

const DowngradeButton = ({onClick}) => {
    return <button className="filled round blue half" onClick={onClick}>Change your account</button>
}

const UpgradeButton = ({onClick, type, balance}) => {
    const LinkButton = ({text}) => <a style={{textDecoration: "underline"}} onClick={onClick}>{text}</a>
    const Button     = ({text}) => <button className="filled round blue half" onClick={onClick}>{text}</button>
    return (
        balance > 0?
        <>
            <span style={{color: "#E32B2B", marginRight: "1rem"}}>Unpaid Balance ₱{numberFormat(balance)}</span>
            <LinkButton text="Upgrade"/>
        </>:
        type == "individual"?
        <LinkButton text = "Upgrade for real-time"/>:
        <Button text = "Upgrade"/>
    )
}



export default UserInfo