import { selectCountries } from 'app/common/selectors';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface Props {
    name?                    : string
    type?                    : string
    label?                   : string | JSX.Element
    tooltip?                 : JSX.Element
    focusTooltip?            : JSX.Element
    style?                   : Style
    max?                     : number
    min?                     : number
    placeholder?             : string
    readonly?                : boolean
    hasForgotLink?           : boolean
    showPassword?            : boolean
    loader?                  : boolean
    valid?                   : boolean
    error?                   : boolean
    error_message?           : string
    value?                   : string
    accept?                  : string
    preview?                 : string
    instruction?             : string
    options?                 : JSX.Element[]
    title?                   : string
    checked?                 : boolean
    country?                 : string
    textAreaCheckbox?        : string
    textAreaChecked?         : boolean
    checkAddress?            : string
    onClick?                 : () => void
    onChange?                : React.ChangeEventHandler<HTMLInputElement>
    onSelectChange?          : React.ChangeEventHandler<HTMLSelectElement>
    onTextAreaChange?        : React.ChangeEventHandler<HTMLTextAreaElement>
    onTextAreaCheckboxChange?: () => void
}

interface Style {
    container?     : string
    tooltip?       : string
    input?         : string
    label?         : string
    inputContainer?: string
}

const InputField = (props: Props) => {
    const countries = useSelector(selectCountries);
    const {name, type, label, tooltip, focusTooltip, style, max, min, placeholder, readonly, hasForgotLink, showPassword, loader, valid, error, error_message, value, accept, preview, instruction, options, title, checked, country, textAreaCheckbox, textAreaChecked, checkAddress, onClick, onChange, onSelectChange, onTextAreaChange, onTextAreaCheckboxChange} = props;
    const [show, setShow] = useState(false);
    const [flag, setFlag] = useState('');

    const map_link = checkAddress && `https://www.google.com.ph/maps/search/${encodeURI(checkAddress)}`
    useEffect(() => {
        if (type === 'mobile' && country) {
            const flagUrl = 'https://s3-staging-justpayto-web-assets.s3-ap-southeast-1.amazonaws.com/p2p-assets/img/icons/flags/:country.svg';
            setFlag(flagUrl.replace(':country', country || 'PH'));
        }
    }, [country])

    const handleEnter = e => {
        if (e.which == 13) {
            var element = e.target.parentNode.parentNode;
            
            while (element) {
                var elem = element
                while (elem) {
                    try {
                        if (
                            elem.nextSibling instanceof HTMLInputElement ||
                            elem.nextSibling instanceof HTMLSelectElement ||
                            elem.nextSibling instanceof HTMLTextAreaElement ||
                            elem.nextSibling instanceof HTMLButtonElement
                        ) {
                            elem.nextSibling.focus();
                            e.preventDefault();
                            return;
                        } else {
                            elem.nextSibling.querySelectorAll('input, select, textarea, button')[0].focus();
                            e.preventDefault();
                            return;
                        }
                    } catch (e) {}
                    elem = elem.nextSibling
                }
                element = element.parentNode;
            }
        }
    }

    const handlePrefixEnter = e => {
        if (e.which == 13) {
            e.target.parentNode.children[2].focus()
        }
    }

    return (
    <>
        <section jpt-input = {name} className = {`
            ${type !== 'hidden' && 'input-box'}
            ${style && style.container && style.container}
            ${valid && 'valid'}
            ${error && 'error'}
            ${type == 'radio' && "radio-field"}
        `}>
        {
            label && type !== 'checkbox' && type !== 'radio' && <>
                <label htmlFor={name} className={`${style?.label} ${tooltip? 'tooltip' : ''}`}>
                    <div className="label">{label}</div>
                    {
                        tooltip && (<>
                            {' '}
                            <span className="fa fa-question-circle"></span>
                            <section className={style && style.tooltip? style.tooltip : ''}>
                                {tooltip}
                            </section>
                        </>)
                    }
                    {checkAddress &&
                        <a href={map_link} target="__blank">Check this address</a>
                    }
                </label>
            </>
        }
        {
            type === 'hidden' ?
            <input type = {type} name = {name} id = {name} value = {value} readOnly={readonly} onKeyDown={handleEnter}/> :
            <section className={
                `input-prepend
                ${readonly ? 'readonly': ''}
                ${type === 'mobile' ? 'mobile': ''}
                ${style && style?.inputContainer ? style?.inputContainer: ''}
                ${type === 'file' ? 'file': ''}
                ${instruction && 'with-instruction'}
            `} onClick={onClick}>
                {
                    type === 'textarea'?
                    <textarea
                        className = {style?.input}
                        name      = { name }
                        id        = { name }
                        maxLength = {max}
                        minLength = {min}
                        placeholder = {placeholder}
                        onChange    = {onTextAreaChange}
                        disabled    = {readonly}
                        value       = {value}
                    />:
                    type === 'mobile'? <>
                        <select name="mobile_prefix" className="mobile-prefix" id="ui-id-1" style={{
                            opacity: 0,
                            position: 'absolute',
                            height: '40px',
                            border: '0px',
                            width: '94px',
                            margin: 0,
                            padding: 0,
                            left: 0,
                        }} onKeyPress={handlePrefixEnter} onChange = {onSelectChange} disabled={readonly || true}>
                            <option value="PH" selected={true}>
                                +63
                            </option>
                            {/* {countries && countries.map(c =>
                                <option value={c.code} selected={c.code == country}>
                                    {c.mobile_code}
                                </option>
                            )} */}
                        </select>
                        <span tabIndex={-1} id="ui-id-1-button" role="combobox" aria-expanded="false" aria-autocomplete="list" aria-owns="ui-id-1-menu" aria-haspopup="true" className="ui-selectmenu-button ui-selectmenu-button-closed ui-corner-all ui-button ui-widget custom-iconSelectMenu-disabled ui-state-disabled" aria-disabled="true">
                            <span className="ui-selectmenu-icon ui-icon ui-icon-triangle-1-s"></span>
                            <div className="ui-selectmenu-text">
                                <img src={flag} className="ui-icon"/>{countries?.find(c => c.code == country)?.mobile_code}
                            </div>
                        </span>
                        <input type="tel" maxLength={12} name="mobile_number" id="mobile_number" className="mobile" value={value} placeholder={placeholder} readOnly={readonly} onKeyPress={handleEnter} onChange={e => {
                            if (
                                onChange && (
                                    e.target.value === '' ||
                                    /^\d+$/.test(e.target.value.replaceAll(' ', ''))
                                )
                            ) {onChange(e);}
                        }}/>
                    </>:
                    type === 'select' ? <>
                        <select className = {style?.input} name={name} id={name} onChange={onSelectChange} value={value} disabled={readonly} onKeyPress={handleEnter} style={
                            name == 'country' && countries?.find(c => c.code == value)?.name ?{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                border: '0px'
                            }: {}
                        }>
                            <option disabled selected={!(value || '' in Object.keys(options || {}))} value="">{placeholder}</option>
                            {options}
                        </select>
                        {['nationality', 'country'].includes(name || '') && countries?.find(c => c.code == value)?.name &&(
                            <span tabIndex={-1} id="ui-id-1-button" aria-expanded="false" aria-autocomplete="list" aria-owns="ui-id-1-menu" aria-haspopup="true" className="ui-selectmenu-button ui-selectmenu-button-closed ui-corner-all ui-button ui-widget custom-iconSelectMenu-disabled ui-state-disabled" aria-disabled="true">
                            <span className={`ui-selectmenu-icon ui-icon ui-icon-triangle-1-s ${flag?'flag': ''}`}></span>
                                <div className="ui-selectmenu-text">
                                    {flag && <img src={flag} className="ui-icon"/>}{countries?.find(c => c.code == value)?.name}
                                </div>
                            </span>
                        )}
                    </>:
                    type === 'checkbox' ? <>
                        <input
                            className = {style?.input}
                            type      = {type}
                            name      = {name}
                            id        = {name}
                            onChange  = {onChange}
                            checked   = {checked}
                            disabled  = {readonly}
                            readOnly  = {readonly}
                        />
                        <label htmlFor={name}>{title}</label>
                    </>:
                    type === 'radio' ? <>
                        <input
                            className = {style?.input}
                            type      = {type}
                            name      = {name}
                            id        = {`${name}_${title}`}
                            onChange  = {onChange}
                            checked   = {checked}
                            disabled  = {readonly}
                            readOnly  = {readonly}
                        />
                        <label htmlFor={`${name}_${title}`}>{title}</label>
                    </>:
                    type === 'file' ? <>
                        <input 
                            className  = {style?.input}
                            type       = "file"
                            name       = { name }
                            id         = { name }
                            accept     = {accept}
                            onChange   = {onChange}
                            onKeyPress = {handleEnter}
                            readOnly   = {readonly}/>
                        <input
                            className = {style?.input}
                            type      = "hidden"
                            name      = {`${name}_link`}
                            id        = {`${name}_link`}
                            readOnly  = {readonly}
                        />
                        <label htmlFor = {name} className = {`preview ${value ? 'show' : ''} ${readonly? 'disabled': ''}`}>
                            <img alt="image-preview" src={preview? preview: value} />
                            <small>{instruction}</small>
                        </label>
                    </>:
                    <input
                        type        = {
                            type != 'password' ? type :
                            show ? 'text' : 'password'
                        }
                        className   = {style?.input}
                        name        = {name}
                        id          = {name}
                        value       = {value || ''}
                        maxLength   = {max || 255}
                        minLength   = {min || 0}
                        placeholder = {placeholder || ''}
                        readOnly    = {readonly}
                        onKeyPress  = {handleEnter}
                        onChange    = {onChange}
                    />
                }
                {type === 'password' && !hasForgotLink && (showPassword === true || showPassword === undefined) && <span className="toggle" onClick={() => setShow(!show)}>{show ? 'Hide' : 'Show'}</span>}
                {type === 'password' && hasForgotLink && (
                    <span className="link">
                        <a href="/forgot-password">Forgot Password?</a>
                    </span>
                )}
                {focusTooltip && (
                    <section className="focus-tooltip">
                        {focusTooltip}
                    </section>
                )}
                {type !== 'hidden' && type !== 'checkbox' && <>
                    <div className={`${!error? 'valid': ''} icon icon-check`}></div>
                    <div className={`${error? 'error': ''} icon icon-error`}></div>
                </>}
                {loader && (
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                )}
            </section>
        }
        {textAreaCheckbox &&
            <div className="textarea-checkbox">
                <input
                    type     = "checkbox"
                    name     = {`textarea-checkbox-${name}`}
                    onClick  = {onTextAreaCheckboxChange}
                    checked  = {textAreaChecked}
                />
                <label htmlFor={`textarea-checkbox-${name}`}>{textAreaCheckbox}</label>
            </div>
        }
        <small>{error_message}</small>
        </section>
    </>
    )
}

export default InputField;