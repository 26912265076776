import ButtonLoader from "app/components/ButtonLoader";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSignUpState } from "../../selectors";
import { signUpActions } from "../../slice";
import SecureAccountModal from "../SecureAccountModal";
import { useStyles } from "../styles";

import AccountInformation from "./AccountInformation";
import BasicInformation from "./BasicInformation";
import PaymentPage from "./PaymentPage";
import SocialMedia from "./SocialMedia";

const AccountInfo = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const signup   = useSelector(selectSignUpState);
    const [validated, setValidated] = useState(false);
    const [showSecureModal, setShowSecureModal] = useState(false);
    
    const validateAccountInfo = () => {
        setValidated(true);
        return (
            signup.email &&
            signup.password && 
            signup.passwordConfirmed &&
            signup.firstName &&
            signup.lastName &&
            signup.username &&
            signup.username.length >= 3 && signup.username.length <=20 &&
            !signup.usernameExists &&
            // USER INFO
            signup.user_info?.middle_name &&
            signup.user_info?.address &&
            signup.user_info.mobile_number?.prefix &&
            signup.user_info.mobile_number?.number &&
            signup.user_info?.nationality &&
            signup.user_info?.birth_date &&
            signup.user_info?.birth_place &&
            signup.user_info?.profile_photo &&
            (// AT LEAST ONE SOCIAL MEDIA LINK
                signup.user_info?.social_media_links?.facebook ||
                signup.user_info?.social_media_links?.twitter ||
                signup.user_info?.social_media_links?.instagram ||
                signup.user_info?.social_media_links?.linkedin ||
                signup.user_info?.social_media_links?.website
            ) &&
            (// IF BUSINESS
                signup.user_info?.account_type?.type == 'individual' || (
                    signup.user_info?.account_type?.type == 'business' && (
                        !!signup.user_info?.account_type?.business.type &&
                        !!signup.user_info?.account_type?.business.category
                    )
                )
            )
        )
    }

    const next = () => {
        if (validateAccountInfo()) {
            dispatch(signUpActions.sendOtp())
            dispatch(signUpActions.saveState())
            setShowSecureModal(true)
        }
    }

    useEffect(() => {
        if (validated) {
            setValidated(false)
        }
        document.getElementsByClassName('error')?.[0]?.scrollIntoView({block: 'start', behavior: 'smooth'})
    }, [validated])

    return (<>
        <section>
            <h1 className={classes.header}>Set up your account</h1>
            <div className={classes.subheader}>Nothing fancy. Just a few things to create your account. Choose a Payment Address and personalize your Payment Page.</div>
        </section>
        <BasicInformation validated={validated} />
        <AccountInformation validated={validated} />
        <PaymentPage validated={validated} />
        <SocialMedia validated={validated} />
        <section className="footer">
            <ButtonLoader hasLoader
                loading = {signup.sendingOtp}
                name  = 'account_info'
                style = {`filled blue ${classes.button}`}
                text  = 'Next'
                onClick = {next}
            />
        </section>
        <SecureAccountModal state = {[showSecureModal, setShowSecureModal]} />
    </>)
});

export default AccountInfo;