import InputField from "app/components/InputField";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasSpecialChar, initials, removeSpaces } from "utils";
import { selectSignUpState } from "../../selectors";
import { signUpActions } from "../../slice";
import { useStyles } from "../styles";

interface Props {
    forReview?: boolean
    validated?: boolean
}
const AccountInformation = memo(({forReview, validated}: Props) => {
    const classes    = useStyles();
    const dispatch   = useDispatch();
    // const ip_info = useSelector(selectIP);
    const signUp     = useSelector(selectSignUpState);
    const number     = signUp.user_info?.mobile_number?.number;

    const [username   , setUsername]    = useState(signUp.username || '');
    const [password   , setPassword]    = useState(signUp.password || '');
    const [confirmPass, setConfirmPass] = useState('');

    const [emptyUsername   , setEmptyUsername]    = useState(false);
    const [errorUsername   , setErrorUsername]    = useState(false);
    const [invalidUsername , setInvalidUsername]  = useState(false);
    const [errorPassword   , setErrorPassword]    = useState(false);
    const [errorConfirmPass, setErrorConfirmPass] = useState(false);

    const checkPassword = password => (
        /* Not Empty               */ password !== '' &&
        /* Not same as useranme    */ password != username &&
        /* At least 8 characters   */ password.length >= 8 &&
        /* At least 1 lower case   */ /[a-z]/.test(password) &&
        /* At least 1 upper case   */ /[A-Z]/.test(password) &&
        /* At least 1 numerical    */ /[0-9]/.test(password) &&
        /* At least 1 special char */ /[-!@#$%^&*()_+[\]{};'`\\,./:"|<>?]/.test(password)
    )
    
    const passwordError = password => errorPassword? (
        /* Not Empty               */ password === ''         ? "This field is required":
        /* Not same as useranme    */ password == username    ? "Should not be the same as username":
        /* At least 8 characters   */ password.length < 8     ? "Should have at least 8 characters":
        /* At least 1 lower case   */ !/[a-z]/.test(password) ? "Should have at least 1 lower case":
        /* At least 1 upper case   */ !/[A-Z]/.test(password) ? "Should have at least 1 upper case":
        /* At least 1 numerical    */ !/[0-9]/.test(password) ? "Should have at least 1 numerical":
        /* At least 1 special char */ !/[-!@#$%^&*()_+[\]{};'`\\,./:"|<>?]/.test(password) ? "Should have at least 1 special character": ""
    ): "";

    const confirmPasswordError = confirmPass => (
        /* Not Empty               */ confirmPass === ''      ? "This field is required":
        /* Not Does not match      */ confirmPass != password ? "Passwords does not match": ""
    )

    const updateAccountInfo = () => {
        dispatch(signUpActions.setAccountInformation({username, password}))
    }

    useEffect(() => updateAccountInfo())
    useEffect(() => updateAccountInfo(),[username, password])

    useEffect(() => {
        if (validated) {
            setEmptyUsername(!username);
            setErrorPassword(!password);
            setErrorConfirmPass(!confirmPass || confirmPass !== password);
        }
    }, [validated])

    useEffect(() => {setErrorUsername(signUp.usernameExists === true)}, [signUp.usernameExists])

    useEffect(() => {
        dispatch(signUpActions.confirmPassword(!!confirmPass && confirmPass === password));
    }, [confirmPass])

    return <>
        <section className={classes.divider}><span>ACCOUNT INFORMATION</span></section>
        <section className="input-group" data-account-info>
            <InputField loader
                type        = 'text'
                name        = 'username'
                label       = 'Username'
                placeholder = 'Your Username'
                max         = {20}
                value       = {username}
                valid       = {!!username && !emptyUsername && !errorUsername && !invalidUsername}
                error       = {emptyUsername || errorUsername || invalidUsername}
                error_message = {
                    errorUsername ? "Username already exists":
                    emptyUsername ? "This field is required" :
                    invalidUsername ? "Invalid username format": ''
                }
                style       = {{container: `${classes.inputField} ${username ? 'valid' : ''}`}}
                tooltip     = {
                    <div className="body">
                        <h5>Here are a few things to keep in mind when you choose a username:</h5>
                        <ul>
                            <li>You can't claim a username someone else is already using.</li>
                            <li>Choose a usename you'll be happy with for the long term.</li>
                            <li>Usernames are not transferable.</li>
                            <li>A username different from your name can be subjected to review.</li>
                        </ul>
                    </div>
                }
                focusTooltip = {
                    !forReview ? <div className="body">
                        <h5>Suggested Username</h5>
                        {signUp.suggestedUsernames.map(username =>
                            <p className="pointer" onClick={() => {
                                setUsername(username);
                                setEmptyUsername(username === '');
                            }}>{username}</p>
                        )}
                    </div>: undefined
                }
                onChange = {e => {
                    if (!hasSpecialChar(e.target.value)) {
                        dispatch(signUpActions.usernameExists(undefined));
                        dispatch(signUpActions.ifUsernameExists(e.target.value));
                        setUsername(e.target.value);
                        setEmptyUsername(e.target.value === '');
                        setInvalidUsername(e.target.value.length < 3 || e.target.value.length > 20)
                    }
                }}
            />
            <InputField readonly
                type        = 'text'
                name        = 'payment_address_1'
                label       = 'Payment Address'
                value       = {username}
                style       = {{inputContainer: 'icon social icon-jpt', container: `${classes.inputField} ${username ? 'valid' : ''}`}}
                tooltip     = {
                    <div className="body">
                        <h5>What is a Payment Address?</h5>
                        <p>Your Payment Address enables you to receive money on the go! No need to worry about sharing your bank account number or giving complicated instructions. Your Payment Address is easily shareable on social media as well as your favorite chat apps.</p>
                    </div>
                }
            />
            <InputField
                type        = 'password'
                name        = 'password'
                label       = 'Password'
                placeholder = 'Enter Password'
                value       = {password}
                error       = {errorPassword}
                error_message = {passwordError(password)}
                tooltip     = {
                    <div className="body">
                        <p>{'Password should be at least 8 characters, lowercase (a-z) character, uppercase (A-Z) character, at least one numeric character (0-9), at least one special character (-!@#$%^&*()_+[]{};\'`\\,./:"|<>?) and should not be the same with the username'}</p>
                    </div>
                }
                onChange = {e => {
                    setPassword(e.target.value);
                    setErrorPassword(!checkPassword(e.target.value));
                }}
            />
            <InputField
                type        = 'password'
                name        = 'password_confirmation'
                label       = 'Confirm Password'
                placeholder = 'Repeat Password'
                style       = {{container: classes.inputField}}
                value       = {!forReview? confirmPass : password}
                error       = {errorConfirmPass && !!confirmPasswordError(confirmPass)}
                error_message = {errorConfirmPass && confirmPasswordError(confirmPass) || ''}
                onChange = {e => {
                    setConfirmPass(e.target.value);
                    setErrorConfirmPass(!e.target.value || e.target.value !== password);
                }}
            />
        </section>
    </>
});

export default AccountInformation;