import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import logo from 'assets/logo.svg';
import userManager from 'app/common/auth/user_manager';

import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    AppBar,
    Box,
    Button,
    List,
    ListItem,
    Toolbar,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.color.primary.pureWhite,
        borderBottom: `${theme.palette.border.default} ${theme.palette.color.primary.grey}`,
    },
    toolbar: {
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
        padding: '16px 0',
    },
    list: {
        display: 'flex',
        padding: 0,
    },
    item: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: 'auto',
        padding: 0,
        color: theme.palette.color.primary.blue,
    },
    button: {
        justifyContent: 'flex-start',
        width: '100%',
        padding: '0 8px',
        fontFamily: theme.palette.baseFont,
        fontWeight: theme.palette.fontWeight.normal,
        color: 'inherit',
        letterSpacing: '1px',
        lineHeight: '24px',
        textTransform: 'none',
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    title: {
        marginRight: 'auto',
    },
    divider: {
        display: 'block',
        position: 'static',
        margin: '0 12px',
        width: '1px',
        height: '24px',
        borderLeft: `${theme.palette.border.default} ${theme.palette.color.primary.black}`,
    },
}));

const TopBar = ({ className, ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const verInfo = `${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_ENV})`;
    const menuList = rest?.currentuser && Object.keys(rest?.currentuser).length && !rest?.currentuser?.isTopBarHidden ? [{
        href: '/user-dashboard',
        title: t('menu_account'),
    }, {
        href: '/logout',
        title: t('menu_logout'),
    }] : [{
        href: '/',
        title: t('menu_login'),
    }, {
        href: '/signup',
        title: t('menu_signup'),
    }];
    const handleLogin = (event) => {
        if (event.target.textContent === t('menu_login')) {
            event.preventDefault();

            userManager.signinRedirect({
                data: { path: window.location.pathname },
            });
        }
    };

    return (
        <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
            <Toolbar className={clsx(classes.toolbar, className)}>
                <RouterLink to="/">
                    <img
                        src={logo}
                        height="24"
                        alt={verInfo}
                        title={verInfo}
                    />
                </RouterLink>
                <Box>
                    <List className={clsx(classes.list, className)}>
                        {menuList.map((item, i) => (
                            <ListItem
                                key={item.title}
                                className={clsx(classes.item, className)}
                                disableGutters
                                {...rest}
                            >
                                <Button
                                    activeclassname={classes.active}
                                    className={classes.button}
                                    component={RouterLink}
                                    onClick={e => handleLogin(e)}
                                    to={item.href}
                                >
                                    <span className={classes.title}>{item.title}</span>
                                </Button>
                                {(menuList[i + 1]
                                    ? <span className={classes.divider}></span>
                                    : ''
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
};

export default TopBar;
