import { selectUserInfo } from "app/common/auth/selectors";
import InputField from "app/components/InputField";
import { ContactlessInfo } from "app/containers/Onboarding/types";
import imageCompression from "browser-image-compression";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Switch from "../../Switch";
import Section from "./Section";

interface Props {
    onChange: (form: ContactlessInfo) => void
}

const Contactless = memo(({onChange}: Props) => {
    const user = useSelector(selectUserInfo)

    const [status, setStatus]                         = useState<'on' | 'off'>(user?.contactless?.status || 'off')
    const [background_image, setBackgroundImage]      = useState(user?.contactless?.background_image || '')
    const [contact_tracing_url, setContactTracingUrl] = useState(user?.contactless?.contact_tracing_url || '')

    useEffect(() => onChange({status, background_image, contact_tracing_url}), [status, background_image, contact_tracing_url])

    const setBackground = async (file) => {
        setBackgroundImage('');
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 5,
            useWebWorker: true
        });
        const imageUri    = await imageCompression.getDataUrlFromFile(compressedFile)
        setBackgroundImage(imageUri)
    }

    return <Section title="CONTACTLESS ORDERING">
        <Switch
            name      = "gocontactless_url"
            title     = "GoContactless URL (Optional)"
            checked   = {status == 'on'}
            onChecked = {() => setStatus(status == 'off'? 'on': 'off')}
        >
            This enables your{' '}
            <a href={`${process.env.REACT_APP_GOCONTACTLESS_URL}/login`} target="_blank">GoContactless Link</a>{' '}
            to be available in your Payment Page. If you do not have{' '}
            <a href={`${process.env.REACT_APP_GOCONTACTLESS_URL}/login`} target="_blank">GoContactless Link</a>{' '}
            yet, please email our support center at{' '}
            <a href="mailto:hello@justpay.to" style={{textDecoration: 'none'}}>hello@justpay.to</a>
        </Switch>
        <InputField
            type    = 'file'
            label   = 'Order/Pay Selection Background Image (Optional)'
            name    = 'contactless_background_image'
            accept  = 'image/jpeg, image/jpg, image/png'
            style   = {{container: 'full-width'}}
            value   = {background_image}
            onChange = {e => e.target.files && setBackground(e.target.files[0])}
        />
        <InputField
            type        = "text"
            name        = "contact_tracing_url"
            label       = "Contact Tracing URL (Optional)"
            placeholder = "https://www.yourcontacttracinglink.com"
            value       = {contact_tracing_url}
            onChange    = {e => setContactTracingUrl(e.target.value)}
            style       = {{
                inputContainer: 'icon icon-link',
                container: 'full-width'
            }}
            // tooltip = {
            //     <div className="body">
            //         <p>Use this window to show Contact Tracing Link for your customers. Provide the URL of a web page, an image, or a form you want to use</p>
            //     </div>
            // }
        />
    </Section>
})

export default Contactless