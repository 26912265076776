import { createSelector } from '@reduxjs/toolkit';
import { RootState }      from 'types';
import { initialState }   from './slice';

const selectDomain = (state: RootState) =>
    state.bankVerificationPage || initialState;

export const selectBankVerification = createSelector(
    [selectDomain],
    bankVerificationPage => bankVerificationPage,
);
