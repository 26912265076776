import React, { memo, ReactNode } from 'react';

interface Props {
    name?     : string
    title?    : string
    checked?  : boolean
    children? : ReactNode
    onChecked?: (e) => void
}

const ToggleSwitch = memo(({
    name, title, checked, children, onChecked
}: Props) => {
    return (
        <section jpt-switch={ name } className="switch">
            <header>
                <h4>{title}</h4>
                <label>
                    <input type="checkbox" name={ name } checked={checked} onChange={onChecked} />
                    <div></div>
                </label>
            </header>
            {children && <p>{children}</p>}
        </section>

    )
})

export default ToggleSwitch;