import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import Calendar from './Calendar'

interface Props {
    status    : boolean
    set       : (value) => void
    text      : string
    setFilter : (set, value) => void
    calendar? : boolean
    startDate?: string
    endDate?  : string
}

const FilterButton = memo(({status, set, text, setFilter, calendar, startDate, endDate}: Props) => {
    const [showCalendar, setShowCalendar] = useState(false);

    useEffect(() => setShowCalendar(status), [status])
    useEffect(() => setShowCalendar(false), [])

    return <div className="filter-button">
        <button
            className = {status ? "active" : ""}
            onClick   = {() => {
                setFilter(set, true)
                setShowCalendar(!showCalendar)
            }}
        >
            {text}
        </button>
        {calendar && <Calendar
            className = {`calendar ${!showCalendar && 'hide'}`}
            start     = {startDate || ''}
            end       = {endDate || ''}
        />}
    </div>
})

export default FilterButton