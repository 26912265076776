import { CircularProgress, Grid } from "@material-ui/core";
import ButtonLoader from "app/components/ButtonLoader";
import SuccessModal from "app/components/Modal/SuccessModal";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddModal from "./AddModal";
import Category from "./Category";

const Categories = memo(() => {

    const dispatch = useDispatch();

    const userDashboard = useSelector(selectUserDashboard)
    const categories    = userDashboard.categories
    const api_version   = userDashboard.api_version

    const [success, setSuccess] = useState(false)

    const onCategoryChange = (category) => {
        const newCategories = categories === undefined? []: [...categories]
        const index = newCategories.findIndex(c => c.id == category.id)
        if (index != -1) {
            newCategories[index] = {...newCategories[index], ...category}
        }
        dispatch(userDashboardPageActions.setCategories(newCategories))
        dispatch(userDashboardPageActions.updateActionTime())
    }

    useEffect(() => setSuccess(userDashboard.removingCategories === false), [userDashboard.removingCategories])

    return <Grid container className="multi-input-category-container">
        <SuccessModal state = {[success, setSuccess]} message = "Removed Category" onClose={() => dispatch(userDashboardPageActions.resetRemovingCategories())}/>
        <Grid item xs={12} container className="category-header">
            <Grid item xs={8} className="title">
                <h3>Category</h3>
            </Grid>
            {api_version == "1" &&
            <Grid item xs={2} className="btn-container">
                <AddModal/>
            </Grid>}
            {api_version == "1" &&
            <Grid item xs={2} className="btn-container">
                <ButtonLoader
                    loading = {userDashboard.removingCategories}
                    style   = "bordered grey is-uppercase"
                    text    = "Remove"
                    onClick = {() => {
                        if (categories?.filter(cat => cat.checked)?.length) {
                            dispatch(userDashboardPageActions.removeCategories())
                        }
                    }}
                />
            </Grid>}
        </Grid>
        <Grid item xs={12}>
            {
                categories !== undefined?
                categories.map(category =>
                    <Category {...category} checked={false} onChange={category => onCategoryChange(category)} />
                ):
                <div style={{textAlign: 'center'}}>
                    <CircularProgress />
                </div>
            }
        </Grid>
    </Grid>
})

export default Categories