import moment from 'moment'
import React, { memo } from 'react'

interface Props {
    direction     : string
    placeholder   : string
    value         : string
    set           : (value) => void
    setRangeFilter: (set, value) => void
}

const FilterDate   = memo(({direction, placeholder, value, set, setRangeFilter}: Props) => (
    <section className={`icon right icon-date-${direction}`}>
        <input required
            type        = "date"
            placeholder = {placeholder}
            className   = "hasDatepicker"
            value       = {value}
            max         = {moment().format('YYYY-MM-DD')}
            onChange    = {e => setRangeFilter(set, e.target.value)}
        />
    </section>
    // <MuiPickersUtilsProvider utils={MomentUtils}>
    //     <KeyboardDatePicker disableToolbar
    //         name     = {`${direction}-date`}
    //         variant  = "inline"
    //         format   = "MM/DD/YYYY"
    //         margin   = "normal"
    //         value    = {new Date(value)}
    //         onChange = {date => setRangeFilter(set, date?.format('YYYY-MM-DD'))}
    //     />
    // </MuiPickersUtilsProvider>
))

export default FilterDate