import ButtonLoader from "app/components/ButtonLoader";
import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserDashboard } from "../../selectors";

interface Props extends DefaultModalProps {
    onConfirm?  : (cancel?: boolean) => void
    onCancel ?  : () => void
    onClose  ?  : () => void
    cancel?     : boolean
    transaction?: any
}

const DeliveryConfirmModal = memo(({state, transaction, cancel, onConfirm, onCancel, onClose}: Props) => {
    const [loading, setLoading] = useState<boolean | undefined>()

    const userDashboard = useSelector(selectUserDashboard)

    useEffect(() => {
        if (loading === true) {
            setLoading(false)
        }
    }, [userDashboard.transactions])
    useEffect(() => {
        if (loading === false) {
            state[1](false)
            setLoading(undefined)
            if (onClose) {
                onClose()
            }
        }
    }, [loading])

    return (
        <Modal state={state} style="transaction" onClose={onClose}>
            <h2 className="status">{
                cancel? "Confirm cancel of order/item?": "Confirm order/items ready for pick-up by partner couriers?"
            }</h2>
            <h3 className="status-details">
                <small className="transaction-number">
                    Transaction Number: <span>{transaction?.payment_id}</span>
                </small>
            </h3>
            <section className="divider"></section>
            <div className="message">
                <h3>
                    <span>{transaction?.['sender_details']?.message}</span>
                </h3>
                <div>- {transaction?.['sender_details']?.name}</div>
            </div>
            <section className="divider"></section>
            <ButtonLoader hasLoader
                loading = {loading}
                name   = "close_transaction_modal"
                style  = "filled green"
                text   = "CONFIRM"
                onClick = {() => {
                    setLoading(true)
                    if (onConfirm) {
                        onConfirm(cancel)
                    }
                }}
            />
            <ButtonLoader hasLoader
                loading = {loading}
                name   = "close_transaction_modal"
                style  = "filled red"
                text   = "CANCEL"
                onClick = {onCancel}
            />
        </Modal>
    )
})

export default DeliveryConfirmModal