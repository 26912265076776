import { Grid } from '@material-ui/core'
import FilterDate from 'app/components/FilterDate'
import { selectUserDashboard } from 'app/containers/UserDashboardPage/selectors'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numberFormat, roundOff } from 'utils'

import { selectUserInfo } from 'app/common/auth/selectors'
import { userDashboardPageActions } from 'app/containers/UserDashboardPage/slice'
import moment from 'moment'
import FilterButton from './FilterButton'
import LineGraph from './LineGraph'

const LineGraphs = memo(() => {

    const dispatch                  = useDispatch()
    const user                      = useSelector(selectUserInfo);
    const userDashboard             = useSelector(selectUserDashboard)
    const [isPremium, setIsPremium] = useState(false);

    const [frequency, setFrequency] = useState('daily');
    const [duration, setDuration]   = useState<string | undefined>('week');
    const [date1, setDate1]         = useState<string | undefined>();
    const [date2, setDate2]         = useState<string | undefined>();

    const defaultTotal = {main: 0, date1: 0, date2: 0}
    const [totalGrossSales, setTotalGrossSales]     = useState<any>(defaultTotal)
    const [totalNewCustomers, setTotalCustomers]    = useState<any>(defaultTotal)
    const [overallNetVolumes, setOverallNetVolumes] = useState<any>(defaultTotal)
    const [overallAvgRevenue, setOverallAvgRevenue] = useState<any>(defaultTotal)
    
    const [totalCompletedTransactions, setTotalCompletedTransactions] = useState<any>(defaultTotal)
    const [totalVerifiedTransactions, setTotalVerifiedTransactions]   = useState<any>(defaultTotal)
    const [totalPendingTransactions, setTotalPendingTransactions]     = useState<any>(defaultTotal)
    const [totalOnHoldTransactions, setTotalOnHoldTransactions]      = useState<any>(defaultTotal)
    const [totalFailedTransactions, setTotalFailedTransactions]       = useState<any>(defaultTotal)

    const setDateFilter = (set, value) => set(value)

    const amountFormatter = value => `₱ ${numberFormat(value)}`

    const computeTotal = (data, key) => data?.reduce((total, data) => total + data[key], 0) || 0

    const computeAvg   = (total, count) => count? total/count: 0

    useEffect(() => setIsPremium(!!(
        user?.groups?.includes('Premium') ||
        user?.groups?.includes('Partners')
    )), [user])

    useEffect(() => {
        if (duration && isPremium) {
            setDate1(undefined)
            setDate2(undefined)
            dispatch(userDashboardPageActions.resetGraphs())
            dispatch(userDashboardPageActions.getLineGraphs({...getDates(), frequency}))
        }
    }, [duration, frequency, isPremium])

    useEffect(() => {
        if (date1 && date2 && isPremium) {
            setDuration(undefined)
            dispatch(userDashboardPageActions.resetGraphs())
            dispatch(userDashboardPageActions.getLineGraphs({frequency, date1, date2}))
        }
    }, [frequency, date1, date2])

    const getDates = () => {
        switch (duration) {
            case 'week' : return {
                date1: moment().subtract(1, 'week').format('YYYY-MM-DD'),
                date2: moment().format('YYYY-MM-DD'),
            }
            case 'month': return {
                date1: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                date2: moment().format('YYYY-MM-DD'),
            }
            case 'year' : return {
                date1: moment().subtract(1, 'year').format('YYYY-MM-DD'),
                date2: moment().format('YYYY-MM-DD'),
            }
            case 'mtd'  : return {
                date1: moment().startOf('month').format('YYYY-MM-DD'),
                date2: moment().format('YYYY-MM-DD'),
            }
            case 'qtd'  : return {
                date1: moment().startOf('quarter').format('YYYY-MM-DD'),
                date2: moment().format('YYYY-MM-DD'),
            }
            case 'ytd'  : return {
                date1: moment().startOf('year').format('YYYY-MM-DD'),
                date2: moment().format('YYYY-MM-DD'),
            }
        }
        return {date1: undefined, date2: undefined}
    }
    
    const getTotal = (setter, dataKey, valueKey) => {
        setter({
            main : duration? computeTotal(userDashboard[dataKey], valueKey): undefined,
            date1: computeTotal(userDashboard[dataKey], `${valueKey}1`),
            date2: computeTotal(userDashboard[dataKey], `${valueKey}2`),
        })
    }

    useEffect(() => getTotal(setTotalGrossSales  , 'grossSales'  , 'Amount'   ), [userDashboard.grossSales])
    useEffect(() => getTotal(setTotalCustomers   , 'newCustomers', 'Customers'), [userDashboard.newCustomers])
    useEffect(() => getTotal(setOverallNetVolumes, 'netVolumes'  , 'Count'    ), [userDashboard.netVolumes])
    useEffect(() => setOverallAvgRevenue({
        main : duration? computeAvg(totalGrossSales.main, overallNetVolumes.main): undefined,
        date1: computeAvg(totalGrossSales.date1, overallNetVolumes.date1),
        date2: computeAvg(totalGrossSales.date2, overallNetVolumes.date2),
    }), [totalGrossSales, overallNetVolumes])

    
    useEffect(() => getTotal(setTotalCompletedTransactions, 'completedTransactions', 'Count'    ), [userDashboard.completedTransactions])
    useEffect(() => getTotal(setTotalVerifiedTransactions, 'verifiedTransactions' , 'Count'    ), [userDashboard.verifiedTransactions])
    useEffect(() => getTotal(setTotalPendingTransactions, 'pendingTransactions'  , 'Count'    ), [userDashboard.pendingTransactions])
    useEffect(() => getTotal(setTotalOnHoldTransactions, 'onHoldTransactions'   , 'Count'    ), [userDashboard.onHoldTransactions])
    useEffect(() => getTotal(setTotalFailedTransactions, 'failedTransactions'   , 'Count'    ), [userDashboard.failedTransactions])

    const randomData = mainKey => {
        const data: any[] = []
        for (var i=0; i<8; i++) {
            const row = {}
            row[mainKey] = roundOff(Math.random() * 100)
            data.push(row)
        }
        return data
    }

    return (
        <Grid container spacing={3} className="container-grid line-graph">
            <Grid container className="standard-grid">
                <Grid item xs={12}>
                    <div className="dataTables_filter">
                        <section className="date-filter-1">
                            <FilterButton status={frequency == 'hourly'} setFilter={() => setFrequency('hourly')} text="Hourly"/>
                            <FilterButton status={frequency == 'daily'}  setFilter={() => setFrequency('daily')} text="Daily"/>
                        </section>
                        <section className="date-filter-1">
                            <FilterButton status={duration == 'week'}  setFilter={() => setDuration('week') } text="1 Week"/>
                            <FilterButton status={duration == 'month'} setFilter={() => setDuration('month')} text="1 Month"/>
                            <FilterButton status={duration == 'year'}  setFilter={() => setDuration('year') } text="1 Year"/>
                            <FilterButton status={duration == 'mtd'}   setFilter={() => setDuration('mtd')  } text="Mtd"/>
                            <FilterButton status={duration == 'qtd'}   setFilter={() => setDuration('qtd')  } text="Qtd"/>
                            <FilterButton status={duration == 'ytd'}   setFilter={() => setDuration('ytd')  } text="Ytd"/>
                        </section>
                        <section className="date-filter-2">
                            <FilterDate direction="to" placeholder="Start Date" value={date1 || ''} set={setDate1} setRangeFilter={setDateFilter}/>
                            <span>vs</span>
                            <FilterDate direction="to" placeholder="Start Date" value={date2 || ''} set={setDate2} setRangeFilter={setDateFilter}/>
                        </section>
                    </div>
                </Grid>
            </Grid>
            <LineGraph
                title     = "Gross Sale"
                total     = {totalGrossSales}
                mainKey   = "Amount"
                data      = {isPremium? userDashboard.grossSales: randomData("Amount")}
                formatter = {amountFormatter}
                frequency = {frequency}
            />
            <LineGraph
                title     = "New Customer"
                total     = {totalNewCustomers}
                mainKey   = "Customers"
                data      = {isPremium? userDashboard.newCustomers: randomData("Customers")}
                frequency = {frequency}
            />
            <LineGraph
                title     = "Net Volume from sale"
                total     = {overallNetVolumes}
                name      = "Net Volume"
                mainKey   = "Count"
                data      = {isPremium? userDashboard.netVolumes: randomData("Count")}
                frequency = {frequency}
            />
            <LineGraph
                title     = "Average Revenue per Customer"
                total     = {overallAvgRevenue}
                mainKey   = "Amount"
                data      = {isPremium? userDashboard.avgRevenues: randomData("Amount")}
                formatter = {amountFormatter}
                frequency = {frequency}
            />
            <LineGraph
                title     = "Completed Transactions"
                total     = {totalCompletedTransactions}
                name      = "Transactions"
                mainKey   = "Count"
                data      = {isPremium? userDashboard.completedTransactions: randomData("Count")}
                frequency = {frequency}
            />
            <LineGraph
                title     = "For Settlement Transactions"
                total     = {totalVerifiedTransactions}
                name      = "Transactions"
                mainKey   = "Count"
                data      = {isPremium? userDashboard.verifiedTransactions: randomData("Count")}
                frequency = {frequency}
            />
            <LineGraph
                title     = "Pending Transactions"
                total     = {totalPendingTransactions}
                name      = "Transactions"
                mainKey   = "Count"
                data      = {isPremium? userDashboard.pendingTransactions: randomData("Count")}
                frequency = {frequency}
            />
            <LineGraph
                title     = "On Hold Transactions"
                total     = {totalOnHoldTransactions}
                name      = "Transactions"
                mainKey   = "Count"
                data      = {isPremium? userDashboard.onHoldTransactions: randomData("Count")}
                frequency = {frequency}
            />
            <LineGraph
                title     = "Failed Transactions"
                total     = {totalFailedTransactions}
                name      = "Transactions"
                mainKey   = "Count"
                data      = {isPremium? userDashboard.failedTransactions: randomData("Count")}
                frequency = {frequency}
            />
        </Grid>
    )
})

export default LineGraphs