import React, { memo } from "react";
import Modal, { DefaultModalProps } from 'app/components/Modal';
import success_icon from 'assets/img/success.svg';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

interface Props extends DefaultModalProps {
    message?: any
}

const CongratulationsModal = memo(({state, message}: Props) => {
    const classes = useStyles();
    return <Modal
        disableEscapeKeyDown
        disableBackdropClick
        state={state}
        dialogClassname={classes.modal}
    >
        <h2 className={classes.title}>Congratulations</h2>
        <img className={classes.icon} src={success_icon} alt="Success Icon"/>
        <p className={classes.message}>{message}</p>
    </Modal>
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            width: "500px",
            textAlign: "center",
        },
        title: {
            fontSize: "20px",
            fontWeight: "bold"
        },
        icon: {
            width: "100px",
            margin: "1rem 0"
        },
        message: {
            ["& > span"]: {
                color: "#777777"
            }
        }
    })
)

export default CongratulationsModal