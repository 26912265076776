import ButtonLoader from "app/components/ButtonLoader";
import IconSelectField from "app/components/IconSelectField";
import InputField from "app/components/InputField";
import React from "react";
import { memo } from "react";
import { field_types, icons } from "../options";

interface Props {
    name: string
    parameter: any
    onChange : (any) => void
}

const ParameterField = memo(({name, parameter, onChange}: Props) => {

    return (
        <section className="parameter-field">
            <section className="checkbox-container">
                <InputField
                    type  = "checkbox"
                    name  = {`${name}_checkbox`}
                    value = {parameter.checked || false}
                    onChange = {() => onChange({...parameter, checked: !parameter.checked})}
                />
            </section>
            <div className="form">
                <section className='radio-group'>
                    <InputField
                        type  = "radio"
                        name  = {`${name}_type`}
                        title = "Input"
                        value = {parameter.type}
                        checked = {parameter.type == "text"}
                        onChange = {() => onChange({...parameter, type: "text"})}
                    />
                    <InputField
                        type  = "radio"
                        name  = {`${name}_type`}
                        title = "Option"
                        value = {parameter.type}
                        checked = {parameter.type == "option"}
                        onChange = {() => onChange({...parameter, type: "option"})}
                    />
                </section>
                <section className='input-group'>
                    <InputField
                        type        = "text"
                        placeholder = "ID"
                        value       = {parameter?.id}
                        onChange    = {e => onChange({...parameter, id: e.target.value})}
                    />
                    <IconSelectField
                        placeholder = "Label"
                        options     = {icons.bayadcenter}
                        text        = {parameter?.label}
                        icon        = {parameter?.icon}
                        onTextChange = {label => onChange({...parameter, label})}
                        onIconChange = {icon  => onChange({...parameter, icon})}
                    />
                    <InputField
                        type        = "text"
                        placeholder = "Tooltip"
                        style       = {{container: "full-width"}}
                        value       = {parameter?.tooltip}
                        onChange    = {e => onChange({...parameter, tooltip: e.target.value})}
                    />
                    <InputField
                        type        = "select"
                        placeholder = "Select Validation"
                        style       = {{container: "full-width"}}
                        value       = {parameter?.validation}
                        options     = {Object.keys(field_types).map(
                            type => (
                                <option value={type} selected={type == parameter?.validation}>
                                    {field_types[type]}
                                </option>
                            )
                        )}
                        onSelectChange = {e => onChange({...parameter, validation: e.target.value})}
                    />
                    {
                        parameter.type == "option" && parameter.options?.map(
                            (option, i) => <>
                                <InputField
                                    type        = "text"
                                    placeholder = "ID"
                                    value       = {option.id}
                                    onChange    = {e => {
                                        const options =  [...parameter.options]
                                        options[i] = {...option, id: e.target.value}
                                        onChange({...parameter, options})
                                    }}
                                />
                                <InputField
                                    type        = "text"
                                    placeholder = "Label"
                                    value       = {option.label}
                                    onChange    = {e => {
                                        const options =  [...parameter.options]
                                        options[i] = {...option, label: e.target.value}
                                        onChange({...parameter, options})
                                    }}
                                />
                            </>
                        )
                    }
                </section>
                {parameter.type == "option" && <ButtonLoader
                    style="filled blue"
                    text="ADD OPTION"
                    onClick = {() => {
                        const options = parameter.options? [...parameter.options]: []
                        options.push({id: "", label: ""})
                        onChange({...parameter, options})
                    }}
                />}
            </div>
        </section>
    )
})

export default ParameterField