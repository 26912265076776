import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { baseStyles } from "../base-styles"

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            boxSizing: "border-box",
            width    : "100%",
            padding  : "1.75rem 0.5rem",
            fontFamily: baseStyles.fontFamily,
            ["@media(min-width: 414px)"]: {
                padding  : "1.75rem",
            },
            ["& .MuiLinearProgress-determinate"]: {
                backgroundColor: baseStyles.color.disabled
            }
        },
        smallModal: {
            width: "auto",
            ["& .modal"]: {
                width: "auto"
            }
        },
        signatureModal: {
            maxWidth: "calc(100vw - 10px)!important",
            margin: "0 5px",
            ["& .modal"]: {
                padding: "40px",
                width: "100%",
                maxWidth: "500px",
                ["& .content"]: {
                    ["& button:last-of-type"]: {
                        marginBottom: "2.62px"
                    }
                }
            }
        },
        progressModal: {
            maxHeight: "100vh",
            height: "635px",
            width: "350px",
            lineHeight: "20px",
            ["& .footer"]: {
                border: 0
            },
            ["& .modal"]: {
                padding: "40px 0",
                maxWidth: "100%!important",
                ["& .header"]: {
                    padding: "0 5px",
                },
                ["& .body, & .footer"]: {
                    padding: "0 10px",
                }
            },
            ["@media(min-width: 375px)"]: {
                width: "400px",
                height: "fit-content",
                ["& .modal"]: {
                    ["& .header"]: {
                        padding: "0 29px!important",
                    },
                    ["& .body, & .footer"]: {
                        padding: "0 30px!important",
                    }
                }
            },
            ["@media(min-width: 414px)"]: {
                ["& .modal"]: {
                    ["& .header"]: {
                        padding: "0 5px",
                    },
                    ["& .body, & .footer"]: {
                        padding: "0 30px",
                    }
                }
            },
            ["@media(min-width: 768px)"]: {
                width: "500px",
                ["& .modal"]: {
                    padding: "40px",
                }
            },
        },
        progress: {
            marginBottom: "1.5rem"
        },
        progressContainer: {
            textAlign   : "center",
            marginBottom: "0.75rem",
            fontSize    : "14px",
            color       : baseStyles.color.light,
            ["& .active"]: {
                color: baseStyles.color.dark,
            },
        },
        header: {
            lineHeight: "normal",
            fontSize: "33px!important",
            marginBottom: "30px"
        },
        subheader: {
            fontSize: "14px",
            color: baseStyles.color.light,
            marginBottom: "40px",
            lineHeight: "20px"
        },
        footer: {
            fontSize: "14px",
            color: baseStyles.color.light,
            margin: "40px 0",
            lineHeight: "20px",
            ['& > a']: {
                fontWeight: "bold"
            }
        },
        divider: {
            fontSize      : "14px",
            textTransform : "capitalize",
            margin        : "40px 0",
            height        : "1px",
            display       : "flex",
            alignItems    : "center",
            justifyContent: "space-between",
            ["&:before, &:after"]: {
                content  : "' '",
                flex     : "1 1 auto",
                borderTop: "1px solid #ccc",
            },
            ["& span"]: {
                flex         : "0 0 auto",
                maxWidth     : "90%",
                display      : "block",
                letterSpacing: "0.15rem",
                padding      : "0 0.5rem",
            }
        },
        button: {
            fontFamily: "Arial",
            fontSize: "17px"
        },
        secureAccountModalContainer: {
            padding: 0
        },
        secureAccountModal: {
            margin: 0,
            borderRadius: "1rem",
            fontFamily: baseStyles.fontFamily,
            color: baseStyles.color.dark,
            fontSize: "14px",
            padding: "0",
            maxHeight: "100vh",
            ['@media(min-width: 375px)']: {
                width: "370px",
            },
            ['@media(min-width: 414px)']: {
                width: "380px",
            },
            ['@media(min-width: 768px)']: {
                width: "500px",
            },
            ["& .modal"]: {
                width: "100%",
                padding: "40px",
                ["& button"]: {
                    margin: 0
                },
                ["& .modal-divider"]: {
                    marginBottom: "30px",
                    lineHeight: "27px"
                }
            },
            ["& .header"]: {
                fontSize: "33px!important"
            },
            ["& .subheader"]: {
                padding: "0",
                letterSpacing: "1px",
                lineHeight: "1.25rem",
                marginBottom: "40px",
                ["@media(min-width: 768px)"]: {
                    padding  : "0 3rem",
                },
            },
            ["& .input-group"]: {
                marginBottom: 0
            },
            ["& span.highlight"]: {
                color: baseStyles.color.link
            },
            ["& .twofa-timer"]: {
                margin: "0 0 30px",
                fontSize: "12px",
                color: baseStyles.color.light,
                ["& span"]: {
                    color: baseStyles.color.link
                }
            }
        },
        secureAccountModalLabel: {
            ['@media(min-width: 768px)']: {
                paddingRight: "120px",
            },
        },
        processingModal: {
            fontFamily: baseStyles.fontFamily,
            width: "500px",
            height: "197px",
            ["& .modal"]: {
                width: "100%",
                height: "100%",
                ["& .content .progress"]: {
                    margin: 0
                }
            },
            ["& .progress-bar__indicator"]: {
                width: "100%",
                height: "30px!important"
            },
            ["& h3"]: {
                fontSize: "33px!important",
                marginBottom: "2.5rem"
            }
        },
        inputField: {
            marginBottom: "30px!important",
            ['& > small']: {
                position: "absolute"
            }
        }
    })
)