import { selectUserInfo } from "app/common/auth/selectors";
import { selectCountries } from "app/common/selectors";
import InputField from "app/components/InputField";
import { MobileNumber } from "app/containers/UserDashboardPage/types";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatMobile } from "utils";
import { clients } from "utils/options";
import Section from "./Section";

interface Form {
    name         : string
    firstName    : string
    lastName     : string
    middleName   : string
    nationality  : string
    birthdate    : string
    birthplace   : string
    mobile_number: MobileNumber
    address      : string
}

interface Props {
    onChange: (form: Form) => void
}

const PersonalInformation = memo(({onChange}: Props) => {
    const user = useSelector(selectUserInfo)
    const countries = useSelector(selectCountries)

    const isBusiness       = user?.account_type?.type == 'business'
    const isApi            = user?.username && user?.groups?.includes(user.username)
    const isClient         = clients.includes(user?.username || '')
    const countriesOptions = countries?.reduce((countries, country) => ({...countries, [country.code]: country.name}), {})
    const isIndividual     = user?.account_type?.type == 'individual';

    const [name, setName]               = useState(user?.name || "");
    const [firstName, setFirstName]     = useState(user?.firstName || "");
    const [lastName, setLastName]       = useState(user?.lastName || "");
    const [middleName, setMiddleName]   = useState(user?.middle_name || "");
    const [nationality, setNationality] = useState(user?.nationality || "");
    const [birthdate, setBirthdate]     = useState(user?.birth_date || "");
    const [birthplace, setBirthplace]   = useState(user?.birth_place || "");
    const [prefix, setMobilePrefix]     = useState(user?.mobile_number?.prefix || "");
    const [number, setMobileNumber]     = useState(user?.mobile_number?.number || "");
    const [address, setAddress]         = useState(user?.address || "");
    const [country, setCountry]         = useState(countries?.find(country => country.mobile_code == prefix)?.code || "")

    useEffect(() => onChange({
        name,
        firstName,
        lastName,
        middleName,
        nationality,
        birthdate,
        birthplace,
        mobile_number: {
            prefix,
            number
        },
        address
    }), [
        firstName,
        lastName,
        middleName,
        nationality,
        birthdate,
        birthplace,
        prefix,
        number,
        address
    ])

    useEffect(() => {
        isIndividual && setName(`${firstName} ${lastName}`)
    }, [firstName, lastName])

    useEffect(() => setCountry(countries?.find(country => country.mobile_code == prefix)?.code || ""), [countries, prefix])

    return <Section title={isApi? "REPRESENTATIVE INFORMATION": "PERSONAL INFORMATION"}>
        <InputField readonly
            type        = "text"
            name        = "first_name"
            label       = "First Name"
            placeholder = "Juan"
            value       = {firstName}
            onChange    = {e => setFirstName(e.target.value)}
        />
        <InputField readonly
            type        = "text"
            name        = "last_name"
            label       = "Last Name"
            placeholder = "Dela Cruz"
            value       = {lastName}
            onChange    = {e => setLastName(e.target.value)}
        />
        <InputField readonly
            type        = "text"
            name        = "middle_name"
            label       = "Middle Name"
            placeholder = "Santos"
            value       = {middleName}
            style       = {{container: middleName? 'valid': ''}}
            onChange    = {e => setMiddleName(e.target.value)}
        />
        <InputField readonly
            type           = "select"
            name           = "country"
            label          = "Country"
            value          = {Object.keys(countriesOptions || {}).includes(nationality)? nationality : nationality}
            placeholder    = "Select your Nationality"
            style          = {{container: nationality? 'valid': ''}}
            options        = {countriesOptions && Object.keys(countriesOptions).map(country => <option value={country} disabled={country != 'PH'}>{countriesOptions[country]}</option>)}
            onSelectChange = {e => setNationality(e.target.value)}
        />
        <InputField readonly
            type        = "date"
            name        = "birth_date"
            label       = "Birthdate"
            value       = {moment(birthdate).format('YYYY-MM-DD')}
            style       = {{container: birthdate? 'valid': ''}}
            onChange    = {e => setBirthdate(e.target.value)}
        />
        <InputField readonly
            type    = "text"
            name    = "birth_place"
            label   = "Birthplace"
            placeholder = "Your Birthplace"
            value = {birthplace}
            style    = {{container: birthplace? 'valid': ''}}
            onChange = {e => setBirthplace(e.target.value)}
        />
        <InputField readonly
            type    = "email"
            name    = "email"
            label   = "Your Email"
            value   = {user?.email}
            style   = {{container: user?.email? 'valid': ''}}
        />
        <InputField
            type          = "mobile"
            name          = "mobile_number"
            label         = "Mobile Number"
            country       = {country || ""}
            placeholder   = "912 345 6789"
            value         = {formatMobile(number)}
            style         = {{container: number? 'valid': ''}}
            onChange      = {e => {
                const value = e.target.value.replaceAll(' ', '').trim();
                setMobileNumber(value)
            }}
            onSelectChange = {e => setMobilePrefix(countries?.find(c => c.code == e.target.value)?.mobile_code || '')}
        />
        {!isBusiness && !isClient && !isApi && <InputField
            type             = "textarea"
            name             = "address"
            label            = "Address (optional)"
            placeholder      = "Your current complete address"
            value            = {address}
            style            = {{container: `full-width ${address? 'valid': ''}`}}
            onTextAreaChange = {e => setAddress(e.target.value)}
        />}
    </Section>
})

export default PersonalInformation