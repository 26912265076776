import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";

import 'styles/sass/base/_calendar.scss'

const weeks = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

interface Props {
    className: string
    start: string
    end  : string
}

const Calendar = memo(({start, end, className}: Props) => {
    const [month, setMonth]             = useState(moment(start || undefined).month());
    const [year, setYear]               = useState(moment(start || undefined).year());
    const [selected, setSelected]       = useState(moment().format("YYYY-MM-DD"));
    const [days, setDays]               = useState<number[]>([]);
    const [startOfDays, setStartOfDays] = useState(0)
    const [endOfDays, setEndOfDays]     = useState(0)

    const prevMonth = () => {
        if (month == 0) {
            setMonth(11)
            setYear(year-1)
        } else {
            setMonth(month-1)
        }
    }

    const nextMonth = () => {
        if (month == 11) {
            setMonth(0)
            setYear(year+1)
        } else {
            setMonth(month+1)
        }
    }

    useEffect(() => setSelected(moment().set({year, month}).format("YYYY-MM-DD")), [month, year])

    useEffect(() => {
        let endOfMonth      = moment(selected).endOf('month')
        let lastDateOfMonth = endOfMonth.date()
        let lastDayOfMonth  = endOfMonth.day()

        var days = [...Array.from({length: lastDateOfMonth}, (_, i) => i + 1)]
        const dayOfFirstDate = moment(selected).startOf('month').day()
    
        var prevMonthLastDay = moment(selected).subtract(1, 'month').endOf('month').date()
        const previousMonthDays: number[] = []
        for (var i = prevMonthLastDay - dayOfFirstDate + 1; i <= prevMonthLastDay; i++) {
            previousMonthDays.push(i)
        }

        const nextMonthDays: number[] = []
        for (var i = 1; i<14-lastDayOfMonth; i++) {
            nextMonthDays.push(i)
        }
        setStartOfDays(previousMonthDays.length)
        setEndOfDays(previousMonthDays.length + days.length)
        setDays([...previousMonthDays, ...days, ...nextMonthDays])
    }, [selected])

    const isNotIncluded = (i) => i < startOfDays || i > endOfDays -1
    const isSelected   = (day, i) => {
        const currMonth = moment(selected).set('date', day)
        const prevMonth = moment(selected).subtract(1, 'month').set('date', day)
        const nextMonth = moment(selected).add(1, 'month').set('date', day)
        const startDate = moment(start).subtract(1, 'day')
        const endDate   = moment(end).add(1, 'day')
        
        return (
            (i < startOfDays  && prevMonth.isBetween(startDate, endDate)) ||
            (i > endOfDays -1 && nextMonth.isBetween(startDate, endDate)) ||
            (i >= startOfDays && i <= endOfDays-1 && currMonth.isBetween(startDate, endDate))
        )
    }
    const isStart = (day, i) => {
        const currMonth = moment(selected).set('date', day)
        const prevMonth = moment(selected).subtract(1, 'month').set('date', day)
        const nextMonth = moment(selected).add(1, 'month').set('date', day)

        return (
            (i < startOfDays  && prevMonth.isSame(moment(start))) ||
            (i > endOfDays -1 && nextMonth.isSame(moment(start))) ||
            (i >= startOfDays && i <= endOfDays-1 && currMonth.isSame(moment(start)))
        )
    }
    const isEnd = (day, i) => {
        const currMonth = moment(selected).set('date', day)
        const prevMonth = moment(selected).subtract(1, 'month').set('date', day)
        const nextMonth = moment(selected).add(1, 'month').set('date', day)
        
        return (
            (i < startOfDays  && prevMonth.isSame(moment(end))) ||
            (i > endOfDays -1 && nextMonth.isSame(moment(end))) ||
            (i >= startOfDays && i <= endOfDays-1 && currMonth.isSame(moment(end)))
        )
    }
    const getClassname = (day, i) => `
        day
        ${isNotIncluded(i)   ? 'not-included': ''}
        ${isSelected(day, i) ? 'selected'    : ''}
        ${isStart(day, i)    ? 'start'       : ''}
        ${isEnd(day, i)      ? 'end'         : ''}
    `

    return (
        <Grid container className={`container ${className}`} justifyContent="center" alignItems="center">
            <Grid item>
                <Grid container className="_calendar">
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center" className="month">
                            <span className="arrow left" onClick={prevMonth}/>
                            <Typography variant="h1">{moment(selected).format('MMMM YYYY')}</Typography>
                            <span className="arrow right" onClick={nextMonth}/>
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className="weekdays">
                            {weeks.map(week => <Grid item key={week}>{week}</Grid>)}
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className="days">
                            {days.map((day, i) => i < 7 && <Grid item className={getClassname(day, i)} key={i}>{day}</Grid>)}
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className="days">
                            {days.map((day, i) => i >= 7 && i < 14 && <Grid item className={getClassname(day, i)} key={i}>{day}</Grid>)}
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className="days">
                            {days.map((day, i) => i >= 14 && i < 21 && <Grid item className={getClassname(day, i)} key={i}>{day}</Grid>)}
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className="days">
                            {days.map((day, i) => i >= 21 && i < 28 && <Grid item className={getClassname(day, i)} key={i}>{day}</Grid>)}
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className="days">
                            {days.map((day, i) => i >= 28 && i < 35 && <Grid item className={getClassname(day, i)} key={i}>{day}</Grid>)}
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className="days">
                            {days.map((day, i) => i >= 35 && i < 42 && <Grid item className={getClassname(day, i)} key={i}>{day}</Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})

export default Calendar;