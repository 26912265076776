import { Grid, LinearProgress } from "@material-ui/core";
import React, { memo } from "react";
import { useStyles } from "./styles";

const Progress = memo(({step}: {step: string}) => {
    const classes = useStyles()
    const stepClassName = (stepName) => step == stepName? "active": ""

    const progressValue = {
        account_info      : 25,
        depository_account: 50,
        documents         : 75,
        review            : 100
    }

    return <div className={classes.progress}>
        <Grid container className={classes.progressContainer}>
            <Grid item xs={3} className={stepClassName("account_info")}>
                Account<br/>Information
            </Grid>
            <Grid item xs={3} className={stepClassName("depository_account")}>
                Depository<br/>Account
            </Grid>
            <Grid item xs={3} className={stepClassName("documents")}>
                Identity<br/>Verification
            </Grid>
            <Grid item xs={3} className={stepClassName("review")}>
                Review<br/>Information
            </Grid>
        </Grid>
        <LinearProgress variant="determinate" value={progressValue[step]}/>
    </div>
})

export  default Progress