/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { useTranslation } from 'react-i18next';
import { Routes } from 'app/routes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add( fab, far, fas );

export function App() {
    const { i18n } = useTranslation();

    React.useEffect(() => {
        (async () => {
            while (!document.getElementById('fb-root')) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }

            if (document.getElementById('fb-root')) {
                let chatbox = document.getElementById('fb-customer-chat');

                if (chatbox) {
                    chatbox.setAttribute('page_id', process.env.REACT_APP_FACEBOOK_CHAT_ID || '');
                    chatbox.setAttribute('attribution', 'biz_inbox');
                    chatbox.setAttribute('greeting_dialog_display', 'hide');
                    chatbox.setAttribute('theme_color', '#009cde');
                }

                window.fbAsyncInit = function() {
                    FB.init({
                        xfbml   : true,
                        version : 'v16.0',
                    });
                };

                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];

                    if (d.getElementById(id)) return;

                    js      = d.createElement(s);
                    js.id   = id;
                    js.src  = 'https://connect.facebook.com/en_US/sdk/xfbml.customerchat.js';

                    if (fjs.parentNode) {
                        fjs.parentNode.insertBefore(js, fjs);
                    }
                }(document, 'script', 'facebook-jssdk'));
            }
        })();
    }, []);

    return (
        <BrowserRouter>
            <Helmet
                defaultTitle={ process.env.REACT_APP_NAME }
                htmlAttributes={{ lang: i18n.language }}
            >
                <meta charSet="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <meta name="theme-color" content="#f8f9fb" />
                <meta property="og:title" content={ process.env.REACT_APP_NAME } />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="/" />
                <meta property="og:image" content="/" />
            </Helmet>
            <Routes />
            <GlobalStyle />
            <div id="fb-root"></div>
            <div id="fb-customer-chat" className="fb-customerchat" ></div>
        </BrowserRouter>
    );
}
