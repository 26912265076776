import InputField from "app/components/InputField";
import imageCompression from "browser-image-compression";
import { checkDimension } from "utils";
import React, { memo, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from "@material-ui/core";
import SubCategory from "./SubCategory";
import { CATEGORY_SUBCATEGORIES } from "app/common/core_api/resources";
import { useSelector } from "react-redux";
import { selectAccessToken, selectUserInfo } from "app/common/auth/selectors";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import { ExpandMore } from "@material-ui/icons";

interface Props {
    id?         : string
    slug?       : string
    icon_url?   : string
    name?       : string
    description?: string
    checked     : boolean
    onChange?   : (any) => void
}

const Category = memo(({id, slug, icon_url, name, description, checked, onChange}: Props) => {
    const username     = useSelector(selectUserInfo)?.username
    const access_token = useSelector(selectAccessToken)
    const api_version  = useSelector(selectUserDashboard).api_version

    const [_name, setName]               = useState(name || "");
    const [_description, setDescription] = useState(description || "");
    const [_icon_url, setIconUrl]        = useState(icon_url || "");
    const [_checked, setChecked]         = useState(checked);
    const [largeError, setLargeError]    = useState(false);
    const [smallError, setSmallError]    = useState(false);

    const [billers, setBillers] = useState<any[]>();

    useEffect(() => {
        if (onChange) {
            onChange({
                id,
                name       : _name,
                description: _description,
                icon_url   : _icon_url,
                checked    : _checked
            })
        }
    }, [
        _name,
        _description,
        _icon_url,
        _checked
    ])

    const processImage = async (file) => {
        if (!file) {
            return;
        }
        if (file.size > 5000000) {
            setLargeError(true);
            return;
        }
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 5,
            useWebWorker: true
        });
        const imageUri    = await imageCompression.getDataUrlFromFile(compressedFile)
        const isValidSize = await checkDimension(imageUri)
        setSmallError(!isValidSize)
        setLargeError(false);
        setIconUrl(imageUri)
    }

    const loadBillers = (e, expanded) => {
        if (expanded && username && (slug || id) && access_token && !billers) {
            fetch(
                CATEGORY_SUBCATEGORIES
                .replace(":username", username)
                .replace(":category", id || ''),
                {headers: {'Authorization': `Bearer ${access_token}`}}
            )
            .then(resp => resp.json())
            .then(json =>
                Array.isArray(json)?
                setBillers(json):
                setBillers([])
            )
        }
    }
    
    return <Accordion className="category" TransitionProps={{ unmountOnExit: true }} onChange={loadBillers}>
        <AccordionSummary className="category-row" expandIcon={<ExpandMore />}>
            <section className="checkbox-container">
                {api_version == "1" && <InputField
                    name     = {`${slug || id}_checkbox`}
                    type     = "checkbox"
                    checked  = {_checked}
                    onChange = {() => setChecked(!_checked)}
                />}
            </section>
            <div className="category-image" onClick = {(event) => event.stopPropagation()}>
                <InputField
                    name          = {`${slug || id}_image`}
                    type          = "file"
                    accept        = 'image/jpeg, image/jpg, image/png'
                    style         = {{container: 'image-upload'}}
                    value         = {_icon_url}
                    error         = {largeError || smallError}
                    onChange      = {e => e.target.files && processImage(e.target.files[0])}
                    error_message = {
                        largeError? "Image file is too large": 
                        smallError? "Image size is too small": 
                        ""
                    }
                />
            </div>
            <div className="category-fields-container" onClick = {(event) => event.stopPropagation()}>
                <InputField
                    type     = "text"
                    style    = {{container: 'text-field'}}
                    value    = {_name}
                    onChange = {e => setName(e.target.value)}
                />
                <InputField
                    type     = "text"
                    style    = {{container: 'text-field'}}
                    value    = {_description}
                    onChange = {e => setDescription(e.target.value)}
                />
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <div className="category-details">
                {
                    billers !== undefined?
                    billers.map(biller => <SubCategory {...biller}/>):
                    <CircularProgress/>
                }
            </div>
        </AccordionDetails>
    </Accordion>
})

export default Category