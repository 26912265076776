import React, { memo, ReactNode } from "react";

import success from 'assets/img/success.svg';
import error from 'assets/img/error.svg';
import alert from 'assets/img/alert.svg';
import warning from 'assets/img/warning.svg';

interface Props {
    children?: ReactNode
    style?   : string
    status   : string
    action?  : ReactNode
}

const Progress = memo(({children, style, status, action}: Props) => {
    return (
        <section className={`achievement ${style ? style : '' } ${status}`}>
            <section className="content">{
                status === 'complete'   ? <img src={success} alt={`${status} icon`}/>:
                status === 'incomplete' ? <img src={error}   alt={`${status} icon`}/>:
                status === 'info'       ? <img src={alert}   alt={`${status} icon`}/>:
                status === 'warning'    ? <img src={warning} alt={`${status} icon`}/>:
                status === 'blank'      ? <div className="icon"></div>:
                ''
            }
                <section>{children}</section>
            </section>
            {action && <section className="action">{action}</section>}
        </section>
    )
})

export default Progress;