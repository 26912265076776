import { selectLoginStatus } from "app/common/auth/selectors";
import { GeneralComponent } from "app/common/slice";
import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import InfoModal from "app/components/Modal/InfoModal";
import bg from 'assets/img/background/justpayto-philippines-payments-2.jpg';
import logo from 'assets/img/landing-page/jpt-logo.svg';
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import 'styles/sass/login.scss';
import { useQuery } from "utils/useQuery";
import MasterPage from "../MasterPage";
import { selectUserDashboard } from "../UserDashboardPage/selectors";
import { userDashboardPageActions } from "../UserDashboardPage/slice";
import useAuth from "../UserDashboardPage/useAuth";
import ForceResetPassword from "./ForceResetPassword";

const LoginPage = memo(() => {
    const dispatch      = useDispatch();
    const auth          = useAuth();
    const loginStatus   = useSelector(selectLoginStatus);
    const userDashboard = useSelector(selectUserDashboard);
    const query         = useQuery()

    const [email, setEmail]           = useState('');
    const [password, setPassword]     = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loggingIn, setLoggingIn]   = useState(false);
    const [loggedOut, setLoggedOut]   = useState(false);

    const [resetmodal, setResetModal] = useState(false);

    const [emptyUsername, setEmptyUsername] = useState(false);
    const [emptyPassword, setEmptyPassword] = useState(false);

    const login = () => {
        if (email && password) {
            setLoggingIn(true)
            dispatch(GeneralComponent.login({email, password, rememberMe}))
        }
    }

    useEffect(() => {
        const token = query.get('login_token')
        if (token) {
            dispatch(GeneralComponent.tokenLogin(token))
        }
    }, [query])

    // Login Failed
    useEffect(() => {
        if (loggingIn === true && loginStatus.loginError) {
            setLoggingIn(false);
        }
        if (loginStatus.loginError == "User needs to reset password") {
            setResetModal(true)
        }
    }, [loginStatus.loginError])

    useEffect(() => {
        if (userDashboard.lastActionTime) {
            setLoggedOut(true)
            dispatch(userDashboardPageActions.removeActionTime())
        }
    }, [userDashboard.lastActionTime])

    return (
        auth?.access_token?
        <Redirect to="/user-dashboard"/>:
        auth?.otp_key?
        <Redirect to="/login/2FA"/>:
        <MasterPage hasHeader
            title = "Log In"
            backgroundImage = {bg}
        >
            <ForceResetPassword state={[resetmodal, setResetModal]} />
            <InfoModal
                state   = {[loggedOut, setLoggedOut]}
                className = "customizations-success-modal larger_modal"
                title   = "Log In"
                message = "You must Log In to view this page"
            />
            <section className="action-box-wrapper">
                <section className="login action-box content-box small">
                    <img className="logo" src={logo} alt={process.env.REACT_APP_NAME}/>
                    <div jpt-form="login" className="pseudo-form">
                        <InputField
                            error       = {!!loginStatus.loginError || emptyUsername}
                            value       = {email}
                            type        = "text"
                            name        = "username"
                            placeholder = "Your Username or Email"
                            onChange    = {e => {
                                setEmail(e.target.value)
                                setEmptyUsername(!e.target.value)
                            }}
                            style       = {{inputContainer: 'icon icon-user ignore'}}
                        />
                        <InputField
                            showPassword = {false}
                            error        = {!!loginStatus.loginError || emptyPassword}
                            value        = {password}
                            type         = "password"
                            name         = "password"
                            placeholder  = "Your Password"
                            onChange     = {e => {
                                setPassword(e.target.value)
                                setEmptyPassword(!e.target.value)
                            }}
                            style       = {{inputContainer: 'icon icon-password ignore'}}
                        />
                        <section className="input-group">
                            <InputField
                                type     = "checkbox"
                                name     = "remember_me"
                                title    = "Remember Me"
                                checked  = {rememberMe}
                                onChange = {e => setRememberMe(e.target.checked)}
                            />
                            <section className="input-box">
                                <section className="input-prepend">
                                    <section className="forgot-password link">
                                    <a href="/forgot-password">Forgot Password?</a>
                                    </section>
                                </section>
                            </section>
                        </section>
                        {
                            loginStatus.loginError &&
                            <div className="error-container" data-error-container>
                                {loginStatus.loginError}
                            </div>
                        }
                        <section className="footer">
                            <ButtonLoader hasLoader
                                loading = {loggingIn}
                                name    = 'login'
                                style   = 'filled blue'
                                text    = 'Log In'
                                onClick = {login}
                            />
                        </section>
                    </div>
                    <section className="divider"><span>OR</span></section>
                    <ButtonLoader hasLoader
                        name       = 'sign-up'
                        style      = 'filled'
                        text       = 'Sign Up'
                        route      = '/early-access'
                    />
                </section>
            </section>
        </MasterPage>
    )
})

export default LoginPage;