import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from 'app/common/slice';

const selectDomain = (state: RootState) => state.general || initialState;

export const selectLoginStatus = createSelector(
    [selectDomain],
    GeneralState => {
        return {
            otpError  : GeneralState.otpError,
            loginError: GeneralState.loginError,
            gettingOtp: GeneralState.gettingOtp
        }
    },
);


export const selectLoadingUser = createSelector(
    [selectDomain],
    GeneralState => GeneralState.loading,
);

export const selectUser = createSelector(
    [selectDomain],
    GeneralState => {
        let user = GeneralState.user,
            userInfo = user?.profile?.user_info,
            userBank = user?.profile?.user_bank;

        Object.keys(userInfo || {}).map((key) => {
            // Search for `Array`
            if (Array.isArray(userInfo[key])) {
                // Merge current and default `Array` values
                if (Array.isArray(userInfo[key][0])) {
                    userInfo[key] = [...userInfo[key][1], ...userInfo[key][0]];
                }

                // Merge current and default `Object` values
                if (typeof userInfo[key][0] === 'object') {
                    userInfo[key] = {...userInfo[key][1], ...userInfo[key][0]};
                }
            }
            return null;
        });

        Object.keys(userBank || {}).map((key) => {
            // Search for `Array`
            if (Array.isArray(userBank[key])) {
                // Merge current and default `Array` values
                if (Array.isArray(userBank[key][0])) {
                    userBank[key] = [...userBank[key][0], ...userBank[key][1]];
                }

                // Merge current and default `Object` values
                if (typeof userBank[key][0] === 'object') {
                    userBank[key] = {...userBank[key][0], ...userBank[key][1]};
                }
            }
            return null;
        });

        return GeneralState;
    },
);

export const selectUserInfo = createSelector(
    [selectDomain],
    GeneralState => GeneralState.user_info
)

export const selectAuth = createSelector(
    [selectDomain],
    GeneralState => GeneralState.auth,
);

export const selectAccessToken = createSelector(
    [selectDomain],
    GeneralState => GeneralState.auth?.access_token,
);

export const selectLogInTime = createSelector(
    [selectDomain],
    GeneralState => GeneralState.logInTime,
);
