import React from 'react';
import { numberFormat } from 'utils';

const useTransactionHeader = (transaction) => {
    const amount_details = transaction?.amount_details;

    const formatCurrency = (amount) => (
        amount_details?.currency == 'PHP'?
        `₱ ${numberFormat(amount)}`:
        `${numberFormat(amount)} ${amount_details?.currency}`
    )

    const net = formatCurrency(+(transaction?.payout_amount || 0))
    const depository = (
        transaction?.receiver_details?.bank_details?.name ||
        transaction?.receiver_details?.bank_details?.bank
    )

    const headers = {
        'complete': {
            status : 'Transaction Completed',
            color  : 'green',
            details: `Successful deposit of ${net} to your ${depository} account`
        },
        'invalid': {
            status : 'Transaction Failed',
            color  : 'red',
            details: `Sender failed to complete this transaction.`
        },
        'expired': {
            status : 'Transaction Failed',
            color  : 'red',
            details: `Sender failed to complete this transaction.`
        },
        'reject': {
            status : 'Transaction Failed',
            color  : 'red',
            details: `Sender failed to complete this transaction.`
        },
        'verified': {
            status : 'For Settlement',
            color  : 'blue',
            details: <>You will be receiving <span style={{display: "inline-block"}}>{net}</span> to your {depository} account</>
        },
        'pending_payment': {
            status : 'Transaction Pending',
            color  : 'yellow',
            details: `Waiting for sender to complete payment`
        },
        'review': {
            status : 'Transaction Pending',
            color  : 'yellow',
            details: `Waiting for sender to complete payment`
        },
        'hold': {
            status : 'Transaction on Hold',
            color  : 'yellow',
            details: `Transaction under review`
        },
        'default' : {
            status : '',
            color  : '',
            details: ''
        },
    }
    let status = transaction?.is_onhold? 'hold': transaction?.status || 'default';
    return headers[status] || headers['default']
}

export default useTransactionHeader;