import profile_img  from 'assets/img/profile_photo.jpg';
import background_img from 'assets/img/background.jpg';

import InputField from "app/components/InputField";

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSignUpState } from "../../selectors";
import { signUpActions } from '../../slice';
import imageCompression from 'browser-image-compression';
import ImageEditor from 'app/components/Modal/ImageEditor';
import { useStyles } from '../styles';

interface Props {
    forReview?: boolean
    validated?: boolean
}
const PaymentPage = memo(({forReview, validated}: Props) => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const signUp   = useSelector(selectSignUpState);

    const emptyError = 'This is required field';
    const sizeError  = 'Image size is too small';
    const largeFileError = 'Image size is too large';

    const [imageEditorModal, setImageEditorModal] = useState(false);

    const [emptyProfilePhoto, setEmptyProfilePhoto] = useState(false);
    const [emptyBackgroundUrl] = useState(false);

    const [errorProfilePhoto, setErrorProfilePhoto] = useState(false);
    const [errorBackgroundUrl, setErrorBackgroundUrl] = useState(false);

    const [errorLargeProfilePhoto, setErrorLargeProfilePhoto] = useState(false);
    const [errorLargeBackgroundUrl, setErrorLargeBackgroundUrl] = useState(false);

    const [description, setDescription]      = useState(signUp.user_info?.description || '');
    const [profile_photo, setProfilePhoto]   = useState(signUp.user_info?.profile_photo || '');
    const [background_url, setBackgroundUrl] = useState(signUp.user_info?.background_url || '');

    const [imageEdit, setImageEdit] = useState("");

    const checkDimension = img => new Promise((resolve, _) => {
        const image  = new Image();
        image.onload = () => resolve(image.width >= 100 && image.height >=100 ? img : false)
        image.src    = img;
    })

    const setImage = async (stateSetter, errorSetter, largeErrorSetter, file) => {
        if (!file) {
            stateSetter(undefined)
            errorSetter(false)
            largeErrorSetter(false)
            return;
        }
        stateSetter('');
        if (file.size > 5000000) {
            largeErrorSetter(true);
            return;
        }
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 5,
            useWebWorker: true
        });
        const imageUri    = await imageCompression.getDataUrlFromFile(compressedFile)
        const isValidSize = await checkDimension(imageUri)
        stateSetter(isValidSize ? imageUri: undefined)
        errorSetter(!isValidSize)
        largeErrorSetter(false);
    }

    const updatePaymentPage = () => {
        dispatch(signUpActions.setPaymentPage({description, profile_photo, background_url}))
    }

    useEffect(() => updatePaymentPage(), [])
    useEffect(() => updatePaymentPage(), [description, profile_photo, background_url])

    useEffect(() => {
        if(validated) {
            setEmptyProfilePhoto(!profile_photo)
            setErrorProfilePhoto(false)
        }
    }, [validated])

    const editImage = async (image) => {
        setImageEdit('')
        if (!image) {
            return;
        }
        if (image.size > 5000000) {
            setErrorLargeProfilePhoto(true);
            return;
        }
        const compressedFile = await imageCompression(image, {
            maxSizeMB: 5,
            useWebWorker: true
        });
        const imageUri    = await imageCompression.getDataUrlFromFile(compressedFile)
        const isValidSize = await checkDimension(imageUri)
        setImageEdit(isValidSize ? imageUri: "")
        setErrorProfilePhoto(!isValidSize)
        setErrorLargeProfilePhoto(false);
        setEmptyProfilePhoto(false);
        if (isValidSize) {
            setImageEditorModal(true);
        }
    }

    return <>
        <ImageEditor state={[imageEditorModal, setImageEditorModal]} image={imageEdit}
            onSave={image => {
                setProfilePhoto(image)
                setImageEditorModal(false)
            }}
            onClose={() => {
                setImageEdit('');
            }}
        />
        <section className={classes.divider}><span>PAYMENT PAGE</span></section>
        <section className="input-group" data-payment-page>
            <InputField
                type        = 'textarea'
                name        = 'description'
                label       = {<>Short Description (Optional)<span className="right" data-char-count>{description.length || 0}/140</span></>}
                placeholder = 'Welcome to my payment page'
                style       = {{container: `full-width ${description? 'valid': ''} ${classes.inputField}`}}
                tooltip     = {
                    <div className="body">
                        <p>Your short writeup goes here</p>
                    </div>
                }
                max = {140}
                value = {description}
                onTextAreaChange = {e => {
                    setDescription(e.target.value);
                }}
            />
            <InputField
                type        = 'file'
                name        = 'profile_photo'
                label       = {signUp.user_info?.account_type?.type == "individual" ? 'Profile Photo' : 'Business Logo'}
                instruction = 'At least 100 x 100 pixels, max 5MB'
                accept      = 'image/jpeg, image/jpg, image/png'
                value       = {profile_photo}
                error       = {errorLargeProfilePhoto || emptyProfilePhoto || errorProfilePhoto}
                style       = {{container: `${profile_photo? 'valid': ''} ${classes.inputField}`}}
                error_message = {
                    errorLargeProfilePhoto ? largeFileError:
                    errorProfilePhoto ? sizeError:
                    emptyProfilePhoto ? emptyError: ''
                }
                tooltip     = {<>
                    <img src={profile_img} alt="profile_photo"/>
                    <div className="body">
                        <h5>Example:</h5>
                    </div>
                </>}
                onChange = {e => e.target.files && editImage(e.target.files[0])}
            />
            <InputField
                type        = 'file'
                name        = 'background_image'
                label       = 'Background Image (Optional)'
                instruction = 'At least 800 x 600 pixels, max 5MB'
                accept      = 'image/jpeg, image/jpg, image/png'
                value       = {background_url}
                error       = {errorLargeBackgroundUrl || emptyBackgroundUrl || errorBackgroundUrl}
                style       = {{container: `${background_url? 'valid': ''} ${classes.inputField}`}}
                error_message = {
                    errorLargeBackgroundUrl ? largeFileError:
                    emptyBackgroundUrl ? emptyError:
                    errorBackgroundUrl ? sizeError: ''
                }
                tooltip     = {<>
                    <img src={background_img} alt="background"/>
                    <div className="body">
                        <h5>Example:</h5>
                    </div>
                </>}
                onChange = {e => e.target.files && setImage(setBackgroundUrl, setErrorBackgroundUrl, setErrorLargeBackgroundUrl, e.target.files[0])}
            />
        </section>
    </>
})

export default PaymentPage;