import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

export interface ForgotPasswordPageState {
    error?: string
    result?: boolean
}

export const initialState: ForgotPasswordPageState = {}

const forgotPasswordPageSlice = createSlice({
    name: 'forgotPasswordPage',
    initialState,
    reducers: {
        submitRequest(state, action: PayloadAction<{
            email: string,
            ip: String
        }>) {},
        requestResult(state, action: PayloadAction<{
            error: string,
            result: boolean 
        }>) {
            state.error  = action.payload.error;
            state.result = action.payload.result;
        },
        clearError(state) {
            state.result = undefined
            state.error  = undefined
        }
    },
});

export const {
    actions: ForgotPasswordPageActions,
    reducer,
    name: sliceKey,
} = forgotPasswordPageSlice;
