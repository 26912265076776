import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import MasterPage from "./MasterPage";
import { selectSignUpState } from "./Onboarding/selectors";

import success from 'assets/img/success.svg';
import 'styles/sass/signup.scss';

const SuccessSignup = memo(() => {
    const payment_page = process.env.REACT_APP_USE_KEYCLOAK == 'True'? '': process.env.REACT_APP_TEMPORARY_PAYMENT_PAGE_URL
    const signup = useSelector(selectSignUpState);
    const skipped_steps = (
        signup.depositoryStatus == 'incomplete'&&
        signup.documentStatus   == 'incomplete'?
        'Depository Account and Identity Verification':
        signup.depositoryStatus == 'incomplete'? 'Depository Account':
        signup.documentStatus   == 'incomplete'? 'Identity Verification': ''
    )
    window.onbeforeunload = null

    useEffect(() => {
        const link = document.getElementById("payment-page-link")
        if (link && signup.username) {
            const minusSize = (signup.username.length/5)-1
            const newSize   = 33 - (minusSize*5)
            link.style.fontSize = `${newSize}px`
        }
    }, [signup.username])

    return (
        <MasterPage title={`Welcome to JustPayto!`} hasHeader backgroundImage={signup.user_info?.background_url}>
            <section className="action-box-wrapper">
                <section className="action-box content-box small success success-signup">
                    <h1 className="center">Success</h1>
                    <img className="logo" src={success} alt="Success Icon"/>
                    <p className="center">{
                        signup.documentStatus == 'incomplete' ||
                        signup.depositoryStatus == 'incomplete' ?
                        "Some requirements are pending. ":
                        "Your application is now under review. "
                    }Start receiving money with your Payment Address very soon!</p>
                    <h1 className="center">
                        <a className="nowrap has-text-weight-normal" id="payment-page-link" href={`${payment_page}/${signup.username}`} style={{textAlign: "center", whiteSpace: "nowrap"}}>
                            justpay.to/{signup.username?.toLowerCase()}
                        </a>
                    </h1>
                    <section className="divider"></section>
                    <p className={!skipped_steps? `nowrap`: ''}>{
                        skipped_steps?
                        <>
                            You have skipped {skipped_steps} Details. Your Payment Address will expire in 30 days unless you complete this step.
                        </>:
                        <>
                            Invite your Friends! Share your referral link.
                            <br/>
                            <a className="has-text-weight-normal" href={`/invites/${signup.username}`}>
                                justpay.to/invites/{signup.username?.toLowerCase()}
                            </a>
                        </>
                    }</p>
                </section>
            </section>
        </MasterPage>
    )
})

export default SuccessSignup;