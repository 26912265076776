import { CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core'
import { selectUserInfo } from 'app/common/auth/selectors'
import ButtonLoader from 'app/components/ButtonLoader'
import FilterButton from 'app/components/FilterButton'
import FilterDate from 'app/components/FilterDate'
import { selectUserDashboard } from 'app/containers/UserDashboardPage/selectors'
import { userDashboardPageActions } from 'app/containers/UserDashboardPage/slice'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numberFormat, roundOff } from 'utils'
import LineGraph from './LineGraph'

interface Props {
    component?: boolean
}

const BarChart = memo(({component}: Props) => {
    const user             = useSelector(selectUserInfo);
    const userDashboard    = useSelector(selectUserDashboard)
    const dispatch         = useDispatch()

    const [lineData, setLineData]         = useState<any[]>();
    const [all, setAll]                   = useState(false);
    const [last7days, setLast7days]       = useState(true);
    const [last20days, setLast20days]     = useState(false);
    const [fromDate, setFromDate]         = useState('');
    const [toDate, setToDate]             = useState('');
    const [grossSales, setGrossSales]     = useState(0);
    const [sales, setSales]               = useState(0);
    const [isPremium, setIsPremium]       = useState(false);
    const [loading, setLoading]           = useState(true);

    useEffect(() => {
        setGrossSales(
            userDashboard.barchartTransactions?.reduce(
                (sum, t) => sum + t.amount_details?.gross, 0
            ) || 0
        )
        setSales(userDashboard.barchartTransactions?.length || 0)
    }, [userDashboard.barchartTransactions])

    const resetFilter = () => {
        setAll(false);
        setLast7days(false);
        setLast20days(false);
        setFromDate('');
        setToDate('');
    }

    const setFilter = (set, value) => {
        resetFilter();
        set(value);
    }

    const setRangeFilter = (set, value) => {
        const from = fromDate;
        const to   = toDate;
        setFromDate(from);
        setToDate(to);
        set(value);
    }

    const isCreditCard = transaction => (
        transaction.source_of_funds?.payment_category == "mastercard_visa" ||
        transaction.source_of_funds?.payment_category == "credit_card" ||
        transaction.source_of_funds?.payment_category == "debit_card"
    )
    const isBankTransfer   = transaction => transaction.source_of_funds?.payment_category == 'bank_fund_transfer'
    const isOnlineBanking  = transaction => transaction.source_of_funds?.payment_category == 'online_banking'
    const isEWalletCrypto  = transaction => transaction.source_of_funds?.payment_category == 'e_wallet'
    const isOverTheCounter = transaction => transaction.source_of_funds?.payment_category == 'over_the_counter'
    const isOthers         = transaction => ![
        'mastercard_visa',
        'credit_card',
        'debit_card',
        'bank_fund_transfer',
        'online_banking',
        'e_wallet',
        'over_the_counter'
    ].includes(transaction.source_of_funds?.payment_category)

    const filter         = condition => userDashboard.barchartTransactions.filter(transaction => condition(transaction)) || []
    const total          = transactions => transactions?.reduce((sum, transactions) => sum + +transactions.payout_amount, 0) || 0
    const totalFormatted = condition => numberFormat(total(filter(condition)))
    const percentage     = condition => roundOff(100*(
        userDashboard.barchartTransactions &&
        userDashboard.barchartTransactions?.length > 0? 
        total(filter(condition))/(total(userDashboard.barchartTransactions || []) || 1): 0
    ))

    const amountFormatter = value => `₱ ${numberFormat(value)}`
    const randomData = mainKey => {
        const data: any[] = []
        for (var i=0; i<8; i++) {
            const row = {}
            row[mainKey] = roundOff(Math.random() * 100)
            data.push(row)
        }
        return data
    }

    useEffect(() => {
        let now = moment(),
            date1    : any = undefined,
            date2    : any = undefined;
        if (last7days) {
            date2 = now.format('YYYY-MM-DD');
            date1 = now.subtract(7, 'days').format('YYYY-MM-DD');
        }  else if (last20days) {
            date2 = now.format('YYYY-MM-DD');
            date1 = now.subtract(20, 'days').format('YYYY-MM-DD');
        }
        if (all || last7days || last20days) {
            dispatch(userDashboardPageActions.getBarchartTransactions({date1, date2}));
            setLoading(true);
        }
    }, [all, last7days, last20days])

    useEffect(() => {
        if (fromDate && toDate) {
            setAll(false);
            setLast7days(false);
            setLast20days(false);
            dispatch(userDashboardPageActions.getBarchartTransactions({
                date1: fromDate,
                date2: toDate
            }));
            setLoading(true);
        }
    }, [fromDate, toDate])

    useEffect(() => {
        dispatch(userDashboardPageActions.updateActionTime())

        if (!isPremium)
            setLineData(randomData('Amount'));
        else {
            let start: moment.Moment,
                end  : moment.Moment,
                transactions = userDashboard.barchartTransactions || [];
            if (fromDate && toDate) {
                start = moment(fromDate);
                end   = moment(toDate);
            } else {
                end   = moment();
                start = moment(
                    all? transactions?.at(-1)?.updated_at:
                    last7days? moment().subtract(7, 'days'):
                    last20days? moment().subtract(20, 'days'):
                    undefined
                );
            }
            let data: any[] = []
            while (start.isSameOrBefore(end, 'date')) {
                data.push({
                    date: start.format('YYYY-MM-DD'),
                    Amount: 0
                });
                start = start.add(1, 'd');
            }
            setLineData(
                transactions.reduce((data, transaction) => {
                    var index  = data.findIndex(d => {
                        let date = moment(d.date),
                            updated_at = moment(transaction.updated_at);

                        return date.isSame(updated_at, 'date');
                    });
                    if (![null, undefined, -1].includes(index)) {
                        data[index].Amount += (+transaction.payout_amount);
                    }
                    return data
                }, data)
            )
            setLoading(false);
        }
    },[userDashboard.barchartTransactions])

    useEffect(() => setIsPremium(!!(
        user?.groups?.includes('Premium') ||
        user?.groups?.includes('Partners')
    )), [user])

    const random = () => roundOff(Math.random() * 100)
    
    const openChangePlanModal = () => dispatch(userDashboardPageActions.setChangePlan())

    return (
        loading?
        <Grid container justifyContent='center'>
            <CircularProgress />
        </Grid>:
        <Grid container spacing={3} className="container-grid bar-chart">
            <Grid container className="standard-grid">
                <Grid item xs={2}>
                    <Typography variant="h5">Gross Sale</Typography><br />     <Typography variant="h3">₱{ numberFormat(grossSales) }</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h5">Transactions</Typography><br />           <Typography variant="h3">{ sales }</Typography>
                </Grid>
                <Grid item xs={1} style={{whiteSpace: 'nowrap'}}>
                    <Typography variant="h5">Average Sales</Typography><br />   <Typography variant="h3">₱{ numberFormat(sales? grossSales/sales: 0) }</Typography>
                </Grid>
                <Grid item xs={7} style={{paddingLeft: '2.5rem'}}>
                    <div className="dataTables_filter">
                        <section className="date-filter-1">
                            <FilterButton status={all}         set={setAll}        setFilter={setFilter} text="All"/>
                            <FilterButton status={last7days}   set={setLast7days}  setFilter={setFilter} text="Last 7 days"/>
                            <FilterButton status={last20days}  set={setLast20days} setFilter={setFilter} text="Last 20 days"/>
                        </section>
                        <span>OR</span>
                        <section className="date-filter-2">
                            <FilterDate direction="from" placeholder="From" value={fromDate} setRangeFilter={setRangeFilter} set={setFromDate}/>
                            <FilterDate direction="to"   placeholder="To"   value={toDate}   setRangeFilter={setRangeFilter} set={setToDate}/>
                        </section>
                    </div>
                </Grid>
            </Grid>
            {!component && 
                <LineGraph
                    title     = "Gross Sale"
                    mainKey   = "Amount"
                    data      = {lineData}
                    formatter = {amountFormatter}
                    frequency = {'daily'}
                />
            }
            <Grid container className="standard-grid" style={{position: "relative"}}>
                <Typography variant="h5">Sales by Payment Type</Typography>
                <Grid className={isPremium? '': 'blur'} item xs={12} container>
                    <Grid item xs={12} className="bar-graph credit-debit-card">
                        <Grid container style={{height: 'fit-content'}}>
                            <Grid item xs={9}><Typography variant="h6" color="textSecondary">Credit/Debit Card</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{isPremium? percentage(isCreditCard): random()}%</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {isPremium? totalFormatted(isCreditCard): random()}</Typography></Grid>
                        </Grid>
                        <LinearProgress variant="determinate" value={isPremium? percentage(isCreditCard): random()}/>
                    </Grid>
                    <Grid item xs={12} className="bar-graph bank-transfer">
                        <Grid container>
                            <Grid item xs={9}><Typography variant="h6" color="textSecondary">Bank Transfer</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{isPremium? percentage(isBankTransfer): random()}%</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {isPremium? totalFormatted(isBankTransfer): random()}</Typography></Grid>
                        </Grid>
                        <LinearProgress variant="determinate" value={isPremium? percentage(isBankTransfer): random()}/>
                    </Grid>
                    <Grid item xs={12} className="bar-graph online-banking">
                        <Grid container>
                            <Grid item xs={9}><Typography variant="h6" color="textSecondary">Online Banking</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{isPremium? percentage(isOnlineBanking): random()}%</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {isPremium? totalFormatted(isOnlineBanking): random()}</Typography></Grid>
                        </Grid>
                        <LinearProgress variant="determinate" value={isPremium? percentage(isOnlineBanking): random()}/>
                    </Grid>
                    <Grid item xs={12} className="bar-graph e-wallet-crypto">
                        <Grid container>
                            <Grid item xs={9}><Typography variant="h6" color="textSecondary">E-Wallet/Crypto</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{isPremium? percentage(isEWalletCrypto): random()}%</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {isPremium? totalFormatted(isEWalletCrypto): random()}</Typography></Grid>
                        </Grid>
                        <LinearProgress variant="determinate" value={isPremium? percentage(isEWalletCrypto): random()}/>
                    </Grid>
                    <Grid item xs={12} className="bar-graph over-the-counter">
                        <Grid container>
                            <Grid item xs={9}><Typography variant="h6" color="textSecondary">Over-the-Counter</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{isPremium? percentage(isOverTheCounter): random()}%</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {isPremium? totalFormatted(isOverTheCounter): random()}</Typography></Grid>
                        </Grid>
                        <LinearProgress variant="determinate" value={isPremium? percentage(isOverTheCounter): random()}/>
                    </Grid>
                    <Grid item xs={12} className="bar-graph others">
                        <Grid container>
                            <Grid item xs={9}><Typography variant="h6" color="textSecondary">Others</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{isPremium? percentage(isOthers): random()}%</Typography></Grid>
                            <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {isPremium? totalFormatted(isOthers): random()}</Typography></Grid>
                        </Grid>
                        <LinearProgress variant="determinate" value={isPremium? percentage(isOthers): random()}/>
                    </Grid>
                </Grid>
                {!isPremium && <ButtonLoader
                    style   = "filled round blue upgrade-button"
                    text    = "Upgrade"
                    onClick = {openChangePlanModal}
                />}
            </Grid>
        </Grid>
    )
})

export default BarChart