import { selectCountries } from "app/common/selectors";
import DropdownField from "app/components/DropdownField";
import InputField from "app/components/InputField";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatMobile } from "utils";
import { business_categories, business_types } from "utils/options";
import { selectIP, selectSignUpState } from "../../selectors";
import { signUpActions } from "../../slice";
import { useStyles } from "../styles";

interface Props {
    forReview?: boolean
    validated?: boolean
}
const BasicInformation = memo(({forReview, validated}: Props) => {
    const classes     = useStyles();
    const dispatch    = useDispatch();
    const ip_info     = useSelector(selectIP);
    const signUp      = useSelector(selectSignUpState);
    const countries   = useSelector(selectCountries);
    const accountType = signUp.user_info?.account_type?.type;

    const required_field = "This field is required";
    const invalid_format = "Invalid Format";
    const [errorBusinessType    , setErrorBusinessType]     = useState(false);
    const [errorBusinessCategory, setErrorBusinessCategory] = useState(false);
    const [errorBusinessName    , setErrorBusinessName]     = useState(false);
    const [errorFirstName       , setErrorFirstName]        = useState(false);
    const [errorMiddleName      , setErrorMiddleName]       = useState(false);
    const [errorLastName        , setErrorLastName]         = useState(false);
    const [errorBirthdate       , setErrorBirthdate]        = useState(false);
    const [errorBirthplace      , setErrorBirthplace]       = useState(false);
    const [errorMobileNumber    , setErrorMobileNumber]     = useState(false);
    const [errorAddress         , setErrorAddress]          = useState(false);
    const [errorCountry         , setErrorCountry]          = useState(false);

    const user_info = signUp.user_info;
    const business  = user_info?.account_type?.business;
    const [businessType    , setBusinessType]           = useState(business?.type || '');
    const [businessCategory, setBusinessCategory]       = useState(business?.category || '');
    const [businessSubcategory, setBusinessSubcategory] = useState(business?.subcategory || '');
    const [businessName    , setBusinessName]           = useState(signUp.user_info?.name || '');
    const [firstName       , setFirstName]              = useState(signUp.firstName || '');
    const [lastName        , setLastName]               = useState(signUp.lastName || '');
    const [middleName      , setMiddleName]             = useState(signUp.user_info?.middle_name || '');
    const [birthdate       , setBirthdate]              = useState(signUp.user_info?.birth_date || '');
    const [birthplace      , setBirthplace]             = useState(signUp.user_info?.birth_place || '');
    const [prefix          , setMobilePrefix]           = useState(signUp.user_info?.nationality || ip_info?.countryCode || 'PH');
    const [number          , setMobileNumber]           = useState(signUp.user_info?.mobile_number?.number || '');
    const [address         , setAddress]                = useState(signUp.user_info?.address || '');
    const [nationality     , setNationality]            = useState(signUp.user_info?.nationality || ip_info?.countryCode);  // Default country is PH. (signUp.user_info?.nationality || ip_info?.country_code);

    const getPrefix = () => (
        countries &&
        countries.find(country => country.code == prefix) &&
        countries?.find(country => country.code == prefix)?.mobile_code || ''
    )

    const setBasicInfo = () =>  {
        const prefix = getPrefix()
        dispatch(signUpActions.setBasicInfo({
            firstName, lastName, middleName,
            birthdate, birthplace,
            address  , nationality,
            mobileNumber: {
                prefix, number
            }
        }))
        dispatch(signUpActions.setBusinessInfo({
            businessName,
            businessType,
            businessCategory,
            businessSubcategory
        }))
    }

    useEffect(() => setBasicInfo(), [])
    useEffect(() => setBasicInfo(), [
        firstName, lastName, middleName,
        birthdate, birthplace,
        address  , nationality,
        countries,
        prefix   , number,
        businessName,
        businessType,
        businessCategory,
        businessSubcategory
    ])

    useEffect(() => {
        dispatch(signUpActions.getSuggestedUsernames({firstname: firstName, lastname: lastName, number}))
    }, [firstName, lastName, number])

    useEffect(() => {
        if (validated) {
            if (accountType == 'business') {
                setErrorBusinessType(!businessType);
                setErrorBusinessCategory(!businessType);
                setErrorBusinessName(!businessName);
            }
            setErrorFirstName(!firstName);
            setErrorMiddleName(!middleName);
            setErrorLastName(!lastName);
            setErrorBirthdate(!birthdate);
            setErrorBirthplace(!birthplace)
            setErrorMobileNumber(!prefix || !number || number.length != 11)
            setErrorAddress(!address)
            setErrorCountry(!nationality)
        }
    }, [validated])

    useEffect(() => setNationality(signUp.user_info?.nationality || ip_info?.countryCode), [signUp.user_info?.nationality, ip_info?.countryCode])

    const countriesOptions = countries?.reduce((countries, country) => ({...countries, [country.code]: country.name}), {})

    return <>
        <section className={classes.divider}>
            <span>{
                accountType === "individual" ?
                'PERSONAL INFORMATION' :
                'BUSINESS INFORMATION'
            }</span>
        </section>
        <section className="input-group" data-basic-info>
            {accountType === "business" && <>
                <InputField
                    type = "select"
                    name = "business_type"
                    placeholder = "Select Business Type"
                    options = {Object.keys(business_types).map(type => <option value={type}>{business_types[type]}</option>)}
                    label = "Business Type"
                    style = {{container: `full-width ${businessType? 'valid': ''} ${classes.inputField}`}}
                    value = {businessType}
                    error = {errorBusinessType}
                    error_message = {errorBusinessType ? required_field : ''}
                    onSelectChange = {e => {
                        setBusinessType(e.target.value)
                        setErrorBusinessType(e.target.value.trim() === '')
                    }}
                />
                <DropdownField
                    label         = "Business Category"
                    placeholder   = "Select Business Category"
                    style         = {{container: classes.inputField}}
                    value         = {businessSubcategory}
                    data          = {business_categories}
                    error         = {errorBusinessCategory}
                    error_message = {errorBusinessCategory ? required_field : ''}
                    onChange      = {(value, selected) => {
                        setBusinessCategory(value);
                        setBusinessSubcategory(selected);
                        setErrorBusinessCategory(value.trim() === '')
                    }}
                />
                <InputField
                    type = "text"
                    name = "business_name"
                    label = "Business Name"
                    placeholder = "Store/Company/Business Name"
                    style = {{container: `full-width ${businessName? 'valid': ''} ${classes.inputField}`}}
                    value = {businessName}
                    error = {errorBusinessName}
                    error_message = {errorBusinessName ? required_field : ''}
                    onChange = {e => {
                        setBusinessName(e.target.value)
                        setErrorBusinessName(e.target.value.trim() === '')
                    }}
                />
            </>}
            <InputField
                type = "text"
                name = "first_name"
                label = "First Name"
                placeholder = "Juan"
                error = {errorFirstName}
                value = {firstName}
                error_message = {errorFirstName ? required_field : ''}
                style    = {{container: `${classes.inputField} ${firstName? 'valid': ''}`}}
                onChange = {e => {
                    setFirstName(e.target.value)
                    setErrorFirstName(e.target.value.trim() === '')
                }}
            />
            <InputField
                type = "text"
                name = "last_name"
                label = "Last Name"
                placeholder = "Dela Cruz"
                error = {errorLastName}
                value = {lastName}
                error_message = {errorLastName ? required_field : ''}
                style    = {{container: `${classes.inputField} ${lastName? 'valid': ''}`}}
                onChange = {e => {
                    setLastName(e.target.value)
                    setErrorLastName(e.target.value.trim() === '')
                }}
            />
            <InputField
                type = "text"
                name = "middle_name"
                label = "Middle Name"
                placeholder = "Santos"
                error         = {errorMiddleName}
                value         = {middleName}
                error_message = {errorMiddleName ? required_field : ''}
                style    = {{container: `${classes.inputField} ${middleName? 'valid': ''}`}}
                onChange = {e => {
                    setMiddleName(e.target.value)
                    setErrorMiddleName(e.target.value.trim() === '')
                }}
            />
            <InputField
                type          = "select"
                name          = "country"
                label         = "Country"
                value         = {nationality}
                error         = {errorCountry}
                error_message = {errorCountry ? required_field : ''}
                placeholder   = "Country"
                style   = {{container: `${classes.inputField} ${nationality? 'valid': ''}`}}
                options = {countriesOptions && Object.keys(countriesOptions).map(country => <option value={country} disabled={country != 'PH'}>{countriesOptions[country]}</option>)}
                onSelectChange = {e => {
                    setNationality(e.target.value)
                    setErrorCountry(!e.target.value)
                }}
            />
            <InputField
                type    = "date"
                name    = "birth_date"
                label   = "Birthdate"
                placeholder = "MM/DD/YYYY"
                error = {errorBirthdate}
                value = {birthdate}
                error_message = {errorBirthdate ? required_field : ''}
                style    = {{container: `${classes.inputField} ${birthdate? 'valid': ''}`}}
                onChange = {e => {
                    setBirthdate(e.target.value)
                    setErrorBirthdate(e.target.value.trim() === '')
                }}
            />
            <InputField
                type    = "text"
                name    = "birth_place"
                label   = "Birthplace"
                placeholder = "Your Birthplace"
                error = {errorBirthplace}
                value = {birthplace}
                error_message = {errorBirthplace ? required_field : ''}
                style    = {{container: `${classes.inputField} ${birthplace? 'valid': ''}`}}
                onChange = {e => {
                    setBirthplace(e.target.value)
                    setErrorBirthplace(e.target.value.trim() === '')
                }}
            />
            <InputField
                type          = "textarea"
                name          = "address"
                label         = "Address"
                placeholder   = "Your current complete address"
                value         = {address}
                style         = {{container: `full-width ${address? 'valid': ''} ${classes.inputField}`}}
                error         = {errorAddress}
                error_message = {errorAddress ? required_field : ''}
                onTextAreaChange      = {e => {
                    setAddress(e.target.value)
                    setErrorAddress(e.target.value.trim() === '')
                }}
            />
            <InputField readonly
                type    = "email"
                name    = "email"
                label   = "Your Email"
                value   = {signUp.email}
                style   = {{container: `${classes.inputField} ${signUp.email? 'valid': ''}`}}
            />
            <InputField
                type          = "mobile"
                name          = "mobile_number"
                label         = "Mobile Number"
                country       = {prefix}
                placeholder   = "912 345 6789"
                value         = {formatMobile(number.substring(1))}
                error         = {errorMobileNumber}
                error_message = {errorMobileNumber ? (!number || number == '0'? required_field: invalid_format) : ''}
                style         = {{container: `${classes.inputField} ${number? 'valid': ''}`}}
                onChange      = {e => {
                    const value = "0"+e.target.value.replaceAll(' ', '').trim();
                    setMobileNumber(value)
                    setErrorMobileNumber(value === '' || value.length != 11)
                }}
                onSelectChange = {e => {
                    setMobilePrefix(countries?.find(c => c.code == e.target.value)?.mobile_code || 'PH')
                }}
            />
        </section>
    </>
});

export default BasicInformation;