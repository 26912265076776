import InputField from 'app/components/InputField'
import React, { memo } from 'react'

interface Props {
    name     : string
    selected?: string
    account  : string
    onChange?: (type) => void
}

const TypeRadioGroup = memo(({name, selected, account, onChange}: Props) => {
    const types = ( account=="beep" ? 
        {
            static  : "Static",
            field   : "Input Text",
            drop    : "Drop Down",
            multiple: "Multi-input",
            disable : "Disable"
        } : 
        {
            static  : "Static",
            field   : "Input Text",
            multiple: "Multi-input",
            disable : "Disable"
        }
    )
    return (
        <section className='radio-group'>
            {Object.keys(types).map(
                type => (
                    <InputField
                        type  = "radio"
                        name  = {`${name}_type_radio_group`}
                        title = {types[type]}
                        value = {type}
                        checked = {(selected? selected: "static") == type}
                        onChange={() => onChange && onChange(type)}
                    />
                )
            )}
        </section>
    )
})

export default TypeRadioGroup