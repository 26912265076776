import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo, useEffect } from "react";
import welcome_icon from 'assets/img/welcome-icon.webp';
import ButtonLoader from "app/components/ButtonLoader";

const WelcomeModal = memo(({state}: DefaultModalProps) => {
    const classes = useStyles();

    const onClick = () => state[1](false)

    useEffect(() => {state[0] && sessionStorage.setItem('welcomeModalClosed', '1')}, [state[0]])

    return <Modal state={state} dialogClassname={classes.modal}>
        <div className={classes.content}>
            <h2 className={classes.header}>Welcome to JustPayto!</h2>
            <img src={welcome_icon} className={classes.icon}/>
            <p className={classes.pharagraph}>
                Enjoy JustPayto Premium for 90 days.<br/>
                Receive money in real-time, get SMS notification, and more
            </p>
        </div>
        <ButtonLoader text="Continue" style={`filled blue ${classes.button}`} onClick={onClick}/>
    </Modal>
})

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        modal: {
            borderRadius: "5px!important",
            fontFamily: "Arial",
            textAlign: "center",
            letterSpacing: "-0.2px",
            ["& > .modal"]: {
                padding: "40px"
            }
        },
        header: {
            fontSize: "33px",

        },
        icon: {
            width: "100px",
            marginBottom: "30px"
        },
        pharagraph: {
            marginBottom: "30px"
        },
        button: {
            marginBottom: 0
        },
        content: {
            padding: "10px 20px"
        }
    })
)

export default WelcomeModal