import React, { memo } from "react";
import TableData from "./TableData";

interface Props {
    asComponent
    transactions
    account_type
    toggleTransaction
    openTransactionModal
    openDeliveryModal
}

const TableBody = memo(({asComponent, transactions, account_type, toggleTransaction, openTransactionModal, openDeliveryModal}: Props) => (
    <tbody>
        {transactions.map((transaction, i) => {
            return <TableData
                asComponent          = {asComponent}
                odd                  = {i%2 != 0}
                transaction          = {transaction}
                toggleTransaction    = {toggleTransaction}
                openTransactionModal = {() => openTransactionModal(transaction)}
                openDeliveryModal    = {() => openDeliveryModal(transaction)}
            />
        })}
    </tbody>
))

export default TableBody