import { Grid } from '@material-ui/core';
import { selectUserInfo } from "app/common/auth/selectors";
import React from "react";
import { useSelector } from "react-redux";
import { clients } from "utils/options";
import Transactions from "../Transactions";
import ApiClientOverview from "./ApiClient";
import BusinessOverview from "./Business";
import IndividualOverview from "./Individual";
import PartnerOverview from "./Partner";

const Overview = () => {
    const userInfo = useSelector(selectUserInfo);

    const isApi      = userInfo?.username && userInfo?.groups?.includes(userInfo.username);
    const isBusiness = userInfo?.account_type?.type == 'business'
    const isClient   = clients.includes(userInfo?.username || "") || userInfo?.groups?.includes("Partners")

    return (<>
        <section jpt-panel="reports" className="overview">
            <section className="divider"><span>OVERVIEW</span></section>
            {
                userInfo && (
                    isApi?
                        <ApiClientOverview/>:
                    isClient?
                        <PartnerOverview/>:
                    isBusiness?
                        <BusinessOverview/>:
                        <IndividualOverview/>
                )
            }
            <Grid container className="container-grid" direction="column" alignItems="center">
                <Transactions asComponent/>
            </Grid>
        </section>
    </>)
};

export default Overview;