import { CircularProgress } from "@material-ui/core";
import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import Modal, { DefaultModalProps } from "app/components/Modal";
import ErrorModal from "app/components/Modal/ErrorModal";
import countdown from 'countdown';
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatMobile } from "utils";
import { selectSignUpState } from "../selectors";
import { signUpActions } from "../slice";
import { useStyles } from "./styles";

const SecureAccountModal = memo((props: DefaultModalProps) => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const signup = useSelector(selectSignUpState);
    const [open,setOpen] = props.state;
    const [timer, setTimer] = useState('');
    const [timeInterval, setTimeInterval] = useState<number>();

    const requiredErrorMessage = 'This field is required.'
    const defaultEmailMessage  = 'Enter the code that has been sent to your email.'
    const defaultSmsMessage    = 'Enter the code that has been sent to your mobile.'
    const wrongEmailMessage    = 'Invalid code'
    const wrongSmsMessage      = 'Invalid code'
    const [errorEmailCode, setErrorEmailCode] = useState(defaultEmailMessage);
    const [errorSmsCode  , setErrorSmsCode  ] = useState(defaultSmsMessage);
    const [emailCode, setEmailCode] = useState('');
    const [smsCode  , setSmsCode  ] = useState('');
    const [errorModal, setErrorModal] = useState(false);
    const [lockOutTimer, setLockOutTimer] = useState('');
    const [lockOutExpiry, setLockOutExpiry] = useState<number>();
    const [lockOutInterval, setLockOutInterval] = useState(-1);

    const verify = () => {
        if (emailCode && smsCode) {
            dispatch(signUpActions.verifyCodes({emailCode, smsCode}))
        }
        if (!emailCode) {
            setErrorEmailCode(requiredErrorMessage);
        }
        if (!smsCode) {
            setErrorSmsCode(requiredErrorMessage);
        }
    }

    useEffect(() => {
        if (!signup.sendingOtp && signup.otpSent) {
            props.state[1](true);
        }
    }, [signup.sendingOtp, signup.otpSent])

    useEffect(() => {
        if (signup.verificationResult?.email && signup.verificationResult?.sms) {
            setOpen(false);
            setEmailCode('');
            setSmsCode('');
        } else if (signup.verificationResult) {
            setErrorEmailCode(signup.verificationResult?.email? defaultEmailMessage: wrongEmailMessage);
            setErrorSmsCode(signup.verificationResult?.sms? defaultSmsMessage: wrongSmsMessage);
        }
    }, [signup.verificationResult])

    // WARNING: DO NOT TOUCH THIS CODE, YOUR COMPUTER WILL EXPLODE. YOU HAVE BEEN WARNED ('_')
    useEffect(() => {
        if (signup.verificationExpiry) {
            let date = signup.verificationExpiry;
            if (timeInterval) {
                clearInterval(timeInterval);
            }
            let intervalId: number = setInterval(() => {
                if (date) {
                    let time = countdown(new Date(date));
                    if (new Date(date).getTime() < new Date().getTime()) {
                        setTimer('')
                        clearInterval(intervalId)
                        setTimeInterval(undefined)
                        dispatch(signUpActions.otpSent(''))
                    } else {
                        let minutes = time.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
                        let seconds = time.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
                        let timer = `${minutes}:${seconds}`
                        setTimer(timer)
                    }
                }
            }, 1000);
            setTimeInterval(intervalId)
        }
    }, [signup.verificationExpiry])

    useEffect(() => {
        if (timer == '00:00') {
            clearInterval(timeInterval)
            setTimeInterval(undefined)
        }
    }, [timer])

    useEffect(() => {
        if (!timeInterval) {
            setTimer('')
        }
    }, [timeInterval])

    useEffect(() => {
        if (signup.lockOutExpiration) {
            clearInterval(lockOutInterval);
            setLockOutInterval(-1);
            setLockOutExpiry(undefined)
            setLockOutExpiry(signup.lockOutExpiration);
            setErrorModal(true)
            setOpen(false);
        }
    }, [signup.lockOutExpiration])

    useEffect(() => {
        if (lockOutInterval === -1 && lockOutExpiry) {
            var timer = lockOutExpiry
            let minutes = Math.floor(timer/60)
            var seconds = Math.floor(timer - (minutes * 60))
            setLockOutTimer(`${minutes}:${`0${seconds}`.slice(-2)}`)
            timer--;
            setLockOutExpiry(timer);
            setLockOutInterval(setInterval(function() {
                let minutes = Math.floor(timer/60)
                var seconds = Math.floor(timer - (minutes * 60))
                let time = `${minutes}:${`0${seconds}`.slice(-2)}`;
                if (time != "0:00") {
                    setLockOutTimer(time)
                } else {
                    setLockOutTimer('');
                }
                timer--;
                setLockOutExpiry(timer);
            }, 1000));
        }
        if (lockOutInterval !== -1 && lockOutExpiry == -1) {
            clearInterval(lockOutInterval);
            setLockOutInterval(-1);
            setErrorModal(false);
            setLockOutExpiry(undefined)
            dispatch(signUpActions.setLockOutExpiry(undefined))
            setOpen(errorModal);
            if (errorModal) {
                dispatch(signUpActions.sendOtp())
            }
        }
    }, [lockOutExpiry])

    useEffect(() => {
        if (lockOutExpiry !== undefined) {
            setOpen(lockOutExpiry <= -1)
            setErrorModal(lockOutExpiry > -1)
        }
    }, [open])

    return <>
        <ErrorModal state={[errorModal, setErrorModal]} title="Error" >
            You have been locked out due to too many 2FA requests. <br/> Please try again in {lockOutTimer}.
        </ErrorModal>
        <Modal state = {props.state} style = "larger_modal" disableBackdropClick
            containerClassname = {`modal-container ${classes.secureAccountModalContainer}`}
            dialogClassname    = {classes.secureAccountModal}
        >
            <section className="pseudo-form verification-form">
                <h1 className="header">Secure your account</h1>
                <div className="subheader">This will setup multiple layers of security for your information and transactions.</div>
                <section className={`modal-divider ${classes.divider}`}><span>EMAIL &amp; MOBILE VERIFICATION</span></section>
                <section className="input-group">
                    <InputField
                        max         = {6}
                        type        = 'text'
                        name        = 'email_code'
                        placeholder = 'ABC123'
                        instruction = 'Enter the code that has been sent to your email.'
                        label       = {
                            <div className={classes.secureAccountModalLabel}>
                                An email with your code has been sent to:{' '}
                                <span className="highlight">{signup.email}</span>
                            </div>
                        }
                        style       = {{container: "full-width"}}
                        value       = {emailCode}
                        error       = {errorEmailCode != defaultEmailMessage}
                        error_message={errorEmailCode}
                        onChange    = {e => {
                            setEmailCode(e.target.value.toUpperCase())
                            setErrorEmailCode(e.target.value ? defaultEmailMessage : requiredErrorMessage)
                        }}
                    />
                    <InputField
                        max         = {6}
                        type        = 'text'
                        name        = 'mobile_code'
                        placeholder = 'ABC123'
                        instruction = 'Enter the code that has been sent to your mobile.'
                        label       = {
                            <div className={classes.secureAccountModalLabel}>
                                An SMS with your code has been sent to:{' '}
                                <span className="highlight" style={{whiteSpace: 'nowrap'}}>{signup.user_info?.mobile_number?.prefix} {formatMobile(signup.user_info?.mobile_number?.number?.substring(1))}</span>
                            </div>
                        }
                        style       = {{container: "full-width"}}
                        value       = {smsCode}
                        error       = {errorSmsCode != defaultSmsMessage}
                        error_message={errorSmsCode}
                        onChange    = {e => {
                            setSmsCode(e.target.value.toUpperCase())
                            setErrorSmsCode(e.target.value ? defaultSmsMessage : requiredErrorMessage)
                        }}
                    />
                </section>
                <section>
                    {timer ?
                        <p className="twofa-timer" data-verification-countdown="true">Code expires in <span data-verification-countdown-timer>{timer}</span></p>:
                        <p className="twofa-timer" data-verification-countdown="false">Code expired {
                            signup.sendingOtp?
                            <CircularProgress size={15}/>:
                            <>(<span className="pointer" data-verification-resend onClick={() => dispatch(signUpActions.sendOtp())}>Resend code</span>)</>
                        }</p>
                    }
                    <ButtonLoader submit hasLoader
                        loading = {signup.verifying}
                        name  = 'verification'
                        style = {`filled blue ${classes.button}`}
                        text  = 'Next'
                        onClick = {() => verify()}
                    />
                </section>
            </section>
        </Modal>
    </>
});

export default SecureAccountModal;