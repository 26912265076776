import { selectUserInfo } from "app/common/auth/selectors";
import ButtonLoader from "app/components/ButtonLoader";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import { TransactionData } from "app/containers/UserDashboardPage/types";
import {
    payment_methods,
    fulfillment_methods,
    transaction_status
} from "app/containers/UserDashboardPage/utils";
import React, { createRef, memo } from "react";
import ReactExport from 'react-data-export';
import { useDispatch, useSelector } from "react-redux";
import { formatDatetime, numberFormat, title } from "utils";
import { clients } from "utils/options";

const File   = ReactExport.ExcelFile;
const Sheet  = ReactExport.ExcelFile.ExcelSheet;

interface Props {
    data    : any[]
    filename: string
    total   : number
    loading?: boolean
}

const ExcelDownload = memo(({data, filename, total, loading}: Props) => {
    const user             = useSelector(selectUserInfo);
    const username         = user?.username || ""
    const is_client        = clients.includes(username);
    const dispatch         = useDispatch()
    const transaction_date = data => formatDatetime(data.updated_at, "MM/DD/YYYY")
    const transaction_time = data => formatDatetime(data.updated_at, "H:mm:ss")
    const on_hold          = data => data.additional_details?.conditions?.is_onhold? 'hold': data.status
    const status           = data => transaction_status[on_hold(data)]
    const mobile_number    = data => (
        typeof data.sender_details?.mobile === 'string'?
        data.sender_details?.mobile:
        data.sender_details?.mobile?
        `${data.sender_details?.mobile?.prefix||''}${data.sender_details?.mobile?.number||''}`:
        ""
    ).replaceAll(' ', '')
    const payment_method     = data => data.source_of_funds?.payment_method? payment_methods[data.source_of_funds?.payment_category]: ""
    const fulfillment_method = data => fulfillment_methods[data.additional_details?.order_details?.method] || "Regular Payment"
    const network            = data => title((data.source_of_funds?.service_name || '').replaceAll('_', ' '))
    const processing_fee     = data => +(data.fees?.sending?.fee || data.fees?.sending_fee || 0) + +(data.fees?.receiving?.fee || data.fees?.receiving_fee || 0)
    const system_fee         = data => +(data.fees?.system || data.fees?.system_fee || 0)
    const fulfillment_fee    = data => +(data.fees?.delivery?.actual_fee || 0) + +(data.fees?.delivery?.system || 0)
    const other_charges      = data => +(data.other_charges || 0)
    const amount             = data => +(data.amount_sent || 0)
    const payout             = data => +(data.payout_amount || 0)
    const style = width => ({width: {wpx: width}})
    const client_detail_title = {
        autosweeprfid: "Plate No.",
        easytriprfid : "Account No.",
        beep         : "Card No.",
        bayadcenter  : "Account No.",
    }
    const message_title = is_client? client_detail_title[username]: "Message/Instructions/Purpose"
    const client_detail_data = data => ({
        autosweeprfid: data.additional_details?.client_details?.autosweeprfid?.plate_number,
        easytriprfid : data.additional_details?.client_details?.easytriprfid?.account_no,
        beep         : data.additional_details?.client_details?.beep?.card_number,
        bayadcenter  : (
            data.additional_details?.client_details?.bayadcenter?.account_number ||
            data.additional_details?.client_details?.bayadcenter?.other_details &&
            data.additional_details?.client_details?.bayadcenter?.other_details?.account_number
        ),
    }[username])
    const dataset = [{
        columns: [
            {title: "Transaction Number"          , ...style(140)},
            {title: "Transaction Date"            , ...style(120)},
            {title: "Transaction Timestamp"       , ...style(160)},
            {title: "Status"                      , ...style(100)},
            {title: "Sender's Name"               , ...style(180)},
            {title: message_title                 , ...style(is_client? 180: 245)},
            {title: "Mobile Number"               , ...style(140)},
            {title: "Fulfillment Method"          , ...style(130)},
            {title: "Payment Method"              , ...style(120)},
            {title: "Network"                     , ...style(210)},
            {title: "Currency"                    , ...style(65)},
            {title: "Amount"                      , ...style(85)},
            {title: "Processing fee"              , ...style(100)},
            {title: "System fee"                  , ...style(80)},
            {title: "Fulfillment Fee"             , ...style(105)},
            {title: "Other charges"               , ...style(100)},
            {title: "Payout"                      , ...style(85)},
        ],
        data: data.map(data => [
            {value: data.payment_id},
            {value: transaction_date(data)},
            {value: transaction_time(data)},
            {value: status(data)},
            {value: data.sender_details?.name},
            {value: is_client? client_detail_data(data): data.sender_details?.message},
            {value: mobile_number(data)},
            {value: fulfillment_method(data)},
            {value: payment_method(data)},
            {value: network(data)},
            {value: data.amount_details?.currency},
            {value: numberFormat(amount(data))},
            {value: numberFormat(processing_fee(data?.amount_details))},
            {value: numberFormat(system_fee(data?.amount_details))},
            {value: numberFormat(fulfillment_fee(data?.amount_details))},
            {value: numberFormat(other_charges(data))},
            {value: numberFormat(payout(data))},
        ])
    }]

    const ref = createRef<HTMLButtonElement>();
    return <>
        <File filename = {filename} element = {<button ref={ref}/>}>
            <Sheet dataSet={dataset} name="Transactions"/>
        </File>
        <ButtonLoader hasLoader
            loading = {loading}
            onClick = {() => ref.current?.click() && dispatch(userDashboardPageActions.updateActionTime())}
            style   = "filled blue"
            text    = {data.length != total ? `Download Selected (${data.length})` : 'Download'}
        />
    </>
})

export default ExcelDownload