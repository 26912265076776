import { ANNOUNCEMENT, PARTNERS, GET_EARLY_ACCESS } from 'app/common/core_api/resources';
import { request } from 'utils/request';
import { call, delay, put, takeLeading } from 'redux-saga/effects';
import { landingPageActions } from "./slice";
import { PayloadAction } from '@reduxjs/toolkit';

export function* getAnnouncement() {
    yield delay(500);
    try {
        let data = yield call(request, ANNOUNCEMENT);
        yield put(landingPageActions.loadedAnnouncement(data));
    } catch(e) {
        console.log(e)
    }
}

export function* getPartners() {
    yield delay(500);
    try {
        let data = yield call(request, PARTNERS);
        yield put(landingPageActions.loadedPartners(data));
    } catch(e) {
        console.log(e)
    }
}

export function* getEarlyAccess(action: PayloadAction<string>) {
    yield delay(500);
    try {
        let options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body  : JSON.stringify({email: action.payload})
        }
        let signUpResult = yield call(request, GET_EARLY_ACCESS, options);
        yield put(landingPageActions.earlyAccessResult(signUpResult));
    } catch(e) {
        console.log(e)
    }
}

export function* landingPageSaga() {
    yield takeLeading(landingPageActions.getAnnouncement.type, getAnnouncement);
    yield takeLeading(landingPageActions.getPartners.type, getPartners);
    yield takeLeading(landingPageActions.getEarlyAccess.type, getEarlyAccess);
}