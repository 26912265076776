import { ErrorOutlineOutlined } from '@material-ui/icons';
import Skeleton from "@material-ui/lab/Skeleton";
import { selectLoadingUser, selectUserInfo } from "app/common/auth/selectors";
import { GeneralComponent } from "app/common/slice";
import SuccessModal from "app/components/Modal/SuccessModal";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import 'styles/sass/user-dashboard.scss';
import { clients } from "utils/options";
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import MasterPage from "../MasterPage";
import DepositoryDetails from "../Onboarding/AccountSetup/DepositoryDetails";
import Documents from "../Onboarding/AccountSetup/Documents";
import Snackbar from "./components/Snackbar";
import ChangePlanModal from "./components/modals/ChangePlanModal";
import ReactivateAccountModal from "./components/modals/ReactivateAccountModal";
import TimeToUpgradeModal from "./components/modals/TimeToUpgradeModal";
import WelcomeModal from "./components/modals/WelcomeModal";
import { userDashboardSaga as saga } from './saga';
import { selectUserDashboard } from "./selectors";
import { sliceKey as key, reducer, userDashboardPageActions } from './slice';
import useAuth from './useAuth';

const UserDashboardPage = memo(({children}) => {
    useInjectReducer({key, reducer});
    useInjectSaga({key, saga});

    const dispatch      = useDispatch();
    const location      = useLocation();
    const auth          = useAuth();
    const loading       = useSelector(selectLoadingUser);
    const userInfo      = useSelector(selectUserInfo);
    const userDashboard = useSelector(selectUserDashboard);

    const v2            = process.env.REACT_APP_USE_KEYCLOAK == 'True'
    const isBusiness    = userInfo?.account_type?.type == 'business'
    const isClient      = clients.includes(userInfo?.username || '')

    const [welcomeModal, setWelcomeModal]                    = useState(false);
    const [timeToUpgradeModal, setTimeToUpgradeModal]        = useState(false);
    const [reactivateModal, setReactivateModal]              = useState(false);

    const [savingUser, setSavingUser] = useState<boolean | undefined>();
    const [successPendingDetails, showSuccessPendingDetails] = useState(false);
    const [timer, setTimer] = useState<number | undefined>()

    useEffect(() => {
        let start = moment().subtract(6, 'days').format('YYYY-MM-DD'),
            end   = moment().format('YYYY-MM-DD');

        userInfo && dispatch(userDashboardPageActions.getTransactions({start, end}))
    }, [userInfo])
    useEffect(() => {
        if (userDashboard.updateUserResult) {
            setSavingUser(false)
        }
    }, [userInfo?.registration_status, userDashboard.updateUserResult])

    useEffect(() => {
        if (userInfo?.sub) {
            dispatch(GeneralComponent.getUser(userInfo?.sub))
        }
    }, [userInfo])

    useEffect(() => {
        let lastLogin   = userInfo?.log_in_records?.slice(-1)[1],
            renewalDate = userInfo?.last_renewal_date + " 00:00:00"
        if (
            savingUser === false &&
            userInfo?.registration_status?.depository == "review" &&
            userInfo?.registration_status?.documents == "review" //&&
            // process.env.REACT_APP_USE_KEYCLOAK != 'True' || (
            //     userInfo?.is_trial && (
                    
            //         !lastLogin ||
            //         moment(lastLogin).isBefore(moment(renewalDate))
            //     )
            // )
        ) {
            showSuccessPendingDetails(true);
            setSavingUser(undefined);
        }
    }, [savingUser, userInfo?.registration_status])

    useEffect(() => {
        /* SHOWS THE WELCOME MODAL*/
        /**
         * Ideally, log in records will always have a record because this page is accessible only once logged in
         * - lastLogin  : technically, this is the 2nd to last login since the last login is the time this page is accessed
         * - lastRenewal: used to check whether user has logged in after getting Premium (trial)
         *     - shows modal if user hasn't logged in after getting Premium (trial)
         */
        if (userInfo?.log_in_records?.length) {
            const lastLogin = moment(
                userInfo?.log_in_records?.length > 1?
                userInfo.log_in_records?.at(-2):
                userInfo.log_in_records?.[0]
            )
            const lastRenewal = userInfo?.last_renewal_date? moment(userInfo?.last_renewal_date): undefined
            const welcomeModalClosed = sessionStorage.getItem('welcomeModalClosed')
            setWelcomeModal(
                /**
                 * Conditions:
                 * - User should be Live
                 * - User should be Premium
                 * - User should be on trial
                 * - First time log in or
                 * - First time log in after getting Premium (trial)
                 */
                userInfo?.status == 'live' &&
                !!userInfo?.groups?.includes('Premium') &&
                !!userInfo?.is_trial && (
                    userInfo?.log_in_records?.length == 1 || (
                        !!lastLogin &&
                        !!lastRenewal &&
                        lastLogin.isBefore(lastRenewal)
                    )
                ) && (
                    welcomeModalClosed === null || !welcomeModalClosed
                )
            )
        }
    }, [userInfo?.last_renewal_date, userInfo?.log_in_records])

    useEffect(() => {
        /* SHOWS THE TIME TO UPGRADE MODAL*/
        if (userInfo?.groups?.includes("Premium")) {
            return;
        }
        // Get the 2nd last login (last login is the current login)
        const last_login   = userInfo?.log_in_records?.at(-2)
        const last_renewal = userInfo?.last_renewal_date

        if (last_login && last_renewal) {
            const last_login_date = moment(last_login)
            const last_renewal_date = moment(last_renewal)

            if (last_login_date.isBefore(last_renewal_date)) {
                setTimeToUpgradeModal(true)
            }
        }
    }, [])

    const TabButton = memo((props: {
        name : string,
        title: string
    }) => {
        const {name, title} = props;
        const isActive = (
            window.location.href.split('/').pop() == name ||
            name == "" && window.location.href.split('/').pop() == 'user-dashboard'
        )
        return (
            <div jpt-tab={name} className={isActive ? 'active' : ''}>
                <Link to={`/user-dashboard/${name}`} onClick={() => dispatch(userDashboardPageActions.setTab(name))}>{title}</Link>
            </div>
        )
    })

    useEffect(() => {
        if (auth?.access_token) {
            dispatch(userDashboardPageActions.setChangePlan(false))
            dispatch(userDashboardPageActions.getLastPayoutDate())
            dispatch(userDashboardPageActions.getOverview())
        }
    }, [auth])

    const nextFromDepository = (depository_details: any) => {
        setSavingUser(true)
        const registration_status = {...userInfo?.registration_status}
        registration_status['depository'] = "review"
        const data = {status: 'review', depository_details, registration_status}
        dispatch(userDashboardPageActions.updateUser({username: userInfo?.username || '', data}))
    }

    const nextFromDocuments  = (document_meta: any) => {
        setSavingUser(true)
        const registration_status = {...userInfo?.registration_status}
        registration_status['documents'] = "review"
        const data = {status: 'review', document_meta, registration_status}
        dispatch(userDashboardPageActions.updateUser({username: userInfo?.username || '', data}))
    }

    if (!loading && !auth.access_token) {
        return <Redirect to="/login"/>
    }

    const openReactivateModal = () => {
        setReactivateModal(true)
    }

    const updateActionTime = () => dispatch(userDashboardPageActions.updateActionTime());

    return <div onClick={updateActionTime}>
        <MasterPage hasHeader hasFooter title = {userInfo? `${userInfo.name} - Dashboard`: 'Dashboard'}>
            <WelcomeModal state={[welcomeModal, setWelcomeModal]}/>
            <TimeToUpgradeModal state={[timeToUpgradeModal, setTimeToUpgradeModal]}/>
            {process.env.REACT_APP_USE_KEYCLOAK == 'True' && <Snackbar show={userInfo?.status == 'deactivated'}>
                <ReactivateAccountModal state={[reactivateModal, setReactivateModal]}/>
                <ErrorOutlineOutlined style={{marginTop: '-2px'}}/> You have deactivated your account last {moment(userInfo?.updated_at).format('MM/DD/YYYY')}. To activate your account, click <a onClick={openReactivateModal}>here</a>
            </Snackbar>}
            <section className="container user-dashboard">
                <header>
                    {userInfo? <h2>Welcome Back, <div style={{display: "inline-block"}}>{`${userInfo.name}!`}</div></h2>: <Skeleton variant="text" animation="wave" style={{margin: "1rem auto", height: "5vh", width: "200px"}}/>}
                    <figure className="avatar">
                        {userInfo? <img src={ userInfo.avatar || userInfo.profile_photo } alt="Profile Photo"/>: <Skeleton variant="circle" animation="wave" height={"100%"}/>}
                    </figure>
                    {userInfo && <section className="tab-links">
                        <TabButton name=""              title="Overview"/>
                        <TabButton name="transactions"  title="Transactions"/>
                        <TabButton name="account"       title="My Account"/>
                        {isClient   && <TabButton name="customizations" title="Customizations"/>}
                        <TabButton name="settings"      title="Settings"/>
                        {/* {isBusiness && <TabButton name="invoice" title="Invoice"/>} */}
                        {v2 && (isBusiness || isClient) && <TabButton name="analytics" title="Analytics"/>}
                    </section>}
                </header>
                {
                    ['live', 'deactivated', 'deleted'].includes(userInfo?.status || '') ? (
                        !['/user-dashboard/', '/user-dashboard'].includes(location.pathname) ||
                        userDashboard?.overview?
                        <section className="panels">
                            {children}
                        </section>:
                        <Skeleton variant="rect" animation="wave" style={{margin: "2rem 0", height: "50vh"}}/>
                    ):
                    !savingUser && userInfo?
                    <section className="panels">
                        {
                            userInfo?.registration_status?.depository != 'pending' &&
                            userInfo?.registration_status?.documents  != 'pending'?
                            <h2 className="pending-account-header">Your account is under review</h2>:<>
                                <h2 className="pending-account-header">Your account creation is almost done!</h2>
                                <form className="signup">{
                                    userInfo?.registration_status?.depository == 'pending'?
                                    <DepositoryDetails next = {nextFromDepository} formData={userInfo.depository_details || {}} saving = {savingUser}/>:
                                    userInfo?.registration_status?.documents == 'pending'?
                                    <Documents next = {nextFromDocuments} formData={userInfo.document_meta} account_type={userInfo.account_type?.type || ""} business_type={userInfo.account_type?.business.type} saving = {savingUser}/>:''
                                }</form>
                            </>
                        }
                        <SuccessModal state = {[successPendingDetails, showSuccessPendingDetails]} message = {<>You have completed the sign up process.<br/>Please check your email for details.</>}/>
                    </section>:
                    <Skeleton variant="rect" animation="wave" style={{margin: "2rem 0", height: "50vh"}}/>
                }
                {
                    loading || savingUser &&
                    <section className="page-loader">
                        <span className="spinner-container">
                            <div className="spinner"></div>
                        </span>
                    </section>
                }
            </section>
            <ChangePlanModal />
        </MasterPage>
    </div>
})

export default UserDashboardPage;