import { Partner } from 'types/Partner';

export const ACTIVE_PARTNERS: Partner[] = [
    { code: 'autosweeprfid', display_name: 'AutoSweep RFID' },
    { code: 'bayadcenter', display_name: 'Bayad Center' },
    { code: 'beep', display_name: 'Beep Card' },
    { code: 'easytriprfid', display_name: 'EasyTrip RFID' },
];

export const GET_IP = `https://api.ipstack.com/check?access_key=${process.env.REACT_APP_IPAPI_KEY}`;
export const WEB_ASSETS       = `${process.env.REACT_APP_S3_WEB_ASSETS_URL}`;
export const GOV_ASSETS       = `${process.env.REACT_APP_S3_GOV_ASSETS_URL}`;
export const JUSTPAYTO_ASSETS = `${process.env.REACT_APP_S3_JUSTPAYTO_ASSETS_URL}`;