
import { RootState } from 'types';
import { initialState } from 'app/common/slice';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state: RootState) => state.general || initialState;

export const selectCountries = createSelector(
    [selectDomain],
    GeneralState => GeneralState.countries,
);
