import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo } from "react";
import 'styles/sass/signup.scss';
import { useStyles } from "../styles";

const ProcessingModal = memo(({state}: DefaultModalProps) => {
    const classes = useStyles();
    return (
        <Modal state={state} dialogClassname={classes.processingModal} style="progress_modal" disableCloseButton disableEscapeKeyDown disableBackdropClick>
            <section className="progress">
                <h3>Processing</h3>
                <section className="progress-bar">
                    <div className="progress-bar__indicator"></div>
                </section>
                {/* <p className="blue pointer cancel" data-cancel-progress onClick={() => dispatch(signUpActions.cancel())}>Cancel</p> */}
            </section>
        </Modal>
    )
})

export default ProcessingModal;