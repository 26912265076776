import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";

export interface BankDetails {
    email         : string
    bank          : string
    account_name  : string
    account_number: string
}

export interface BankVerificationState {
    confirmingDeposit?    : boolean
    bankDetails?          : BankDetails
    confirmDepositResults?: boolean
    confirmDepositError?  : string
}

export const initialState: BankVerificationState = {};

const bankVerificationSlice = createSlice({
    name: 'bankVerificationPage',
    initialState,
    reducers: {
        getBankDetails(state, action: PayloadAction<{username: string, key: string}>) {
        },
        setBankDetails(state, action: PayloadAction<BankDetails>) {
            state.bankDetails = action.payload;
        },
        confirmDeposit(state, action: PayloadAction<{username_email: string, amount: number}>) {
            state.confirmDepositError = "";
            state.confirmingDeposit = true;
        },
        confirmDepositResult(state, action: PayloadAction<boolean>) {
            state.confirmDepositResults = action.payload;
            state.confirmingDeposit = false;
        },
        confirmDepositError(state, action: PayloadAction<string>) {
            state.confirmDepositError = action.payload;
        }
    }
});

export const {
    actions: bankVerificationActions,
    reducer,
    name: sliceKey,
} = bankVerificationSlice;
