import { PayloadAction } from '@reduxjs/toolkit';
import { ANALYTICS, API_VERSION, BARCHART_TRANSACTIONS, CATEGORIES, CATEGORY, CHANGE_PLAN, CUSTOMIZATION, CUSTOMIZATIONS, DOCUMENT_UPLOAD, LAST_PAYOUT_DATE, LINE_GRAPHS, OVERVIEW, OVERVIEW_SALES, REACTIVATE, SEND_ACCOUNT_OTP, SETTINGS, SUBCATEGORIES, TRANSACTIONS, UPDATE_ACCOUNT, UPDATE_DELIVERY, UPDATE_STATUS, UPDATE_USER, UPLOAD_CLIENT_ASSET, VALIDATE_ACCOUNT_OTP } from 'app/common/core_api/resources';
import { GeneralComponent } from 'app/common/slice';
import moment, { Moment } from 'moment';
import {
    call,
    delay,
    put,
    select,
    takeLatest,
    takeLeading
} from 'redux-saga/effects';
import { RootState } from 'types/RootState';
import { dataURItoBlob } from 'utils';
import { ResponseError, request } from 'utils/request';
import { configHeaders } from 'utils/request-config';
import { ContactlessInfo, SocialMediaLinks } from '../Onboarding/types';
import { userDashboardPageActions } from './slice';
import { LineGraphFilter, MobileNumber, UpdateSettingsPayload } from './types';

const getAccessToken = (state: RootState) => state?.general?.auth?.access_token;
const getUsername    = (state: RootState) => state?.general?.user_info?.username;

export function* updateStatus(status, id) {
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({'id': id})
        }
        let url    = UPDATE_STATUS.replace(":status", status);
        let result = yield call(request, url, options);
    } catch (err) {
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
        console.log(err)
    }
}

export function* setTwoFAStatus(action: PayloadAction<string>) {
    updateStatus('twofa_status', action.payload);
}

export function* setSMSNotifStatus(action: PayloadAction<string>) {
    updateStatus('sms_status', action.payload);
}

export function* updateUser(action: PayloadAction<{username: string, data: object}>) {
    try {
        let getUser     = (state: RootState) => state?.general?.user_info;
        let user        = yield select(getUser)
        
        var options: RequestInit = {method: "POST"}
        const data: any = {...action.payload.data}
        if ("document_meta" in action.payload.data) {
            const documents          = {...(action.payload.data['document_meta'] as any)}
            const business_documents = [...documents.business_documents]
    
            delete documents.type
            delete documents.business_documents
    
            const url = DOCUMENT_UPLOAD.replace(':username', action.payload.username)
    
            const document_urls = {}
            for (const doc in documents) {
                if (documents[doc]) {
                    const uri  = documents[doc].file;
                    if (uri) {
                        const blob = dataURItoBlob(uri);
                        const data = new FormData()
                        data.append('blob', blob, `blob.${documents[doc].type}`);
                        options.body = data
                        document_urls[doc] = yield call(request, url.replace(':type', doc), options);
                    }
                }
            }
    
            const business_document_urls: any[] = []
            for (const i in business_documents) {
                if (business_documents[i]) {
                    const uri  = business_documents[i].file;
                    if (uri) {
                        const blob = dataURItoBlob(uri);
                        const data = new FormData()
                        data.append('blob', blob, `blob.${business_documents[i].type}`);
                        options.body = data
                        business_document_urls.push(yield call(request, url.replace(':type', 'business_documents'), options));
                    }
                }
            }
            data.document_meta = {
                ...(action.payload.data['document_meta'] as any),
                ...document_urls,
                business_documents: business_document_urls
            }
        }
        
        const access_token = yield select(getAccessToken);
        options = {
            method: "PUT",
            headers: configHeaders(access_token),
            body: JSON.stringify(data)
        }
        let url = UPDATE_USER.replace(":username", action.payload.username);
        yield call(request, url, options);
        yield put(GeneralComponent.getUserInfo())
        yield put(userDashboardPageActions.successUpdateUser())
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
        yield put(userDashboardPageActions.failedUpdateUser())
    }
}

export function* updateAccount(action: PayloadAction<{
    name?              : string,
    address?           : string,
    pickup_location?   : string,
    mobile_number?     : MobileNumber,
    description?       : string,
    profile_photo?     : string,
    background_url?    : string,
    social_media_links?: SocialMediaLinks,
    document_meta?     : any
    contactless?       : ContactlessInfo
}>) {
    try {
        const access_token       = yield select(getAccessToken)
        const getUser            = (state: RootState) => state?.general?.user_info
        const data               = {...action.payload}

        var options: RequestInit = {
            method : "PUT",
            headers: configHeaders(access_token),
            body   : JSON.stringify(data)
        }
        const new_user_info     = yield call(request, UPDATE_ACCOUNT, options);
        const getUserInfo       = (state: RootState) => state?.general?.user_info
        const current_user_info = yield select(getUserInfo)
        yield put(GeneralComponent.setUserInfo({
            ...current_user_info,
            ...new_user_info
        }))
        yield put(userDashboardPageActions.successUpdateUser())
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
        yield put(userDashboardPageActions.failedUpdateUser())
    }
}

export function* getTransactions(action: PayloadAction<{
    filter?: string,
    start?: string,
    end?: string
}>) {
    try {
        let url          = new URL(TRANSACTIONS),
            params       = yield select((state: RootState) => state.userDashboardPage?.transactionQuery),
            access_token = yield select(getAccessToken),
            options: RequestInit = {
                method : "GET",
                headers: configHeaders(access_token),
            }
        params = action.payload || params;
        switch (params.filter) {
            case 'last7days':
                url.searchParams.append('start', moment().subtract(6, 'days').format('YYYY-MM-DD'));
                url.searchParams.append('end', moment().format('YYYY-MM-DD'));
            break;
            case 'last20days':
                url.searchParams.append('start', moment().subtract(19, 'days').format('YYYY-MM-DD'));
                url.searchParams.append('end', moment().format('YYYY-MM-DD'));
            break;
            default:
                if (params.start && params.end) {
                    url.searchParams.append('start', params.start);
                    url.searchParams.append('end', params.end);
                }
        }
        let data = yield call(request, url.toString(), options)
        if (!data?.length) {
            yield put(userDashboardPageActions.loadTransactions([]))
        }
        yield put(userDashboardPageActions.loadTransactions(data))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
        yield put(userDashboardPageActions.loadTransactions([]))
    }
}

const createDays  = async days => Array.from({length: days}).map((_, i) => i).map(i => moment().subtract(i, 'days').format('YYYY-MM-DD')).reverse()
const createHours = async (days, day: string | undefined = undefined) => {
    const getDay = () => moment(day)
    var current_hour = 0
    var current_day  = days
    return Array.from({length: days*24+getDay().hours()+1}).map((_, i) => i).map(i => {
        current_hour = current_hour == 24? 0: current_hour
        const date = getDay().startOf('day').subtract(current_day, 'days').add(current_hour, 'hours').format('YYYY-MM-DD HH:mm')
        if (current_hour+1 == 24) {
            current_hour = 0
            current_day -= 1
        } else {
            current_hour += 1
        }
        return date
    })
}
const computeDays = (start: Moment, end: Moment) => end.diff(start, 'days') + 1

export function* loadLineGraphs(action: PayloadAction<{
    frequency?: string
    duration? : string
    date1?    : string
    date2?    : string
}>) {
    yield call(getGrossSales, action)
    yield call(getNewCustomers, action)
    yield call(getNetVolumes, action)
    yield call(getAvgRevenue, action)
    
    yield call(getCompletedTransactions, action)
    yield call(getVerifiedTransactions, action)
    yield call(getPendingTransactions, action)
    yield call(getOnHoldTransactions, action)
    yield call(getFailedTransactions, action)
}

const getDateRange = (payload: {frequency?: string, duration?: string, date?: string, startDate?: string, endDate?: string}) => {
    const {frequency, duration, date, startDate, endDate} = payload
    const now = moment()
    const end = (
        endDate? moment(endDate):
        !date? now :
        frequency == 'daily' ? moment(date).add(5, 'days'):
        frequency == 'hourly'? moment(date):
        now
    ).add(1, 'hour').format('YYYY-MM-DD')
    const quarter = Math.floor((now.month()-1)/3 + 1)
    const start_of_quarter = (quarter-1)*3+1
    const start = !date? (
        startDate? moment(startDate):
        duration == 'week'    ? now.subtract(1, 'week') :
        duration == '20 days' ? now.subtract(20, 'days'):
        duration == 'month'   ? now.subtract(30, 'days'):
        duration == 'year'    ? now.subtract(1, 'year') :
        duration == 'mtd'     ? now.set('date', 1) :
        duration == 'ytd'     ? now.set('date', 1).set('month', 1) :
        duration == 'qtd'     ? now.set('date', 1).set('month', start_of_quarter):
        now
    ).format('YYYY-MM-DD'): date
    return {start, end}
}

export const getLineGraphData = (transactions, service, payload: {
    frequency?: string
    duration? : string
    date1?    : string
    date2?    : string
}, no_compare?: boolean) => {
    const {frequency, duration, date1, date2} = payload;
    if (duration) {
        let {start, end} = getDateRange({frequency, duration, startDate: date1, endDate: date2})
        return service(transactions, start, end, frequency)
    } else if (!date1 && !date2) {
        let {start, end} = {
            start: moment(new Date(
                Math.min(
                  ...transactions.map(element => {
                    return new Date(element.timestamp);
                  }),
                ),
            )).format('YYYY-MM-DD'),
            end: moment(new Date(
                Math.max(
                  ...transactions.map(element => {
                    return new Date(element.timestamp);
                  }),
                ),
            )).format('YYYY-MM-DD'),
        }
        return service(transactions, start, end, frequency)
    } else if (!no_compare && date1 && date2) {
        if (frequency == 'hourly') {
            var start1 = moment(date1).format('YYYY-MM-DD 00:00:00'),
                end1   = moment(date1).format('YYYY-MM-DD 23:00:00'),
                start2 = moment(date2).format('YYYY-MM-DD 00:00:00'),
                end2   = moment(date2).format('YYYY-MM-DD 23:00:00')
            return [
                service(transactions, start1, end1, frequency),
                service(transactions, start2, end2, frequency),
            ]
        } else if (frequency == 'daily') {
            var start1 = moment(date1).format('YYYY-MM-DD'),
                end1   = moment(date1).add(5, 'days').format('YYYY-MM-DD'),
                start2 = moment(date2).format('YYYY-MM-DD'),
                end2   = moment(date2).add(5, 'days').format('YYYY-MM-DD ')
            return [
                service(transactions, start1, end1, frequency),
                service(transactions, start2, end2, frequency),
            ]
        }
    } else if (date1 && date2) {
        var start = moment(date1).format('YYYY-MM-DD'),
            end   = moment(date2).format('YYYY-MM-DD')
        return service(transactions, start, end, frequency)
    }
}

export function* getGrossSales(action: PayloadAction<{
    frequency?: string
    duration? : string
    date1?    : string
    date2?    : string
}>) {
    var getTransactions = (state: RootState) => state?.userDashboardPage?.transactions
    var response = yield select(getTransactions)

    if (!response) return;
    const service = (transactions, start, end) => {
        var startDate = moment(start),
            endDate   = moment(end),
            format    = (
                action.payload.frequency == 'daily'? 'YYYY-MM-DD':
                action.payload.frequency == 'hourly'? 'YYYY-MM-DD HH:00':
                'YYYY-MM-DD HH:mm:ss'
            )
        transactions = transactions.filter(t =>
            moment(t.timestamp).isBetween(startDate, endDate, 'date', '[]')
        )
        transactions = transactions.reduce((data, transaction) => {
            var date   = moment(transaction.timestamp).format(format),
                index  = data.findIndex(d => d.date == date)
            if (index !== undefined && index !== null && index != -1) {
                data[index].amount += (+transaction.amount)
            } else {
                data.push({date, amount: (+transaction.amount)})
            }
            return data
        }, [])
        return transactions
    }

    response = getLineGraphData(response, service, action.payload)
    try {
        const data = yield call(loadGrossSales, response, action.payload, true)
        yield put(userDashboardPageActions.loadGrossSales(data))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* getBarchartTransactions(action: PayloadAction<LineGraphFilter>) {
    let response,
        url         = new URL(BARCHART_TRANSACTIONS),
        accessToken = yield select(getAccessToken),
        options     = {headers: configHeaders(accessToken)}

    action.payload.date1 && url.searchParams.append('start', action.payload.date1)
    action.payload.date2 && url.searchParams.append('end', action.payload.date2)
    try {
        response = yield call(request, url.toString(), options);
    } catch (err) {
        let error = err as ResponseError;
        if (error.response?.status == 401) {
            throw error;
        }
        return console.log(err);
    }
    yield put(userDashboardPageActions.loadBarchartTransactions(response || []));    
}

export const loadGrossSales = async (response, payload, compare?: boolean) => {
    const {
        date1, date2,
        duration,
        frequency
    } = payload
    const days = (
        duration == 'week'    ? 7:
        duration == '20 days' ? 20:
        duration == 'month'   ? 30:
        duration == 'year'    ? 365:
        duration == 'mtd'     ? computeDays(moment().startOf('month'), moment()) :
        duration == 'qtd'     ? computeDays(moment().startOf('quarter'), moment()) :
        duration == 'ytd'     ? computeDays(moment().startOf('year'), moment()) :
        frequency == 'daily'  ? 5:
        frequency == 'hourly' ? 1:
        0
    )
    const data: any[] = []

    if (duration) {
        const creator = (
            frequency == 'daily'? createDays:
            frequency == 'hourly'? createHours:
            createDays
        )
        const data_days: any = await creator(days)
        for (const day of data_days) {
            const day_data = response?.find(r => r.date == day)
            data.push({
                date: day,
                Amount: (+day_data?.amount || 0)
            })
        }
    } else if (!date1 && !date2) {
        var startDay = moment(new Date(
                Math.min(
                ...response?.map(element => {
                    return new Date(element.date);
                }),
                ),
            )),
            endDay = moment(new Date(
                Math.max(
                ...response?.map(element => {
                    return new Date(element.date);
                }),
                ),
            ))
        const days: any[] = []
        const format = frequency == 'daily'? "YYYY-MM-DD": "YYYY-MM-DD HH:00"
        while (startDay.isSameOrBefore(endDay)) {
            days.push(startDay.format(format))
            startDay = startDay.add(1, 'day')
        }
        for (const day of days) {
            const day_data = response?.find(r => r.date == day)
            data.push({
                date: day,
                Amount: (+day_data?.amount || 0)
            })
        }
    } else if (compare) {
        if (frequency == 'hourly') {
            const data_days1 = Array.from(Array(24).keys()).map(i => moment(date1).startOf('day').add(i, 'hours').format('YYYY-MM-DD HH:mm'))
            const data_days2 = Array.from(Array(24).keys()).map(i => moment(date2).startOf('day').add(i, 'hours').format('YYYY-MM-DD HH:mm'))
            for (var i = 0; i < 24; i++) {
                data.push({
                    date1: data_days1[i],
                    date2: data_days2[i],
                    Amount1: +(response[0].find(r => r.date == data_days1[i])?.amount || 0),
                    Amount2: +(response[1].find(r => r.date == data_days2[i])?.amount || 0),
                })
            }
        } else if (frequency == 'daily') {
            for (var i = 0; i < 5; i++) {
                let firstDate  = moment(date1).add(i, 'days').format('YYYY-MM-DD')
                let secondDate = moment(date2).add(i, 'days').format('YYYY-MM-DD')
                data.push({
                    date1: firstDate,
                    date2: secondDate,
                    Amount1: +(response[0].find(r => r.date == firstDate)?.amount || 0),
                    Amount2: +(response[1].find(r => r.date == secondDate)?.amount || 0),
                })
            }
        }
    } else if (frequency == 'hourly') {
        var startDay = moment(moment(date1).format("YYYY-MM-DD 00:00:00")),
            endDay   = moment(moment(date2).format("YYYY-MM-DD HH:00:00"))
        const days: any[] = []
        while (startDay.isSameOrBefore(endDay)) {
            days.push(startDay.format("YYYY-MM-DD HH:00"))
            startDay = startDay.add(1, 'day')
        }
        for (const day of days) {
            const day_data = response?.find(r => r.date == day)
            data.push({
                date: day,
                Amount: +(day_data?.amount || 0)
            })
        }
    } else if (frequency == 'daily') {
        var startDay = moment(moment(date1).format("YYYY-MM-DD")),
            endDay   = moment(moment(date2).format("YYYY-MM-DD"))
        const days: any[] = []
        while (startDay.isSameOrBefore(endDay)) {
            days.push(startDay.format("YYYY-MM-DD"))
            startDay = startDay.add(1, 'day')
        }
        for (const day of days) {
            const day_data = response?.find(r => r.date == day)
            data.push({
                date: day,
                Amount: +(day_data?.amount || 0)
            })
        }
    }
    return data
}

export function* getNewCustomers(action: PayloadAction<{
    frequency?: string
    duration? : string
    date1?    : string
    date2?    : string
}>) {
    var getTransactions = (state: RootState) => state?.userDashboardPage?.transactions
    var response = yield select(getTransactions)

    if (!response) return;
    const service = (transactions, start, end) => {
        var startDate = moment(start),
            endDate   = moment(end),
            format    = (
                action.payload.frequency == 'daily'? 'YYYY-MM-DD':
                action.payload.frequency == 'hourly'? 'YYYY-MM-DD HH:00':
                'YYYY-MM-DD HH:mm:ss'
            )
        transactions = transactions.filter(t => 
            moment(t.timestamp).isBetween(startDate, endDate, 'date', '[]') &&
            t.is_new_user
        )
        transactions = transactions.reduce((data, transaction) => {
            var date   = moment(transaction.timestamp).format(format),
                index  = data.findIndex(d => d.date == date)
            if (index !== undefined && index !== null && index != -1) {
                data[index].count += 1
            } else {
                data.push({date, count: 1})
            }
            return data
        }, [])
        return transactions
    }

    response = getLineGraphData(response, service, action.payload)

    try {
        const {
            date1, date2,
            duration,
            frequency
        } = action.payload
        const days = (
            duration == 'week' ? 8:
            duration == 'month'? 31:
            duration == 'year' ? 365:
            duration == 'mtd'  ? computeDays(moment().startOf('month'), moment()) :
            duration == 'qtd'  ? computeDays(moment().startOf('quarter'), moment()) :
            duration == 'ytd'  ? computeDays(moment().startOf('year'), moment()) :
            0
        )
        const data: any[] = []
        if (duration) {
            const creator = (
                frequency == 'daily'? createDays:
                frequency == 'hourly'? createHours:
                createDays
            )
            const data_days   = yield call(creator, days)
            for (const day of data_days) {
                const day_data = response?.find(r => r.date == day)
                data.push({
                    date: day,
                    Customers: day_data?.count || 0
                })
            }
        } else if (frequency == 'hourly') {
            const data_days1 = Array.from(Array(24).keys()).map(i => moment(date1).startOf('day').add(i, 'hours').format('YYYY-MM-DD HH:mm'))
            const data_days2 = Array.from(Array(24).keys()).map(i => moment(date2).startOf('day').add(i, 'hours').format('YYYY-MM-DD HH:mm'))
            for (var i = 0; i < 24; i++) {
                data.push({
                    date1: data_days1[i],
                    date2: data_days2[i],
                    Customers1: response[0].find(r => r.date == data_days1[i])?.count || 0,
                    Customers2: response[1].find(r => r.date == data_days2[i])?.count || 0,
                })
            }
        } else if (frequency == 'daily') {
            for (var i = 0; i < 5; i++) {
                let firstDate  = moment(date1).add(i, 'days').format('YYYY-MM-DD')
                let secondDate = moment(date2).add(i, 'days').format('YYYY-MM-DD')
                data.push({
                    date1: firstDate,
                    date2: secondDate,
                    Customers1: response[0].find(r => r.date == firstDate)?.count || 0,
                    Customers2: response[1].find(r => r.date == secondDate)?.count || 0,
                })
            }
        }
        yield put(userDashboardPageActions.loadNewCustomers(data))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

function* countTransactions(put_action, action, status = '') {
    var getTransactions = (state: RootState) => state?.userDashboardPage?.transactions
    var response = yield select(getTransactions)

    if (!response) return;
    const service = (transactions, start, end) => {
        var startDate = moment(start),
            endDate   = moment(end),
            format    = (
                action.payload.frequency == 'daily'? 'YYYY-MM-DD':
                action.payload.frequency == 'hourly'? 'YYYY-MM-DD HH:00':
                'YYYY-MM-DD HH:mm:ss'
            )
        transactions = transactions.filter(t => 
            moment(t.timestamp).isBetween(startDate, endDate, 'date', '[]') &&
            (
                (status == 'on_hold' && t.on_hold) ||
                (status == '' || t.status == status)
            )
        )
        transactions = transactions.reduce((data, transaction) => {
            var date   = moment(transaction.timestamp).format(format),
                index  = data.findIndex(d => d.date == date)
            if (index !== undefined && index !== null && index != -1) {
                data[index].transactions += 1
            } else {
                data.push({date, transactions: 1})
            }
            return data
        }, [])
        return transactions
    }

    response = getLineGraphData(response, service, action.payload)

    try {
        const {
            date1, date2,
            duration,
            frequency
        } = action.payload
        const days = (
            duration == 'week' ? 8:
            duration == 'month'? 31:
            duration == 'year' ? 365:
            duration == 'mtd'  ? computeDays(moment().startOf('month'), moment()) :
            duration == 'qtd'  ? computeDays(moment().startOf('quarter'), moment()) :
            duration == 'ytd'  ? computeDays(moment().startOf('year'), moment()) :
            0
        )
        const data: any[] = []
        
        if (duration) {
            const creator = (
                frequency == 'daily'? createDays:
                frequency == 'hourly'? createHours:
                createDays
            )
            const data_days   = yield call(creator, days)
            for (const day of data_days) {
                const day_data = response?.find(r => r.date == day)
                data.push({
                    date: day,
                    Count: day_data?.transactions || 0
                })
            }
        } else if (frequency == 'hourly') {
            const data_days1 = Array.from(Array(24).keys()).map(i => moment(date1).startOf('day').add(i, 'hours').format('YYYY-MM-DD HH:mm'))
            const data_days2 = Array.from(Array(24).keys()).map(i => moment(date2).startOf('day').add(i, 'hours').format('YYYY-MM-DD HH:mm'))
            for (var i = 0; i < 24; i++) {
                data.push({
                    date1: data_days1[i],
                    date2: data_days2[i],
                    Count1: response[0].find(r => r.date == data_days1[i])?.transactions || 0,
                    Count2: response[1].find(r => r.date == data_days2[i])?.transactions || 0,
                })
            }
        } else if (frequency == 'daily') {
            for (var i = 0; i < 5; i++) {
                let firstDate  = moment(date1).add(i, 'days').format('YYYY-MM-DD')
                let secondDate = moment(date2).add(i, 'days').format('YYYY-MM-DD')
                data.push({
                    date1: firstDate,
                    date2: secondDate,
                    Count1: response[0].find(r => r.date == firstDate)?.transactions || 0,
                    Count2: response[1].find(r => r.date == secondDate)?.transactions || 0,
                })
            }
        }
        yield put(put_action(data))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export const getNetVolumes            = (action: PayloadAction<LineGraphFilter>) => countTransactions(userDashboardPageActions.loadNetVolumes, action)
export const getCompletedTransactions = (action: PayloadAction<LineGraphFilter>) => countTransactions(userDashboardPageActions.loadCompletedTransaction, action, 'complete')
export const getVerifiedTransactions  = (action: PayloadAction<LineGraphFilter>) => countTransactions(userDashboardPageActions.loadVerifiedTransactions, action, 'verified')
export const getPendingTransactions   = (action: PayloadAction<LineGraphFilter>) => countTransactions(userDashboardPageActions.loadPendingTransactions , action, 'pending_payment')
export const getOnHoldTransactions    = (action: PayloadAction<LineGraphFilter>) => countTransactions(userDashboardPageActions.loadOnHoldTransactions  , action, 'on_hold')
export const getFailedTransactions    = (action: PayloadAction<LineGraphFilter>) => countTransactions(userDashboardPageActions.loadFailedTransactions  , action, 'failed')

export function* getAvgRevenue(action: PayloadAction<{
    frequency?: string
    duration? : string
    date1?    : string
    date2?    : string
}>) {
    var getTransactions = (state: RootState) => state?.userDashboardPage?.transactions
    var response = yield select(getTransactions)

    if (!response) return;
    const service = (transactions, start, end) => {
        var startDate = moment(start),
            endDate   = moment(end),
            format    = (
                action.payload.frequency == 'daily'? 'YYYY-MM-DD':
                action.payload.frequency == 'hourly'? 'YYYY-MM-DD HH:00':
                'YYYY-MM-DD HH:mm:ss'
            )
        transactions = transactions.filter(t => 
            moment(t.timestamp).isBetween(startDate, endDate, 'date', '[]') &&
            t.status == 'complete'
        )
        transactions = transactions.reduce((data, transaction) => {
            var date   = moment(transaction.timestamp).format(format),
                index  = data.findIndex(d => d.date == date)
            if (index !== undefined && index !== null && index != -1) {
                data[index].count += 1
                data[index].amount += (+transaction.amount)
            } else {
                data.push({date, count: 1, amount: (+transaction.amount)})
            }
            return data
        }, [])
        return transactions
    }

    response = getLineGraphData(response, service, action.payload)

    try {
        const {
            date1, date2,
            duration,
            frequency
        } = action.payload
        const days = (
            duration == 'week' ? 8:
            duration == 'month'? 31:
            duration == 'year' ? 365:
            duration == 'mtd'  ? computeDays(moment().startOf('month'), moment()) :
            duration == 'qtd'  ? computeDays(moment().startOf('quarter'), moment()) :
            duration == 'ytd'  ? computeDays(moment().startOf('year'), moment()) :
            0
        )
        const data: any[] = []
        
        if (duration) {
            const creator = (
                frequency == 'daily'? createDays:
                frequency == 'hourly'? createHours:
                createDays
            )
            const data_days   = yield call(creator, days)
            for (const day of data_days) {
                const day_data = response?.find(r => r.date == day)
                const average  = day_data? +day_data.amount/day_data.count: 0
                data.push({
                    date: day,
                    Amount: average || 0
                })
            }
        } else if (frequency == 'hourly') {
            const data_days1 = Array.from(Array(24).keys()).map(i => moment(date1).startOf('day').add(i, 'hours').format('YYYY-MM-DD HH:mm'))
            const data_days2 = Array.from(Array(24).keys()).map(i => moment(date2).startOf('day').add(i, 'hours').format('YYYY-MM-DD HH:mm'))
            for (var i = 0; i < 24; i++) {
                var data1 = response[0].find(r => r.date == data_days1[i]),
                    data2 = response[1].find(r => r.date == data_days2[i]),
                    average1 = data1? (+data1.amount/data1.count): 0,
                    average2 = data2? (+data2.amount/data2.count): 0
                data.push({
                    date1: data_days1[i],
                    date2: data_days2[i],
                    Amount1: average1 || 0,
                    Amount2: average2 || 0,
                })
            }
        } else if (frequency == 'daily') {
            for (var i = 0; i < 5; i++) {
                let firstDate  = moment(date1).add(i, 'days').format('YYYY-MM-DD')
                let secondDate = moment(date2).add(i, 'days').format('YYYY-MM-DD')
                var data1 = response[0].find(r => r.date == firstDate),
                    data2 = response[1].find(r => r.date == secondDate),
                    average1 = data1? (+data1.amount/data1.count): 0,
                    average2 = data2? (+data2.amount/data2.count): 0
                data.push({
                    date1: firstDate,
                    date2: secondDate,
                    Amount1: average1 || 0,
                    Amount2: average2 || 0,
                })
            }
        }
        yield put(userDashboardPageActions.loadAvgRevenues(data))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* updateSettings(action: PayloadAction<UpdateSettingsPayload>) {
    const url = SETTINGS
    const access_token = yield select(getAccessToken)
    const options: RequestInit = {
        method : 'PUT',
        headers: configHeaders(access_token),
        body   : JSON.stringify(action.payload)
    }
    
    try {
        yield call(request, url, options)
        yield put(userDashboardPageActions.settingsUpdated())
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        } else {
            yield put(userDashboardPageActions.settingsUpdated())
        }
    }
}

export function* getLastPayoutDate() {
    const url = LAST_PAYOUT_DATE
    const access_token = yield select(getAccessToken)
    const options: RequestInit = {
        headers: {'Authorization': `Bearer ${access_token}`}
    }

    try {
        const response  = yield call(request, url, options)
        yield put(userDashboardPageActions.setLastPayoutDate(response))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* updateDelivery(payload_action: PayloadAction<{transactionNo: string, action: string}>) {
    const {transactionNo, action} = payload_action.payload;
    const url                     = UPDATE_DELIVERY.replace(":id", transactionNo).replace(":action", action)
    const access_token            = yield select(getAccessToken)
    const options: RequestInit    = {
        method : "POST",
        headers: {'Authorization': `Bearer ${access_token}`}
    }

    try {
        yield call(request, url, options);
        yield put(userDashboardPageActions.getTransactions());
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* sendOtp(action: PayloadAction<string>) {
    const url                  = SEND_ACCOUNT_OTP
    const access_token         = yield select(getAccessToken)
    const options: RequestInit = {
        method : "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type' : 'application/json'
        },
        body   : JSON.stringify({action: action.payload})
    }
    
    try {
        yield call(request, url, options)
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* validateOtp(action: PayloadAction<{
    otp      : string,
    action   : string,
    callback?: (data?: any) => void
}>) {
    const url                  = VALIDATE_ACCOUNT_OTP
    const access_token         = yield select(getAccessToken)
    const options: RequestInit = {
        method : "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type' : 'application/json'
        },
        body   : JSON.stringify({
            action: action.payload.action,
            otp   : action.payload.otp,
        })
    }
    
    try {
        const result = yield call(request, url, options)
        if (action.payload.callback) {
            action.payload.callback(result)
        }
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
        else if (action.payload.callback) {
            const error = err as ResponseError
            action.payload.callback(error.response.status)
        }
    }
}

export function* reactivate(action: PayloadAction<(data?: any) => void>) {
    const url                  = REACTIVATE
    const access_token         = yield select(getAccessToken)
    const options: RequestInit = {
        method : "POST",
        headers: {'Authorization': `Bearer ${access_token}`},
    }
    
    try {
        const result = yield call(request, url, options)
        if (action.payload) {
            action.payload(result)
        }
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        } else if (action.payload) {
            const error = err as ResponseError
            action.payload(error.response.status)
        }
    }
}

export function* getCustomization() {
    const access_token = yield select(getAccessToken)
    const username     = yield select(getUsername)
    const url          = CUSTOMIZATIONS.replace(":username", username)
    const options: RequestInit = {
        headers: {'Authorization': `Bearer ${access_token}`}
    }
    
    try {
        const result = yield call(request, url, options)
        yield put(userDashboardPageActions.loadCustomization(result))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* updateCustomization(action: PayloadAction<any[]>) {
    const access_token = yield select(getAccessToken)
    const username     = yield select(getUsername)
    const url          = CUSTOMIZATION.replace(":username", username)
    const options: RequestInit = {
        method : "PUT",
        headers: configHeaders(access_token)
    }
    
    try {
        for (const field of action.payload) {
            // Upload image for `img_url` and get url
            if (field.field_value.img_url) {
                field.field_value.img_url = yield call(upload_img_url, username, field)
            }
            let type = field.field_value.type
            // Build Request Body
            options.body = JSON.stringify({field_value:
                typeof field.field_value === 'object'?
                JSON.stringify(field.field_value):
                field.field_value
            })
            // Update Field
            yield call(request, url.replace(":field_key", field.field_key), options)
            // Update Categories
            if (type == 'multiple') {
                const getCategories = (state: RootState) => state?.userDashboardPage?.categories;
                const categories    = yield select(getCategories)
                
                for (const category of categories) {
                    options.body = JSON.stringify(category)
                    const url = (
                        CATEGORY
                        .replace(":username", username)
                        .replace(":category", category.id)
                    )
                    yield call(request, url, options)
                }
            }
        }
        yield put(userDashboardPageActions.savedCustomization())
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* upload_img_url(username, field) {
    // Get Access Token
    const access_token = yield select(getAccessToken)
    // Build Request URL
    const url = UPLOAD_CLIENT_ASSET
        .replace(":username", username)
        .replace(":field_key", field.field_key)
    // Build Request Options & Headers
    const options: RequestInit = {
        method: "POST",
        headers: {Authorization: `Bearer ${access_token}`}
    }
    // Build Request Body
    try {
        const extension = field.field_value.img_url.split(';')[0].split('/')[1]
        const data      = new FormData()
        const asset     = dataURItoBlob(field.field_value.img_url);
        data.append('asset', asset, `asset.${extension}`);
        options.body = data
    } catch (e) {
        return field.field_value.img_url
    }
    
    // Upload File
    try {
        return yield call(request, url, options)
    } catch (err) {
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        } else {
            return field.field_value.img_url
        }
    }
}

export function* getCategories() {
    const access_token = yield select(getAccessToken)
    const username     = yield select(getUsername)
    const url          = CATEGORIES.replace(":username", username)
    const options: RequestInit = {
        headers: {'Authorization': `Bearer ${access_token}`}
    }
    
    try {
        const result = yield call(request, url, options)
        yield put(userDashboardPageActions.setCategories(result))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        } else {
            yield put(userDashboardPageActions.setCategories([]))
        }
    }
}

export function* getApiVersion() {
    const access_token = yield select(getAccessToken)
    const username     = yield select(getUsername)
    const url          = API_VERSION.replace(":username", username)
    const options: RequestInit = {
        headers: {'Authorization': `Bearer ${access_token}`}
    }
    
    try {
        const result = yield call(request, url, options)
        yield put(userDashboardPageActions.setApiVersion(result))
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* addCategories(action: PayloadAction<any[]>) {
    const access_token = yield select(getAccessToken)
    const username     = yield select(getUsername)
    const url          = CATEGORIES.replace(":username", username)
    const options: RequestInit = {
        method : "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type' : "application/json"
        }
    }
    
    try {
        for (const category of action.payload) {
            options.body = JSON.stringify(category)
            yield call(request, url, options)
        }
        yield put(userDashboardPageActions.savedMultiInput())
        yield put(userDashboardPageActions.getCategories())
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        } else {
            yield put(userDashboardPageActions.getCategories())
        }
    }
}

export function* addSubcategories(action: PayloadAction<any[]>) {
    const access_token = yield select(getAccessToken)
    const username     = yield select(getUsername)
    const url          = SUBCATEGORIES.replace(":username", username)
    const options: RequestInit = {
        method : "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type' : "application/json"
        }
    }
    const existingSubcategory: any[] = []
    for (const category of action.payload) {
        options.body = JSON.stringify(category)
        try {
            yield call(request, url, options)
        } catch (err) {
            const error = err as ResponseError
            if (error.response.status == 401) {
                throw error;
            } else {
                const error = JSON.parse((err as ResponseError).data)['detail']
                if (error == "Subcategory already exists") {
                    existingSubcategory.push(category.biller_name)
                }
            }
        }
    }
    if (existingSubcategory.length > 0) {
        yield put(userDashboardPageActions.failedMultiInput(existingSubcategory))
    }
    yield put(userDashboardPageActions.savedMultiInput())
}

export function* removeCategories() {
    const getCategories = (state: RootState) => state.userDashboardPage?.categories
    const categories   = (yield select(getCategories))?.filter(cat => cat.checked) || []
    const access_token = yield select(getAccessToken)
    const username     = yield select(getUsername)
    const url          = CATEGORY.replace(":username", username)
    const options: RequestInit = {
        method : "DELETE",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type' : "application/json"
        }
    }
    
    try {
        for (const category of categories) {
            yield call(request, url.replace(':category', category.id), options)
            yield put(userDashboardPageActions.removedCategories())
        }
        yield put(userDashboardPageActions.getCategories())
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        } else {
            yield put(userDashboardPageActions.getCategories())
        }
    }
}

export function* changePlan(action: PayloadAction<string>) {
    const payment_method = action.payload
    const access_token = yield select(getAccessToken)
    const options: RequestInit = {
        method : "POST",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type' : "application/json"
        },
        body: JSON.stringify({payment_method})
    }
    
    try {
        yield call(request, CHANGE_PLAN, options)
        yield put(GeneralComponent.getUserInfo())
    } catch (err) {
        console.log(err)
        const error = err as ResponseError
        if (error.response.status == 401) {
            throw error;
        }
    }
}

export function* getOverview() {
    yield delay(1500);
    try {
        const access_token = yield select(getAccessToken)
        const options     = {headers: configHeaders(access_token)}
        const overview    = yield call(request, OVERVIEW, options)
        yield put(userDashboardPageActions.setOverview(overview))
    } catch (err) {
        const error = err as ResponseError
        if (error?.response?.status == 401) {
            throw error;
        }
    }
}

export function* getSales() {
    try {
        const access_token = yield select(getAccessToken)
        const options      = {headers: configHeaders(access_token)}
        const response     = yield call(request, OVERVIEW_SALES, options)
        yield put(userDashboardPageActions.setSales(response))
    } catch (err) {
        const error = err as ResponseError
        if (error?.response?.status == 401) {
            throw error;
        }
    }
}

export function* getAnalytics() {
    try {
        const access_token = yield select(getAccessToken)
        const options      = {headers: configHeaders(access_token)}
        const response     = yield call(request, ANALYTICS, options)
        yield put(userDashboardPageActions.setAnalytics(response))
    } catch (err) {
        const error = err as ResponseError
        if (error?.response?.status == 401) {
            throw error;
        }
    }
}

export function* getLineGraphs(action: PayloadAction<LineGraphFilter>) {
    const graphs = [
        'gross-sales',
        'new-customers',
        'net-volumes',
        'avg-revenues',
        'completed',
        'verified',
        'pending',
        'hold',
        'failed'
    ];
    let options = {headers: configHeaders(yield select(getAccessToken))};
    for (const graph of graphs) {
        let response, url = new URL(LINE_GRAPHS.replace(':graph', graph));
        action.payload.frequency && url.searchParams.append('frequency', action.payload.frequency);
        action.payload.date1 && url.searchParams.append('start', action.payload.date1);
        action.payload.date2 && url.searchParams.append('end', action.payload.date2);
        
        try {
            response = yield call(request, url.toString(), options);
        } catch (err) {
            let error = err as ResponseError;
            if (error.response?.status == 401) {
                throw error;
            }
            response = undefined;
            console.log(err)
        }
        if (response) {
            switch (graph) {
                case 'gross-sales'  : yield put(userDashboardPageActions.loadGrossSales(response)); break;
                case 'new-customers': yield put(userDashboardPageActions.loadNewCustomers(response)); break;
                case 'net-volumes'  : yield put(userDashboardPageActions.loadNetVolumes(response)); break;
                case 'avg-revenues' : yield put(userDashboardPageActions.loadAvgRevenues(response)); break;
                case 'completed'   : yield put(userDashboardPageActions.loadCompletedTransaction(response)); break;
                case 'verified'     : yield put(userDashboardPageActions.loadVerifiedTransactions(response)); break;
                case 'pending'      : yield put(userDashboardPageActions.loadPendingTransactions(response)); break;
                case 'hold'         : yield put(userDashboardPageActions.loadOnHoldTransactions(response)); break;
                case 'failed'       : yield put(userDashboardPageActions.loadFailedTransactions(response)); break;
            }
        }
    }
}

export const refreshTokenOnFail = (func) => function*(action: PayloadAction<any>) {
    try {
        yield call(func, action);
    } catch (err) {
        let error = err as ResponseError;
        if (error.response?.status == 401) {
            // Refresh Token
            yield call(GeneralComponent.refreshToken, undefined);
            try {
                // Retry
                yield call(func, action);
            } catch {
                // Force Logout
                yield put(userDashboardPageActions.error('idle'));
                yield put(GeneralComponent.removeAuth());
                yield put(GeneralComponent.setUserInfo());
                localStorage.removeItem('auth');
            }
        } else {
            throw err;
        }
    }
}

export function* userDashboardSaga() {
    yield takeLatest(userDashboardPageActions.setTwoFAStatus.type, refreshTokenOnFail(setTwoFAStatus));
    yield takeLatest(userDashboardPageActions.setSMSNotifStatus.type, refreshTokenOnFail(setSMSNotifStatus));
    yield takeLatest(userDashboardPageActions.updateUser.type, refreshTokenOnFail(updateUser));
    yield takeLatest(userDashboardPageActions.updateAccount.type, refreshTokenOnFail(updateAccount));
    yield takeLatest(userDashboardPageActions.getTransactions.type, refreshTokenOnFail(getTransactions));
    yield takeLatest(userDashboardPageActions.loadLineGraphs.type, refreshTokenOnFail(loadLineGraphs));
    yield takeLatest(userDashboardPageActions.getBarchartTransactions.type, refreshTokenOnFail(getBarchartTransactions));
    
    yield takeLatest(userDashboardPageActions.updateSettings.type, refreshTokenOnFail(updateSettings));
    yield takeLatest(userDashboardPageActions.getLastPayoutDate.type, refreshTokenOnFail(getLastPayoutDate));
    yield takeLatest(userDashboardPageActions.updateDelivery.type, refreshTokenOnFail(updateDelivery));

    yield takeLatest(userDashboardPageActions.sendOtp.type, refreshTokenOnFail(sendOtp));
    yield takeLatest(userDashboardPageActions.validateOtp.type, refreshTokenOnFail(validateOtp));
    yield takeLatest(userDashboardPageActions.reactivate.type, refreshTokenOnFail(reactivate));
    
    yield takeLatest(userDashboardPageActions.getCustomization.type, refreshTokenOnFail(getCustomization));
    yield takeLatest(userDashboardPageActions.updateCustomization.type, refreshTokenOnFail(updateCustomization));
    yield takeLatest(userDashboardPageActions.getCategories.type, refreshTokenOnFail(getCategories));
    yield takeLatest(userDashboardPageActions.addCategories.type, refreshTokenOnFail(addCategories));
    yield takeLatest(userDashboardPageActions.addSubcategories.type, refreshTokenOnFail(addSubcategories));
    yield takeLatest(userDashboardPageActions.removeCategories.type, refreshTokenOnFail(removeCategories));

    yield takeLatest(userDashboardPageActions.getApiVersion.type, refreshTokenOnFail(getApiVersion));

    yield takeLatest(userDashboardPageActions.changePlan.type, refreshTokenOnFail(changePlan));
    yield takeLeading(userDashboardPageActions.getOverview.type, refreshTokenOnFail(getOverview));
    yield takeLatest(userDashboardPageActions.getSales.type, refreshTokenOnFail(getSales));
    yield takeLatest(userDashboardPageActions.getAnalytics.type, refreshTokenOnFail(getAnalytics));
    
    yield takeLatest(userDashboardPageActions.getLineGraphs.type, refreshTokenOnFail(getLineGraphs));
}