import React, { memo } from "react";
import BarChart from "./BarChart";
import LineGraphs from "./LineGraphs";
import SalesCharts from "./api_clients/SalesCharts";
import ClientLineGraphs from "./api_clients/LineGraphs";
import { useSelector } from "react-redux";
import { selectUserInfo } from "app/common/auth/selectors";

const Analytics = memo(() => {
    const userInfo = useSelector(selectUserInfo);

    return <>
        <section jpt-panel="reports" className="analytics">
            <section className="divider"><span>ANALYTICS</span></section>
            {userInfo?.username == 'lotto'? <>
                <SalesCharts />
                <ClientLineGraphs
                    type           = 'cash_in'
                    name           = 'Cash-In'
                    feePercent     = {1}
                    fixedFee       = {10}
                    revenuePercent = {2}
                    fixedRevenue   = {10}
                />
                <ClientLineGraphs
                    type           = 'cash_out'
                    name           = 'Cash-Out'
                    feePercent     = {1}
                    fixedFee       = {10}
                    revenuePercent = {3}
                    fixedRevenue   = {15}
                />
            </>: <>
                <BarChart />
                <LineGraphs />
            </>}
        </section>
    </>
});

export default Analytics;