import { createSelector } from '@reduxjs/toolkit';
import { RootState }      from 'types';
import { initialState }   from './slice';

const selectDomain = (state: RootState) =>
    state.userDashboardPage || initialState;

export const selectUserDashboard = createSelector(
    [selectDomain],
    userDashboardPage => userDashboardPage,
);
