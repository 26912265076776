import { Grid, Paper } from "@material-ui/core";
import Modal from "app/components/Modal";
import React, { memo, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import ButtonLoader from "app/components/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "app/common/auth/selectors";
import { userDashboardPageActions } from "../../slice";
import { selectUserDashboard } from "../../selectors";
import PremiumPaymentMethodModal from "./PremiumPaymentMethodModal";
import CongratulationsModal from "./CongratulationsModal";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position  : "relative",
            maxWidth  : "fit-content",
            padding   : "1rem 0",
            ["& > .close"]: {
                zIndex  : 10,
                position: "absolute",
                top     : "13px",
                right   : "13px",
                margin  : 0,
                padding : 0,
                ["&:before"]: {
                    fontFamily: "fontawesome",
                    content   : '"\\f057"',
                    color     : "#ccc",
                    transform : "translateY(-50%)",
                    fontSize  : "24px",
                }
            }
        },
        header: {
            fontWeight: 600,
            margin: "2rem"
        },
        content: {
            textAlign: "center"
        },
        gridContainer: {
            padding: "0 3rem",
            marginBottom: "2rem"
        },
        accountTypeGrid: {
            padding: "0 0.5rem"
        },
        accountTypeContainer: {
            width   : "350px",
            maxWidth: "100%",
            overflow: "hidden"
        },
        accountTypeHeader: {
            color: "#FFFFFF",
            backgroundColor: "#009CDE",
            padding: "1rem 0 0.75rem",
            "& h3": {
                color: "inherit",
                marginBottom: "0.5rem"
            },
            "& small": {
                opacity: "0.9",
                fontSize: "12px",
                fontStyle: "italic",
            }
        },
        accountTypeContent: {
            padding: "1.5rem 1.5rem 1rem",
            lineHeight: "1.25rem",
            fontSize: "12px",
            color: "#8E8E91",
            "& header": {
                color: "#484848",
                fontWeight: 600,
                marginBottom: "1rem"
            },
            "& hr": {
                margin: "1.75rem 0"
            },
            "& footer": {
                fontStyle: "italic",
                fontSize: "12px",
                letterSpacing: "-0.25px",
                padding: "0 1rem",
                "& div": {
                    marginTop: "2.25rem"
                }
            },
        },
        button: {
            border       : "#ccc 1px solid",
            color        : "#777777",
            fontSize     : "14px",
            display      : "block",
            width        : "100%",
            minHeight    : "40px",
            marginTop    : "1.25rem",
            marginBottom : "0.75rem",
            letterSpacing: "1px",
            textTransform: "uppercase"
        }
    })
)

interface ChangePlanButtonProps {
    loading?: boolean
    onClick : () => void
}

const ChangePlanButton = memo(({onClick, loading}: ChangePlanButtonProps) => {
    const classes  = useStyles();
    const user     = useSelector(selectUserInfo);

    return (
        <ButtonLoader hasLoader
            loading = {loading}
            onClick = {onClick}
            style   = {`filled blue ${classes.button}`}
            text    = "Change Plan"
        />)
})

const CurrentPlanButton = memo(() => {
    const classes = useStyles();
    return (
        <ButtonLoader disabled
            style = {classes.button}
            text  = "Current Plan"
        />
    )
})

const ChangePlanModal = memo(() => {
    const dispatch      = useDispatch();
    const classes       = useStyles();
    const user          = useSelector(selectUserInfo);
    const userDashboard = useSelector(selectUserDashboard);
    const isPremium     = user?.groups?.includes("Premium")

    const [open, setOpen]       = useState(!!userDashboard.changePlanModal);
    const [success, setSuccess] = useState(false);
    const [loaded, setLoaded]   = useState<any[]>([]);
    const [paymentMethod, setPaymentMethod] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && !isPremium && user?.premium_balance && user?.premium_balance > 0) {
            setPaymentMethod(true)
            setOpen(false)
        }
    }, [open])

    useEffect(() => {
        if (loaded.length > 0) {
            setOpen(!open)
        }
    }, [userDashboard.changePlanModal])
    useEffect(() => {
        setLoading(false)
        setLoaded(user?.groups || [])
        if (loaded.length > 0 && JSON.stringify(user?.groups) != JSON.stringify(loaded)) {
            setOpen(false)
            setSuccess(true)
            setPaymentMethod(false)
        }
    }, [user?.groups])

    const onClick = () => {
        dispatch(userDashboardPageActions.updateActionTime())
        if (isPremium) {
            setLoading(true)
            dispatch(userDashboardPageActions.changePlan(""))
        } else {
            setPaymentMethod(true);
            setOpen(false);
        }
    }

    return <>
        <PremiumPaymentMethodModal state={[paymentMethod, setPaymentMethod]}/>
        <CongratulationsModal state={[success, setSuccess]} message={`You successfully ${isPremium? "updated": "downgraded"} your account to ${isPremium? "Premium": "Standard"}`}/>
        <Modal state={[open, setOpen]} modalClassname={`${classes.modal}`}>
            <div className={classes.content}>
                <h2 className={classes.header}>Change Plan</h2>
                <Grid container justifyContent="center" className={classes.gridContainer}>
                    <Grid item xs={6} className={classes.accountTypeGrid}>
                        <Paper className={classes.accountTypeContainer}>
                            <div className={classes.accountTypeHeader}>
                                <h3>Standard</h3>
                                <small>Free</small>
                            </div>
                            <div className={classes.accountTypeContent}>
                                <header>
                                    Accept payments easily.<br/>
                                    Receive money conveniently.
                                </header>
                                Payment Address<br/>
                                Payment Page<br/>
                                Accept any payment methods<br/>
                                Various fulfillment methods<br/>
                                Real-time notifications<br/>
                                Weekly Settlement<br/>
                                QR Code<br/>
                                Pay Button<br/>
                                <footer>
                                    <div>*standard bank fees per transaction.</div>
                                </footer>
                                {
                                    user?.groups?.includes("Standard")?
                                    <CurrentPlanButton/>:
                                    <ChangePlanButton onClick={onClick} loading={loading}/>
                                }
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} className={classes.accountTypeGrid}>
                        <Paper className={classes.accountTypeContainer}>
                            <div className={classes.accountTypeHeader}>
                                <h3>Premium</h3>
                                <small>₱ 1,000.00 / month</small>
                            </div>
                            <div className={classes.accountTypeContent}>
                                <header>
                                    More features. More options.<br/>
                                    Receive money faster.
                                </header>
                                <section>
                                    Standard Plan plus:<br/>
                                    Real-time deposits<br/>
                                    Sales analytics & detail reports<br/>
                                    Enable/Disable SMS Notifications<br/>
                                    Pass on fee to customer<br/>
                                    Choose payment & fulfillment methods<br/>
                                </section>
                                <hr/>
                                <footer>
                                    ₱ 1,000.00 will be deducted to the first payment amount of each month
                                </footer>
                                {
                                    user?.groups?.includes("Premium")?
                                    <CurrentPlanButton/>:
                                    <ChangePlanButton onClick={onClick}/>
                                }
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    </>
})

export default ChangePlanModal