import { PayloadAction } from '@reduxjs/toolkit';
import {
    CHECK_EMAIL,
    CREATE_ACCOUNT,
    DEPOSITORY_SERVICE,
    DOCUMENT_UPLOAD,
    SEND_REGISTRATION_OTP,
    SUGGESTED_USERNAMES,
    USERNAME_EXISTS,
    VERIFY_CODES
} from 'app/common/core_api/resources';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from 'types';
import { dataURItoBlob } from 'utils';
import { ResponseError, request } from 'utils/request';
import { signUpActions } from './slice';

var controller = new AbortController();
var signal = controller.signal;

export function* checkEmail(action: PayloadAction<{email: string, access_code: string}>) {
    yield delay(1000);
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({...action.payload})
        }
        let result = yield call(request, CHECK_EMAIL, options);
        if (!result) {
            window.onbeforeunload = null;
        }
        yield put(signUpActions.doneCheckEmail(!!result));
    } catch (err) {
        console.log(err)
    }
}

export function* sendOtp() {
    yield delay(1000);
    try {
        let   getSignUp = (state: RootState) => state?.signUp;
        let   signup    = yield select(getSignUp);
        const data      = {...signup};
        delete data.step;
        delete data.saving;
        delete data.registered;
        delete data.verificationExpiry;
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(data)
        }
        let verificationExpiry = yield call(request, SEND_REGISTRATION_OTP, options);
        yield put(signUpActions.otpSent(verificationExpiry))
        // yield put(signUpActions.otpSent(''))
    } catch (err) {
        const error = err as ResponseError;
        if (error.response.status == 403) {
            yield put(signUpActions.setLockOutExpiry(error.data))
        }
        yield put(signUpActions.failedOtp())
    }
}

export function* verifyCodes(action: PayloadAction<{
    emailCode: string,
    smsCode: string
}>) {
    yield delay(1000);
    try {
        let getSignUp = (state: RootState) => state?.signUp;
        let signup    = yield select(getSignUp);
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({...action.payload, user: signup})
        }
        let result = yield call(request, VERIFY_CODES, options);
        yield put(signUpActions.verificationResult(result));
        if (result.email && result.sms) {
            yield put(signUpActions.nextStep());
            yield put(signUpActions.saveState());
        }
        // yield put(signUpActions.verificationResult(true));
        // yield put(signUpActions.nextStep());
    } catch (err) {
        console.log(err)
        yield put(signUpActions.verificationResult(undefined));
        const error = err as ResponseError;
        if (error.response?.status && error.response.status == 403) {
            yield put(signUpActions.setLockOutExpiry(error.data))
        }
        yield put(signUpActions.failedOtp())
    }
}

export function* createAccount() {
    yield delay(1000);
    try {
        const getSignUp = (state: RootState) => state?.signUp;
        const signup    = yield select(getSignUp);
        const user_info = {...signup.user_info}
        var options: RequestInit = {method: "POST"}
        if (signup.user_info.registration_status.documents == 'review') {
            const documents          = {...signup.user_info.document_meta}
            const business_documents = [...(documents.business_documents ||[])]

            delete documents.type
            delete documents.business_documents

            const url = DOCUMENT_UPLOAD.replace(':username', signup.username)

            const document_urls = {}
            for (const doc in documents) {
                if (documents[doc] && documents[doc].file) {
                    const uri  = documents[doc].file;
                    const blob = dataURItoBlob(uri);
                    const data = new FormData()
                    data.append('blob', blob, `blob.${documents[doc].type}`);
                    options.body = data
                    document_urls[doc] = yield call(request, url.replace(':type', doc), options);
                }
            }

            const business_document_urls: any[] = []
            for (const i in business_documents) {
                if (business_documents[i] && business_documents[i].file) {
                    const uri  = business_documents[i].file;
                    const blob = dataURItoBlob(uri);
                    const data = new FormData()
                    data.append('blob', blob, `blob.${business_documents[i].type}`);
                    options.body = data
                    business_document_urls.push(yield call(request, url.replace(':type', 'business_documents'), options));
                }
            }
            user_info.document_meta = {
                ...document_urls,
                type: signup.user_info.document_meta.type,
                business_documents: business_document_urls
            }
        }
        options = {
            method: "POST",
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({
                firstName: signup.firstName,
                lastName : signup.lastName,
                email    : signup.email,
                username : signup.username,
                password : signup.password,
                user_info: user_info
            })
        }
        const result = yield call(request, CREATE_ACCOUNT, options);
        yield put(signUpActions.setPaymentPage({
            description   : signup.description,
            profile_photo : signup.profile_photo,
            background_url: result.bg_url
        }))
        yield put(signUpActions.accountCreated(true));
    } catch (err) {
        console.log(err)
        yield put(signUpActions.accountCreated(false));
    }
}

export function* getSuggestedUsernames(action: PayloadAction<{
    firstname: string,
    lastname: string,
    number: string,
}>) {
    yield delay(1000);
    try {
        let {firstname, lastname, number} = action.payload;
        let url = new URL(SUGGESTED_USERNAMES);
        url.searchParams.append('firstname', firstname);
        url.searchParams.append('lastname', lastname);
        url.searchParams.append('number', number);
        let suggestions = yield call(request, url.toString());
        yield put(signUpActions.setSuggestedUsernames(suggestions))
    } catch (err) {
        console.log(err)
    }
}

export function* ifUsernameExists(action: PayloadAction<string>) {
    yield delay(1000);
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({
                username: action.payload
            })
        }
        let result = yield call(request, USERNAME_EXISTS, options);
        yield put(signUpActions.usernameExists(result))
    } catch (err) {
        console.log(err)
    }
}

export function* getDepositoryService(action: PayloadAction<string>) {
    yield delay(1000);
    try {
        let url    = DEPOSITORY_SERVICE.replace(':code', action.payload);
        let result = yield call(request, url);
        yield put(signUpActions.setDepositoryService(result))
    } catch (err) {
        console.log(err)
    }
}

export function* cancel() {
    controller.abort();
}

function* signupSaga() {
    yield takeLatest(signUpActions.checkEmail.type, checkEmail)
    yield takeLatest(signUpActions.sendOtp.type, sendOtp)
    yield takeLatest(signUpActions.verifyCodes.type, verifyCodes)
    yield takeLatest(signUpActions.createAccount.type, createAccount)
    yield takeLatest(signUpActions.getSuggestedUsernames.type, getSuggestedUsernames)
    yield takeLatest(signUpActions.ifUsernameExists.type, ifUsernameExists)
    yield takeLatest(signUpActions.getDepositoryService.type, getDepositoryService)
    yield takeLatest(signUpActions.cancel.type, cancel)
}

export default signupSaga;