import InputField from "app/components/InputField";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { social_medias } from "utils/options";
import { selectSignUpState } from "../../selectors";
import { signUpActions } from "../../slice";
import { useStyles } from "../styles";

interface Props {
    forReview?: boolean
    validated?: boolean
}
const SocialMedia = memo(({forReview, validated}: Props) => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const signUp   = useSelector(selectSignUpState);

    const socialProvider = "facebook";
    const socialToken    = "facebook";

    const error_message = "At least one social media account is required"
    const [error ,setError] = useState(false);
    const [social_media_links, setSocialMediaLinks] = useState(signUp.user_info?.social_media_links || {
        facebook : '',
        twitter  : '',
        instagram: '',
        linkedin : '',
        website  : '',
    });

    const set = (key, value) => {
        const socmed = {...social_media_links};
        socmed[key]  = value;
        setSocialMediaLinks(socmed);
        setError(
            !socmed.facebook &&
            !socmed.twitter &&
            !socmed.instagram &&
            !socmed.linkedin &&
            !socmed.website
        )
    }

    const updateSocialMediaLinks = () => {
        dispatch(signUpActions.setSocialMedia(social_media_links));
    }

    useEffect(() => updateSocialMediaLinks(), [])
    useEffect(() => updateSocialMediaLinks(), [social_media_links])
    
    useEffect(() => {
        if (validated) {
            setError(
                !social_media_links.facebook &&
                !social_media_links.twitter &&
                !social_media_links.instagram &&
                !social_media_links.linkedin &&
                !social_media_links.website
            )
        }
    }, [validated])

    return <>
        <section className={classes.divider}><span>SOCIAL MEDIA</span></section>
        <section className="input-group" data-social-media>
            {Object.keys(social_medias).map(social =>
                <InputField
                    type        = 'text'
                    label       = {social_medias[social].toUpperCase()}
                    name        = {social_medias[social]}
                    placeholder = 'username'
                    max         = {30}
                    error       = {error}
                    error_message = {error ? error_message : ''}
                    value       = {social_media_links[social]}
                    style       = {{
                        inputContainer    : `icon social icon-${social}`,
                        container: `${social_media_links[social]? 'valid': ''} ${classes.inputField}`
                    }}
                    onChange = {e => set(social, e.target.value)}
                />
            )}
            <InputField readonly
                type        = 'text'
                label       = 'JustPayto'
                name        = 'payment_address_2'
                placeholder = 'username'
                value       = {signUp.username}
                style       = {{
                    inputContainer    : `icon social icon-jpt`,
                    container: `${signUp.username ? 'valid' : ''} ${classes.inputField}`
                }}
            />
            <InputField
                type        = 'text'
                label       = 'Website'
                name        = 'website'
                placeholder = 'www.yourwebsite.com'
                error       = {error}
                error_message = {error ? error_message : ''}
                value       = {social_media_links.website}
                style       = {{
                    inputContainer: `icon social icon-link`,
                    container     : classes.inputField
                }}
                onChange = {e => set('website', e.target.value)}
            />
        </section>
    </>
})

export default SocialMedia;