import { selectUserInfo } from "app/common/auth/selectors";
import InputField from "app/components/InputField";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import Section from "./Section";

const AccountInfo = memo(() => {
    const user = useSelector(selectUserInfo)

    return <Section title="ACCOUNT INFO">
        <InputField readonly
            type  = "text"
            name  = "username"
            label = "Username"
            value = {user?.preferred_username || user?.username}
            valid = {!!(user?.preferred_username || user?.username)}
        />
        <InputField readonly
            type  = "text"
            name  = "payment_address_1"
            label = "Payment Address"
            value = {user?.preferred_username || user?.username}
            valid = {!!(user?.preferred_username || user?.username)}
            tooltip = {
                <div className="body">
                    <h5>What is a Payment Address?</h5>
                    <p>Your Payment Address enables you to receive money on the go! No need to worry about sharing your bank account number or giving complicated instructions. Your Payment Address is easily shareable on social media as well as your favorite chat apps.</p>
                </div>
            }
            style = {{inputContainer: 'icon social icon-jpt'}}
        />
    </Section>
})

export default AccountInfo