import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid } from "@material-ui/core";
import { Add, ExpandMore, Remove } from "@material-ui/icons";
import { selectAccessToken, selectUserInfo } from "app/common/auth/selectors";
import { SUBCATEGORY } from "app/common/core_api/resources";
import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import ErrorModal from "app/components/Modal/ErrorModal";
import SuccessModal from "app/components/Modal/SuccessModal";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import imageCompression from "browser-image-compression";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import ToggleSwitch from "../../../ToggleSwitch";
import ParameterField from "./ParameterField";

interface Props {
    id?              : string
    icon_url?        : string
    background_url?  : string
    biller_name?     : string
    slug?            : string
    status?          : string
    biller_code?     : string
    service_code?    : string
    description?     : string
    page_description?: string
    instruction?     : string
    screenshot?      : string
    category_slug?   : string
    parameters?      : any
    api_version?     : string
    // V2 Fields
    username?        : string
    logo?            : string
    name?            : string
    code?            : string
    category?        : string
    enabled?         : boolean
}

const SubCategory = memo((biller: Props) => {
    const userDashboard = useSelector(selectUserDashboard)
    const username      = useSelector(selectUserInfo)?.username
    const access_token  = useSelector(selectAccessToken)

    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [saving, setSaving] = useState(false)

    const [icon, setIcon]                       = useState(biller.icon_url || biller.logo);
    const [background, setBackground]           = useState(biller.background_url);
    const [name, setName]                       = useState(biller.biller_name || biller.name);
    const [slug, setSlug]                       = useState(biller.slug || biller.username);
    const [status, setStatus]                   = useState(biller.enabled && 'on' || biller.status);
    const [billerCode, setBillerCode]           = useState(biller.biller_code || biller.code);
    const [serviceCode, setServiceCode]         = useState(biller.service_code || biller.code);
    const [description, setDescription]         = useState(biller.description);
    const [pageDescription, setPageDescription] = useState(biller.page_description);
    const [instruction, setInstruction]         = useState(biller.instruction);
    const [screenshot, setScreenshot]           = useState(biller.screenshot);
    const [category, setCategory]               = useState(biller.category_slug || biller.category);
    const [parameters, setParameters]           = useState<any[]>(biller.parameters? JSON.parse(biller.parameters): [])

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [sucecss, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const updateSubcategory = (data, callback?: (any) => void) => {
        fetch(
            SUBCATEGORY
            .replace(":username", username || "")
            .replace(":id", biller.id  || ""),
            {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data)
            }
        )
        .then(resp => callback && callback(resp))
    }

    const toggleStatus = () => {
        setUpdatingStatus(true)
        if (username && biller.id && access_token) {
            let data = {status: status == 'on'?'off':'on'}
            updateSubcategory(data, () => {
                let newStatus = status == 'on'?'off':'on'
                setSuccess(true)
                setSuccessMessage(`Subcategory Turned ${newStatus.toUpperCase()}`)
                setUpdatingStatus(false)
                setStatus(newStatus)
            })
        } else {
            setError(true)
            setErrorMessage(
                !username? "Missing username":
                !biller.id? "Missing biller id":
                !access_token? "Unauthorized":""
            )
            setUpdatingStatus(false)
        }
    }

    const setImage = async (image, setter) => {
        setter('')
        if (!image) {
            return;
        }
        const compressedFile = await imageCompression(image, {
            maxSizeMB: 5,
            useWebWorker: true
        });
        const imageUri = await imageCompression.getDataUrlFromFile(compressedFile)
        setter(imageUri)
    }

    return <>
        <ErrorModal state={[error, setError]} title="Failed" message={errorMessage} onClose={() => setErrorMessage("")}/>
        <SuccessModal state={[sucecss, setSuccess]} message={successMessage} onClose={() => setSuccessMessage("")}/>
        <Accordion className="biller" TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary className="biller-summary" expandIcon={<ExpandMore />}>
                <Grid container>
                    <Grid item xs={10} justifyContent="center">
                        {name}
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'right'}}>
                        {
                            updatingStatus?
                            <CircularProgress size="1.125rem"/>:
                            <ToggleSwitch
                                name      = {`${slug}_status`}
                                checked   = {status == 'on'}
                                onChecked = {toggleStatus}
                            />
                        }
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
            <div className="accordion-container">
                <Grid container justifyContent="space-around">
                    <Grid item xs={biller.api_version != "2"? 6 : 9} style={{padding: "0 1rem"}}>
                        <div className="biller-details">
                            <div className="input-group">
                                <InputField
                                    type   = "file"
                                    style  = {{container: "biller-logo"}}
                                    name   = {`${slug}_logo`}
                                    accept = 'image/jpeg, image/jpg, image/png'
                                    value  = {icon || ""}
                                    onChange = {e => e.target.files && setImage(e.target.files[0], setIcon)}
                                />
                                <InputField
                                    type   = "file"
                                    style  = {{container: "biller-bg"}}
                                    name   = {`${slug}_bg`}
                                    accept = 'image/jpeg, image/jpg, image/png'
                                    value  = {background || ""}
                                    onChange = {e => e.target.files && setImage(e.target.files[0], setBackground)}
                                />
                            </div>
                            <div className="input-group">
                                <InputField
                                    type        = "text"
                                    placeholder = {`${username == 'bayadcenter'? 'Biller ' : ''}Code`}
                                    value       = {billerCode || ""}
                                    onChange    = {e => setBillerCode(e.target.value)}
                                />
                                <InputField
                                    type        = "text"
                                    placeholder = "Service Code"
                                    value       = {serviceCode || ""}
                                    onChange    = {e => setServiceCode(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <InputField
                                    type        = "text"
                                    placeholder = {`${username == 'bayadcenter'? 'Biller ' : ''}Name`}
                                    style       = {{container: "full-width"}}
                                    value       = {name || ""}
                                    onChange    = {e => setName(e.target.value)}
                                />
                                <InputField
                                    type        = "text"
                                    placeholder = "Slug"
                                    style       = {{container: "full-width"}}
                                    value       = {slug || ""}
                                    onChange    = {e => setSlug(e.target.value)}
                                />
                                <InputField
                                    type        = "text"
                                    placeholder = "Description"
                                    style       = {{container: "full-width"}}
                                    value       = {description || ""}
                                    onChange    = {e => setDescription(e.target.value)}
                                />
                                <InputField
                                    type        = "text"
                                    placeholder = "Page Description"
                                    style       = {{container: "full-width"}}
                                    value       = {pageDescription || ""}
                                    onChange    = {e => setPageDescription(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <InputField
                                    type        = "text"
                                    placeholder = "Instruction"
                                    style       = {{container: "full-width"}}
                                    value       = {instruction || ""}
                                    onChange    = {e => setInstruction(e.target.value)}
                                />
                                <InputField
                                    type   = "file"
                                    name   = {`${slug}_screenshot`}
                                    accept = 'image/jpeg, image/jpg, image/png'
                                    style  = {{container: "full-width"}}
                                    value  = {screenshot || ""}
                                    onChange = {e => e.target.files && setImage(e.target.files[0], setScreenshot)}
                                />
                            </div>
                            <div className="input-group">
                                <InputField
                                    type        = "select"
                                    placeholder = "Select Category"
                                    options     = {userDashboard.categories?.map(
                                        cat => (
                                            <option
                                                value    = {cat.slug || cat.name}
                                                selected = {(cat.slug || cat.name) == category}
                                            >
                                                {cat.name}
                                            </option>
                                        )
                                    )}
                                    style    = {{container: "full-width"}}
                                    value    = {category || ""}
                                    onChange = {e => setCategory(e.target.value)}
                                />
                            </div>
                        </div>
                    </Grid>
                    {biller.api_version != "2" && <Grid item xs={6} style={{padding: "0 1rem"}}>
                        <div className="biller-details">
                            <div className="add-remove-field">
                                <ButtonLoader
                                    style   = "filled blue is-uppercase round icon"
                                    text    = {<Add/>}
                                    onClick = {() => {
                                        const newParams = [...parameters]
                                        newParams.push({
                                            type: "text",
                                            id: "",
                                            label: "",
                                            tooltip: ""
                                        })
                                        setParameters(newParams)
                                    }}
                                />
                                <ButtonLoader
                                    style   = "filled red is-uppercase round icon"
                                    text    = {<Remove />}
                                    onClick = {() => setParameters(parameters.filter(param => !param.checked))}
                                />
                            </div>
                            {parameters?.map(
                                (param, i) => <ParameterField
                                    name      = {`${billerCode}_${i}`}
                                    parameter = {param}
                                    onChange  = {parameter => {
                                        const newParams = [...parameters]
                                        newParams[i] = parameter
                                        setParameters(newParams)
                                    }}
                                />
                            )}
                        </div>
                    </Grid>}
                </Grid>
                <ButtonLoader hasLoader
                    loading = {saving}
                    style   = "filled blue is-uppercase update-button"
                    text    = "Update"
                    onClick = {() => {
                        setSaving(true)
                        updateSubcategory({
                            status, description, instruction, screenshot,
                            slug            : slug || username,
                            icon_url        : icon,
                            background_url  : background,
                            biller_name     : name,
                            biller_code     : billerCode,
                            service_code    : serviceCode,
                            page_description: pageDescription,
                            category_slug   : category,
                            parameters      : parameters
                        }, () => {
                            setSaving(false)
                            setSuccess(true)
                            setSuccessMessage(`Subcategory Updated`)
                        })
                    }}
                />
            </div>
            </AccordionDetails>
        </Accordion>
        </>
})

export default SubCategory