import { selectUserInfo } from "app/common/auth/selectors";
import InputField from "app/components/InputField";
import ImageEditor from "app/components/Modal/ImageEditor";
import imageCompression from "browser-image-compression";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkDimension } from "utils";
import Section from "./Section";

interface Form {
    description   : string
    profile_photo : string
    background_url: string
}

interface Props {
    onChange: (form: Form) => void
}

const PaymentPage = memo(({onChange}: Props) => {
    const user = useSelector(selectUserInfo)

    // States
    const [description, setDescription]           = useState(user?.description || "");
    const [profile_photo, setProfilePhoto]        = useState(user?.profile_photo || "");
    const [background_url, setBackgroundUrl]      = useState(user?.background_url || "");
    const [imageEdit, setImageEdit]               = useState("");
    const [imageEditorModal, setImageEditorModal] = useState(false);

    // Error States
    const [errorProfilePhoto, setErrorProfilePhoto]             = useState(false);
    const [errorBackgroundUrl, setErrorBackgroundUrl]           = useState(false);
    const [errorLargeProfilePhoto, setErrorLargeProfilePhoto]   = useState(false);
    const [errorLargeBackgroundUrl, setErrorLargeBackgroundUrl] = useState(false);
    
    // Error messages
    const sizeError      = 'Image size is too small';
    const largeFileError = 'Image size is too large';

    const editImage = async (image) => {
        setImageEdit('')
        if (!image) {
            return;
        }
        if (image.size > 5000000) {
            setErrorLargeProfilePhoto(true);
            return;
        }
        const compressedFile = await imageCompression(image, {
            maxSizeMB: 5,
            useWebWorker: true
        });
        const imageUri    = await imageCompression.getDataUrlFromFile(compressedFile)
        const isValidSize = await checkDimension(imageUri)
        setImageEdit(isValidSize ? imageUri: "")
        setErrorProfilePhoto(!isValidSize)
        setErrorLargeProfilePhoto(false);
        if (isValidSize) {
            setImageEditorModal(true);
        }
    }

    const setBackgroundImage = async (file) => {
        setBackgroundUrl('');
        if (file.size > 5000000) {
            setErrorLargeBackgroundUrl(true);
            return;
        }
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 5,
            useWebWorker: true
        });
        const imageUri    = await imageCompression.getDataUrlFromFile(compressedFile)
        const isValidSize = await checkDimension(imageUri)
        setBackgroundUrl(isValidSize ? imageUri: "")
        setErrorBackgroundUrl(!isValidSize)
        setErrorLargeBackgroundUrl(false);
    }

    useEffect(() => onChange({
        description,
        profile_photo,
        background_url,
    }), [
        description,
        profile_photo,
        background_url,
    ])

    return <Section title="PAYMENT PAGE">
        <InputField 
            type             = "textarea"
            name             = "depositdescriptionory_account"
            label            = {<>Short Description (Optional) <span className="right">{description.length || 0}/140</span></>}
            placeholder      = "Your short writeup goes here"
            value            = {description}
            style            = {{container: 'full-width'}}
            onTextAreaChange = {e => setDescription(e.target.value)}
        />
        <ImageEditor state={[imageEditorModal, setImageEditorModal]} image={imageEdit}
            onSave={image => {
                setProfilePhoto(image)
                setImageEditorModal(false)
            }}
            onClose={() => {
                setImageEdit('');
            }}
        />
        <InputField
            type        = 'file'
            name        = 'profile_photo'
            label       = {user?.account_type?.type == "individual" ? 'Profile Photo' : 'Business Logo'}
            instruction = 'At least 100 x 100 pixels, max 5MB'
            accept      = 'image/jpeg, image/jpg, image/png'
            value       = {profile_photo}
            error       = {errorLargeProfilePhoto || errorProfilePhoto}
            style       = {{container: `${profile_photo? 'valid': ''}`}}
            error_message = {
                errorLargeProfilePhoto ? largeFileError:
                errorProfilePhoto ? sizeError: ""
            }
            onChange = {e => e.target.files && editImage(e.target.files[0])}
        />
        <InputField
            type        = 'file'
            name        = 'background_image'
            label       = 'Background Image (Optional)'
            instruction = 'At least 800 x 600 pixels, max 5MB'
            accept      = 'image/jpeg, image/jpg, image/png'
            value       = {background_url}
            error       = {errorLargeBackgroundUrl || errorBackgroundUrl}
            style       = {{container: `${background_url? 'valid': ''}`}}
            error_message = {
                errorLargeBackgroundUrl ? largeFileError:
                errorBackgroundUrl ? sizeError: ''
            }
            onChange = {e => e.target.files && setBackgroundImage(e.target.files[0])}
        />
    </Section>
})

export default PaymentPage