import ButtonLoader from "app/components/ButtonLoader";
import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo } from "react";

// Styles
import { selectUserInfo } from "app/common/auth/selectors";
import { useSelector } from "react-redux";
import 'styles/sass/user-dashboard.scss';
import { clients } from "utils/options";
import ApiDetails from "./ApiDetails";
import TransactionDetails from "./TransactionDetails";
import useApiHeader from "./useApiHeader";
import useTransactionHeader from "./useTransactionHeader";

interface Props extends DefaultModalProps {
    transaction?: any
}

const TransactionModal = memo(({state, transaction}: Props) => {
    const user        = useSelector(selectUserInfo);

    const is_client   = clients.includes(user?.username || "")
    const is_api      = user?.username && user?.groups?.includes(user.username)
    const is_p2p      = !is_client && !is_api

    const useHeader = is_api? useApiHeader: useTransactionHeader;
    const info = useHeader(transaction);

    const closeModal = () => state[1](false);

    return (
        <Modal state = {state} style = 'transaction larger_modal'>
            <h2 className={`status ${info.color}`}>{info.title || info.status}</h2>
            <h3 className="status-details">
                <span>{info.details}</span>
                <small className="transaction-number">
                    Transaction Number: <span>{transaction?.payment_id}</span>
                </small>
            </h3>
            <section className="divider"></section>
            {is_p2p && <>
                <div className="message">
                    <h3>
                        <span>{transaction?.sender_details?.message}</span>
                    </h3>
                    <div>- {transaction?.sender_details?.name}</div>
                </div>
                <section className="divider"></section>
            </>}
            {is_api?
                <ApiDetails transaction={transaction}/>:
                <TransactionDetails transaction={transaction}/>
            }
            <ButtonLoader hasLoader
                name   = "close_transaction_modal"
                style  = "filled blue"
                text   = "OK"
                onClick = {closeModal}
            />
        </Modal>
    )
})

export default TransactionModal;