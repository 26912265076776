import ButtonLoader from "app/components/ButtonLoader";
import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo, useState } from "react";
import SignatureCanvas from 'react-signature-canvas';
import { useStyles } from "../styles";

interface Props extends DefaultModalProps {
    saveSignature: (value) => any
}

const SignatureModal = memo(({state, saveSignature}: Props) => {
    const classes  = useStyles();
    const [show, setShow] = state;
    const [signature, setSignature] = useState<any>();
    const windowSize = document.documentElement.clientWidth < 500? document.documentElement.clientWidth: 500
    const canvasDimension = {
        width: windowSize - 120,
        height: windowSize - 120,
    }

    return(
        <Modal state={[show, setShow]} dialogClassname={classes.signatureModal}>
            <section className="base-input signature-input">
                <SignatureCanvas canvasProps={canvasDimension} ref={ref => setSignature(ref)}/>
                <button className="signature-input__undo-button" onClick={() => signature.clear()}></button>
            </section>
            <ButtonLoader
                style   = 'filled blue full-width'
                text    = 'Continue'
                onClick = {() => {
                    const image = {
                        file: signature.toDataURL(),
                        type: 'png'
                    }
                    saveSignature(image);
                    setShow(false);
                }}
            />
            <ButtonLoader
                style   = 'bordered blue full-width'
                text    = 'Cancel'
                onClick = {() => setShow(false)}
            />
        </Modal>
    )
})

export default SignatureModal;