export const field_types = {
    amount: "Amount",
    text  : "Text",
    number: "Number",
    email : "Email",
    plate : "Plate Number"
}

const icons_url = `${process.env.REACT_APP_S3_JUSTPAYTO_WEB_ASSETS_URL}/client-assets/:client/img/icons`
const icons_url_by_client = {
    autosweep   : icons_url.replace(":client", "autosweeprfid"),
    bayadcenter : icons_url.replace(":client", "bayadcenter"),
    beep        : icons_url.replace(":client", "beep"),
    easytriprfid: icons_url.replace(":client", "easytriprfid"),
}
export const icons = {
    autosweep: {
        "Account Number": `${icons_url_by_client.autosweep}/account-number.svg`,
        "Amount"        : `${icons_url_by_client.autosweep}/amount.svg`,
        "Card Number"   : `${icons_url_by_client.autosweep}/card-number.svg`,
        "CVC"           : `${icons_url_by_client.autosweep}/cvc.svg`,
        "Mobile"        : `${icons_url_by_client.autosweep}/mobile.svg`,
        "Name"          : `${icons_url_by_client.autosweep}/name.svg`,
        "Source of Fund": `${icons_url_by_client.autosweep}/source-of-fund.svg`,
        "Type of Bill"  : `${icons_url_by_client.autosweep}/type-of-bill.svg`,
        "No Icon"       : ``,
    },
    bayadcenter: {
        "Account Number": `${icons_url_by_client.bayadcenter}/account-number.svg`,
        "Amount"        : `${icons_url_by_client.bayadcenter}/amount.svg`,
        "Card Number"   : `${icons_url_by_client.bayadcenter}/card-number.svg`,
        "CVC"           : `${icons_url_by_client.bayadcenter}/cvc.svg`,
        "Mobile"        : `${icons_url_by_client.bayadcenter}/mobile.svg`,
        "Name"          : `${icons_url_by_client.bayadcenter}/name.svg`,
        "Source of Fund": `${icons_url_by_client.bayadcenter}/source-of-fund.svg`,
        "Type of Bill"  : `${icons_url_by_client.bayadcenter}/type-of-bill.svg`,
        "No Icon"       : ``,
    },
}