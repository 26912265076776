import React from 'react';
import moment from 'moment';

import {
    Avatar,
    Box,
    Button,
    Theme,
    Typography,
    createStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MountedEffect } from 'app/containers/PaymentPage/Extensions/Controller';
import { paymentPageRequestSaga } from 'app/containers/PaymentPage/saga';
import { paymentAttributes } from 'app/containers/PaymentPage/selectors';
import { reducer, sliceKey, paymentPageRequestActions } from 'app/containers/PaymentPage/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100vh !important',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& .MuiTypography': {
                '&-root': {
                    width: '100%',
                    fontFamily: theme.palette['baseFont'],
                    textAlign: 'center',
                    margin: '12px 0',
                },
                '&-subtitle2': {
                    margin: '32px 0',
                },
            },
            '& .MuiAvatar': {
                '&-root': {
                    width: '100%',
                    height: '100px',
                    margin: '12px auto',
                },
                '&-img': {
                    width: '100px',
                    height: '100px',
                    objectFit: 'cover',
                },
            },
            '& .MuiButtonBase': {
                '&-root': {
                    width: '100%',
                    maxWidth: '250px',
                    height: '32px',
                    margin: '12px 0',
                    fontSize: theme.palette['fontSize']['M'],
                    fontFamily: theme.palette['baseFont'],
                    fontWeight: theme.palette['fontWeight']['light'],
                    color: theme.palette['color']['primary']['white'],
                    letterSpacing: '2px',
                    textTransform: 'initial',
                    backgroundColor: theme.palette['color']['primary']['blue'],
                },
            },
        },
        qr: {
            display: 'block',
            '& .MuiTypography': {
                '&-root': {
                    width: '100%',
                    fontFamily: theme.palette['baseFont'],
                    textAlign: 'center',
                    margin: '12px 0',
                },
                '&-h2': {
                    fontSize: '65px',
                    '& span': {
                        display: 'block',
                        fontSize: '35px',
                        marginTop: '8px',
                    },
                    '@media (max-width: 750px)': {
                        fontSize: '35px',
                        '& span': {
                            fontSize: '28px',
                        },
                    },
                    '@media (max-width: 500px)': {
                        fontSize: '28px',
                        '& span': {
                            fontSize: '18px',
                        },
                    },
                },
                '&-subtitle2': {
                    position: 'fixed',
                    width: '100%',
                    maxWidth: '485px',
                    inset: 0,
                    top: 'unset !important',
                    padding: '10px 8px',
                    margin: 'auto',
                    background: theme.palette['color']['primary']['white'],
                    boxShadow: `0 -10px 14px ${theme.palette['color']['primary']['darkGrey']}`,
                },
            },
            '& .MuiAvatar': {
                '&-root': {
                    width: '100%',
                    height: '100%',
                    marginBottom: '135px',
                    background: theme.palette['color']['primary']['white'],
                },
                '&-img': {
                    width: '100%',
                    height: '100%',
                    maxWidth: '710px',
                    maxHeight: '710px',
                    objectFit: 'scale-down',
                    '@media (max-width: 750px)': {
                        maxWidth: '477px',
                        maxHeight: '477px',
                    },
                    '@media (max-width: 500px)': {
                        maxWidth: '325px',
                        maxHeight: '325px',
                    },
                },
            },
        },
    }),
);

function NewWindowHandler() {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: paymentPageRequestSaga });

    const dispatch  = useDispatch();
    const classes   = useStyles();
    const payAttr   = useSelector(paymentAttributes);

    /**
     * Initiate State
     */
    const [qrExpiration, setQRExpiration]           = React.useState(180);
    const [timeInterval, setTimeInterval]           = React.useState(0) as any;
    const [qrTimer, setQRTimer]                     = React.useState(<></>);
    const [transactionTitle, setTransactionTitle]   = React.useState('Transaction Successful');
    const [transactionIcon, setTransactionIcon]     = React.useState('');
    const [qrCodeAmount, setQRCodeAmount]           = React.useState('');
    const [qrCodeURL, setQRCodeURL]                 = React.useState('');

    /**
     * Initiate Functions
     */
    const closeWindow   = () => window.close();
    const getAllParams  = () => {
        let paramObj = {};

        for (let [key, value] of (new URLSearchParams(window.location.search))) {
            if (paramObj[key]) {
                if (Array.isArray(paramObj[key])) {
                    paramObj[key].push(value);
                } else {
                    paramObj[key] = [paramObj[key], value];
                }
            } else {
                try {
                    paramObj[key] = JSON.parse(value.replaceAll('\'', '"'));
                } catch {
                    paramObj[key] = value;
                }
            }
        }

        if ('threeds_status' in paramObj && paramObj['threeds_status'] == 'True') {
            setTransactionTitle('Transaction Successful');
            setTransactionIcon('https://s3-staging-justpayto-web-assets.s3-ap-southeast-1.amazonaws.com/p2p-assets/img/icons/success.svg');
        } else {
            setTransactionTitle('Transaction Failed');
            setTransactionIcon('https://s3-staging-justpayto-web-assets.s3-ap-southeast-1.amazonaws.com/p2p-assets/img/icons/error.svg');
        }

        return paramObj;
    };

    /**
     * Identify response action
     */
    MountedEffect(React.useRef(true), () => {
        let getParameter = getAllParams();

        window.newWindowResponse = getParameter;

        document.getElementById('fb-root')?.remove();

        if ('generate_qr' in getParameter) {
            window.newWindowResponse.message        = 'QRPH interrupted. Please try again.';
            window.newWindowResponse.reason_code    = 400;
            window.newWindowResponse.threeds_status = false;

            setQRCodeAmount(getParameter?.['generate_qr']?.['amount']);
            setQRCodeURL(getParameter?.['generate_qr']?.['url']);

            (async () => {
                while (!payAttr.AllBankQRPHStatus) {
                    await new Promise(resolve => setTimeout(resolve, 1000));

                    dispatch(paymentPageRequestActions.getAllBankQRPHStatus({merc_token: getParameter?.['generate_qr']?.['data']?.['merc_token']} as any));
                }
            })();
        } else {
            window.close();
        }
    }, []);
    // END

    /**
     * Calculates QRPH Expiration Time
     */
    MountedEffect(React.useRef(false), () => {
        let expiry      = moment(new Date((new Date()).getTime() + 3 * 60000)),
            interval    = setInterval(() => {
                let getBase     = moment(expiry.diff(moment())).subtract(1, 'seconds'),
                    getMinutes  = getBase.minutes(),
                    getSeconds  = getBase.seconds();

                setQRExpiration(getMinutes * 60 + getSeconds);
            }, 1000);

        setTimeInterval(interval);
    }, [qrCodeURL]);
    // END

    /**
     * Catches QR Status
     */
    MountedEffect(React.useRef(false), () => {
        if (payAttr.AllBankQRPHStatus) {
            window.newWindowResponse.message        = 'OK';
            window.newWindowResponse.reason_code    = 200;
            window.newWindowResponse.threeds_status = true;
            window.newWindowResponse.qr_response    = payAttr.AllBankQRPHResponse;

            window.close();
        }
    }, [payAttr.AllBankQRPHStatus]);
    // END

    /**
     * Stops timer to render "Resend" OTP
     */
    MountedEffect(React.useRef(false), () => {
        if (qrExpiration < 0 || qrExpiration > 200) {
            clearInterval(timeInterval);

            window.newWindowResponse.message = 'QRPH expired. Please try again.';

            if (process.env.REACT_APP_ENV != 'dev') {
                window.close();
            }
        } else {
            let duration        = moment.duration(qrExpiration, 'seconds'),
                formattedTime   = moment.utc(duration.asMilliseconds()).format('mm:ss');

            setQRTimer(<>
                <Typography variant="subtitle2">
                    QR Code expires in: <span>{formattedTime}</span>
                    <br/>
                    <br/>
                    This window will close automatically when successful transaction has been made via QRPH. Please do not close.
                </Typography>
            </>);
        }
    }, [qrExpiration]);
    // END

    return (
        <>
            <Box className={qrCodeURL ? classes.qr : classes.container}>
                {
                    qrCodeURL ? <>
                        <Typography variant="h2">
                            JustPay.to
                            <span>₱ {parseFloat(qrCodeAmount).toFixed(2)}</span>
                        </Typography>
                        <Avatar
                            alt="JustPay.to QR"
                            variant="square"
                            src={qrCodeURL}
                        />
                        {qrTimer}
                    </> : <>
                        <Typography variant="h3">
                            {transactionTitle}
                        </Typography>
                        <Avatar
                            alt="JustPay.to"
                            src={transactionIcon}
                        />
                        <Typography variant="subtitle2">
                            This window will close automatically or close manually if window did not close.
                        </Typography>
                        <Button variant="contained" onClick={closeWindow}>
                            Close
                        </Button>
                    </>
                }
            </Box>
        </>
    );
}

export default NewWindowHandler;
