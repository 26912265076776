import { WEB_ASSETS } from "app/common/common";
import { selectCountries } from "app/common/selectors";
import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import ConfirmModal from "app/components/Modal/ConfirmModal";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { depository_accounts, depository_account_categories } from "utils/options";
import { selectIP, selectSignUpState } from "../../selectors";
import { signUpActions } from "../../slice";
import { useStyles } from "../styles";

export interface FormData {
    bank?          : string
    country?       : string
    category?      : string
    account_name?  : string
    account_number?: string
}

interface Props {
    saving?        : boolean
    forReview?     : boolean
    formData       : FormData
    next?          : (d: FormData) => any
    back?          : () => any
    skip?          : () => any
}

const DepositoryDetails = memo(({saving, forReview, formData, next, back, skip}: Props) => {
    // Philippines only selectable for now
    // const countries = {PH: 'Philippines'}
    const ip_info   = useSelector(selectIP);
    const countries = useSelector(selectCountries)?.reduce(
        (countries, country) => (
            {...countries, [country.code]: country.name}
        ), {}
    );
    const classes  = useStyles();
    const dispatch = useDispatch();
    const signup   = useSelector(selectSignUpState);
    const [country, setCountry]   = useState(formData?.country || '');
    const [category, setCategory] = useState(
        Object.keys(depository_accounts.bank    ).includes(formData?.bank || '') ? "bank":
        Object.keys(depository_accounts.e_wallet).includes(formData?.bank || '') ? "e_wallet":
        Object.keys(depository_accounts.otc     ).includes(formData?.bank || '') ? "otc": ""
    );
    const [depositoryAccount, setDepositoryAccount] = useState(formData?.bank);
    const [accountName, setAccountName]             = useState(formData?.account_name);
    const [accountNumber, setAccountNumber]         = useState(formData?.account_number);

    const required_field = 'This field is required.'
    const invalid_number = 'Invalid card number'
    const [errorCountry, setErrorCountry]                     = useState(false);
    const [errorCategory, setErrorCategory]                   = useState(false);
    const [errorDepositoryAccount, setErrorDepositoryAccount] = useState(false);
    const [errorAccountName, setErrorAccountName]             = useState(false);
    const [errorAccountNumber, setErrorAccountNumber]         = useState(false);

    const [invalidNumber, setInvalidNumber] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    const _next = (data: FormData) => {
        setErrorCountry(!data.country);
        setErrorCategory(!data.category);
        setErrorDepositoryAccount(!data.bank);
        setErrorAccountName(!data.account_name);
        setErrorAccountNumber(!data.account_number)
        if (next && (
            data.country &&
            data.category &&
            data.bank &&
            data.account_name &&
            data.account_number
        )) {
            onConfirm()
            // setConfirmModal(true);
        }
    }

    useEffect(() => {
        dispatch(signUpActions.setDepositoryDetails({
            country,
            bank          : depositoryAccount || '',
            account_name  : accountName || '',
            account_number: accountNumber || ''
        }))
    }, [country, category, depositoryAccount, accountName, accountNumber])

    useEffect(() => {
        setCountry(ip_info?.countryCode || '')
    }, [ip_info?.countryCode])

    const onConfirm = () => {
        setConfirmModal(false)
        if (next) {
            next({
                country, category,
                account_name: accountName,
                account_number: accountNumber,
                bank: depositoryAccount
            });
        }
    }

    return <>
        <ConfirmModal state = {[confirmModal, setConfirmModal]} onConfirm = {onConfirm} message = "Are you sure you want to continue?"/>
        {!forReview && <section className="header">
            <h3 className={classes.header}>Where to receive money</h3>
            <div className={classes.subheader}>Select a bank account, an e-wallet, or a payout destination where you prefer to receive payments sent to you.</div>
        </section>}
        <section className={classes.divider}><span>DEPOSITORY ACCOUNT</span></section>
        {/* Form Fields:
            - Country
            - Depository Account Category
            - Depository Account
            - Account Name
            - Account Number
            */}
        <section className="input-group" data-depository-account>
            <InputField loader
                type    = 'select'
                name    = 'country'
                options = {countries && Object.keys(countries).map(country => <option value={country} disabled={country != 'PH'}>{countries[country]}</option>)}
                label   = 'Country'
                country = {country}
                value   = {country}
                valid   = {!!country}
                error   = {errorCountry}
                error_message = {errorCountry? required_field : ''}
                style   = {{container: `full-width ${classes.inputField}`}}
                placeholder = "Select Country"
                onSelectChange = {e => {
                    setCountry(e.target.value)
                    setErrorCountry(!e.target.value)
                }}
            />
            <InputField loader
                type    = 'select'
                name    = 'depository_account_category'
                options = {Object.keys(depository_account_categories).map(type => <option value={type} disabled={type == 'otc'}>{depository_account_categories[type]}</option>)}
                label   = 'Depository Account Category'
                style   = {{container: `full-width ${classes.inputField}`}}
                value   = {category}
                valid   = {!!category}
                error   = {errorCategory}
                error_message = {errorCategory? required_field : ''}
                placeholder = "Select Depository Account Category"
                onSelectChange = {e => {
                    setCategory(e.target.value)
                    setDepositoryAccount('')
                    setErrorCategory(!e.target.value)
                }}
            />
            <InputField loader
                type    = 'select'
                name    = 'depository_account'
                options = {!category? undefined: Object.keys(depository_accounts[category]).map(type => <option value={type} disabled={category == 'otc'}>{depository_accounts[category][type]}</option>)}
                label   = 'Depository Account'
                style   = {{container: `full-width ${classes.inputField}`}}
                value   = {depositoryAccount}
                valid   = {!!depositoryAccount}
                error   = {errorDepositoryAccount}
                error_message = {errorDepositoryAccount? required_field : ''}
                placeholder = "Select Depository Account"
                onSelectChange = {e => {
                    dispatch(signUpActions.getDepositoryService(e.target.value))
                    setDepositoryAccount(e.target.value)
                    setErrorDepositoryAccount(!e.target.value)
                }}
            />
            <InputField loader
                type    = 'text'
                name    = 'account_name'
                label   = 'Account Name'
                placeholder= 'Juan Dela Cruz'
                style   = {{
                    container: `full-width ${classes.inputField}`,
                    tooltip: 'small'
                }}
                tooltip = {!depositoryAccount? undefined: <>
                    <div className="body">
                        {depositoryAccount && <img data-account-name src={`${WEB_ASSETS}/tooltips/depository_cards/${depositoryAccount}-front.png`}/>}
                    </div>
                </>}
                value = {accountName}
                valid   = {!!accountName}
                error   = {errorAccountName}
                error_message = {errorAccountName? required_field : ''}
                onChange = {e => {
                    setAccountName(e.target.value)
                    setErrorAccountName(!e.target.value)
                }}
            />
            <InputField loader
                type    = 'text'
                name    = 'account_number'
                label   = 'Account Number'
                placeholder= '072894092694'
                style   = {{
                    container: `full-width ${classes.inputField}`,
                    tooltip: 'small'
                }}
                tooltip = {!depositoryAccount? undefined: <>
                    <div className="body">
                        {depositoryAccount && <img data-account-name src={`${WEB_ASSETS}/tooltips/depository_cards/${depositoryAccount}-front.png`}/>}
                    </div>
                </>}
                value = {accountNumber}
                valid   = {!!accountNumber}
                error   = {errorAccountNumber || invalidNumber}
                error_message = {
                    errorAccountNumber? required_field :
                    invalidNumber? invalid_number: ''
                }
                onChange = {e => {
                    if (category != 'e_wallet' || !isNaN(+e.target.value)) {
                        setAccountNumber(e.target.value.replace(/[^\w\s]/gi, ""))
                        setErrorAccountNumber(!e.target.value)
                        const meta = signup.depositoryService?.additional_meta
                        if (meta && meta['min_card_num']) {
                            let min = parseInt(meta['min_card_num'])
                            setInvalidNumber(min > e.target.value.length)
                        } else {
                            setInvalidNumber(false)
                        }
                    }
                }}
                max = {signup.depositoryService?.additional_meta && signup.depositoryService?.additional_meta['max_card_num'] || undefined}
            />
        </section>
        {!forReview && <section className="footer">
            <section className="buttons">
                {back && <ButtonLoader
                    name  = 'depository_account_back'
                    style = 'bordered blue'
                    text  = 'Back'
                    onClick = {back}
                />}
                <ButtonLoader hasLoader
                    loading = {saving}
                    name  = 'depository_account'
                    style = 'filled blue'
                    text  = 'Next'
                    onClick = {() => _next({
                        country, category,
                        account_name: accountName,
                        account_number: accountNumber,
                        bank: depositoryAccount
                    })}
                />
            </section>
            {skip && <ButtonLoader
                name  = 'skip_button'
                style = 'text blue'
                text  = 'Skip this step.'
                onClick = {skip}
            />}
        </section>}
    </>
});

export default DepositoryDetails;