import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import AccordionOption from './AccordionOption';

interface Props {
    label?        : string
    placeholder?  : string
    style?        : {container?: string, label?: string, input?: string}
    value?        : string
    onChange?     : (value: string, selected: string) => void
    data          : object
    error?        : boolean
    error_message?: string
}

const classes = {
    container       : (error, className) => `input-box full-width dropdown-field ${error && 'error'} ${className}`,
    input           : "input-prepend dropdown",
    inputField      : "select-field default-pointer",
    caret           : (open?: boolean) => `ui-selectmenu-icon ui-icon ui-icon-triangle-1-s caret ${open? 'open': ''}`,
    optionsContainer: (open?: boolean) => `selection ${open? 'open': ''}`,
    option          : "dropdown-item pointer",
    searchField     : "search-field",
}

const DropdownField = memo(({
    label,
    placeholder,
    style,
    value,
    onChange,
    data,
    error,
    error_message
}: Props) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const onClick = (key, selected) => {
        setOpen(false)
        if (onChange) {
            onChange(key, selected)
        }
    }
    const searchOptions = (options) => {
        return options.some(option => option.toLowerCase().includes(search.toLowerCase()))
    }

    return (
        <section
            className = {classes.container(error, style?.container || '')}
            onClick   = {() => setOpen(!open)}
        >
            <label className={style?.label || ''}><div>{label || ''}</div></label>
            <section className = {classes.input}>
                {/* Input Field */}
                <span className = {classes.caret(open)}></span>
                <input
                    type        = "text"
                    className   = {classes.inputField}
                    placeholder = {placeholder || ''}
                    value       = {value || ''}
                />
                {/* Options */}
                <div className={ `absolute-container ${open? 'open': ''}`}>
                    <section
                        className = "selection"
                        onClick   = {e => e.stopPropagation()}
                        onFocus   = {() => setOpen(true)}
                    >
                        <div className="search-bar">
                            <label className="icon icon-search">
                                <input type="text" value={search} className={style?.input || ''} onChange={e => setSearch(e.target.value)}/>
                            </label>
                        </div>
                        <div className="dropdown body">{
                            Object.keys(data).map(key => (
                                typeof data[key] == 'string'?
                                (!search || data[key].toLowerCase().includes(search.toLowerCase())) &&
                                <div
                                    className = {classes.option}
                                    onClick   = {() => onClick(key, data[key])}
                                >{data[key]}</div>:
                                (!search || searchOptions(data[key].options)) &&
                                <AccordionOption
                                    search   = {search}
                                    key      = {key}
                                    name     = {data[key].name}
                                    list     = {data[key].options}
                                    onSelect = {(value) => onClick(key, value)}
                                />
                            ))
                        }</div>
                    </section>
                </div>
                <div className={`${!error? 'valid': ''} icon icon-check`}></div>
                <div className={`${error? 'error': ''} icon icon-error`}></div>
            </section>
            <small>{error_message}</small>
        </section>
    )
})

export default DropdownField;