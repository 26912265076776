import { Grid, Typography } from "@material-ui/core";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

import FilterDate from "app/components/FilterDate";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import moment from "moment";
import { numberFormat } from "utils";
import FilterButton from '../FilterButton';

const LineGraphs = memo(({type, name, feePercent, fixedFee, revenuePercent, fixedRevenue}: any) => {
    const dispatch = useDispatch();
    const userDashboard = useSelector(selectUserDashboard);

    const [data, setData]           = useState<any>();
    const [frequency, setFrequency] = useState('daily');
    const [duration, setDuration]   = useState('week');
    const [date1, setDate1]         = useState('');
    const [date2, setDate2]         = useState('');

    const [amounts, setAmounts]   = useState<any[]>([]);
    const [counts, setCounts]     = useState<any[]>([]);
    const [averages, setAverages] = useState<any[]>([]);
    const [fees, setFees]         = useState<any[]>([]);
    const [revenues, setRevenues] = useState<any[]>([]);

    const setDateFilter = (set, value) => set(value)

    const getData = () => {
        userDashboard.analyticsData?
            filterData():
            dispatch(userDashboardPageActions.getAnalytics())
    }

    const filterData = () => {
        let start, end, data = userDashboard.analyticsData;
        if (date1 && date2) {
            start = getStartDate(date1)
            end   = getEndDate(date1)
            let data1 = data?.filter(d => moment(d['date']).isBetween(start, end, undefined, '[]')) || [];
            start = getStartDate(date2)
            end   = getEndDate(date2)
            data  = [...data1, ...(data?.filter(d => moment(d['date']).isBetween(start, end, undefined, '[]')) || [])];
        } else {
            switch (duration) {
                case 'ytd': start   = moment().startOf('year').startOf('day'); break;
                case 'qtd': start   = moment().startOf('quarter').startOf('day'); break;
                case 'mtd': start   = moment().startOf('month').startOf('day'); break;
                case 'year': start  = moment().subtract(1, 'year').startOf('day'); break;
                case 'month': start = moment().subtract(1, 'month').startOf('day'); break;
                case 'week': start  = moment().subtract(1, 'week').startOf('day'); break;
            }
            if (start) {
                end = moment().endOf('day');
                data = data?.filter(d => moment(d['date']).isBetween(start, end, undefined, '[]'));
            }
        }
        setData(data);
    }

    const getStartDate = (date) => {
        switch (duration) {
            case 'ytd'  : return moment(date).startOf('year').startOf('day');
            case 'qtd'  : return moment(date).startOf('quarter').startOf('day');
            case 'mtd'  : return moment(date).startOf('month').startOf('day');
            case 'year' : return moment(date);
            case 'month': return moment(date);
            case 'week' : return moment(date);
        }
    }

    const getEndDate = (date) => {
        switch (duration) {
            case 'ytd'  : return moment(date);
            case 'qtd'  : return moment(date);
            case 'mtd'  : return moment(date);
            case 'year' : return moment(date).add(1, 'year');
            case 'month': return moment(date).add(1, 'month');
            case 'week' : return moment(date).add(1, 'week');
        }
    }

    const computation = (amount, no_of_transactions, percent, fixed) => {
        return (percent*amount) + (fixed*no_of_transactions)
    }
    const computeAvg     = (amount, no_of_transactions) => amount/(no_of_transactions || 1)
    const computeFees    = (amount, count) => computation(amount, count, feePercent, fixedFee)
    const computeRevenue = (amount, count) => computation(amount, count, revenuePercent, fixedRevenue)
    const getGraphData   = () => {
        if (data) {
            let amounts : any = [],
                counts  : any = [],
                averages: any = [],
                fees    : any = [],
                revenues: any = [];
            if (date1 && date2) {
                let data1 = data.filter(d => (
                        moment(d.date).isBetween(
                            getStartDate(date1),
                            getEndDate(date1),
                            undefined,
                            '[]'
                        )
                    )),
                    data2 = data.filter(d => (
                        moment(d.date).isBetween(
                            getStartDate(date2),
                            getEndDate(date2),
                            undefined,
                            '[]'
                        )
                    ));
                for (var i = 0; i < data.length/2; i++) {
                    switch (frequency) {
                        case 'daily':
                            amounts.push({
                                amount1: data1[i]?.[type]?.amount || 0,
                                amount2: data2[i]?.[type]?.amount || 0
                            });
                            counts.push({
                                count1: data1[i]?.[type]?.count || 0,
                                count2: data2[i]?.[type]?.count || 0
                            });
                            averages.push({
                                amount1: computeAvg(
                                    data1[i]?.[type]?.amount || 0,
                                    data1[i]?.[type]?.count || 0
                                ),
                                amount2: computeAvg(
                                    data2[i]?.[type]?.amount || 0,
                                    data2[i]?.[type]?.count || 0
                                ),
                            })
                            fees.push({
                                amount1: computeFees(
                                    data1[i]?.[type]?.amount || 0,
                                    data1[i]?.[type]?.count || 0
                                ),
                                amount2: computeFees(
                                    data2[i]?.[type]?.amount || 0,
                                    data2[i]?.[type]?.count || 0
                                )
                            })
                            revenues.push({
                                amount1: computeRevenue(
                                    data1[i]?.[type]?.amount || 0,
                                    data1[i]?.[type]?.count || 0
                                ),
                                amount2: computeRevenue(
                                    data2[i]?.[type]?.amount || 0,
                                    data2[i]?.[type]?.count || 0
                                )
                            })
                        break;
                        case 'hourly':
                            for (var j=0; j<24; j++) {
                                let hourly1 = data1[i]?.hourly?.[j]?.[type] || {amount: 0, count: 0},
                                    hourly2 = data2[i]?.hourly?.[j]?.[type] || {amount: 0, count: 0}

                                amounts.push({
                                    amount1: hourly1.amount,
                                    amount2: hourly2.amount
                                })
                                counts.push({
                                    amount1: hourly1.count,
                                    amount2: hourly2.count
                                })
                                averages.push({
                                    amount1: computeAvg(
                                        hourly1.amount,
                                        hourly1.count
                                    ),
                                    amount2: computeAvg(
                                        hourly2.amount,
                                        hourly2.count
                                    )
                                })
                                fees.push({
                                    amount1: computeFees(
                                        hourly1.amount,
                                        hourly1.count
                                    ),
                                    amount2: computeFees(
                                        hourly2.amount,
                                        hourly2.count
                                    ),
                                })
                                revenues.push({
                                    amount1: computeRevenue(
                                        hourly1.amount,
                                        hourly1.count
                                    ),
                                    amount2: computeRevenue(
                                        hourly2.amount,
                                        hourly2.count
                                    ),
                                })
                            }
                        break;
                    }
                }
            } else {
                for (const d of data) {
                    switch (frequency) {
                        case 'daily':
                            amounts.push({amount: d[type].amount})
                            counts.push({count: d[type].count})
                            averages.push({amount: computeAvg(
                                d[type].amount,
                                d[type].count
                            )})
                            fees.push({amount: computeFees(
                                d[type].amount,
                                d[type].count
                            )})
                            revenues.push({amount: computeRevenue(
                                d[type].amount,
                                d[type].count
                            )})
                        break;
                        case 'hourly':
                            for (const h of d.hourly) {
                                amounts.push({amount: h[type].amount})
                                counts.push({count: h[type].count})
                                averages.push({amount: computeAvg(
                                    h[type].amount,
                                    h[type].count
                                )})
                                fees.push({amount: computeFees(
                                    h[type].amount,
                                    h[type].count
                                )})
                                revenues.push({amount: computeRevenue(
                                    h[type].amount,
                                    h[type].count
                                )})
                            }
                        break;
                    }
                }
            }
            setAmounts(amounts);
            setCounts(counts);
            setAverages(averages);
            setFees(fees);
            setRevenues(revenues);
        }
    }

    useEffect(getData, [userDashboard.analyticsData])
    useEffect(getGraphData, [data])
    useEffect(filterData, [frequency, duration, date1, date2])

    return <>
        <Grid container spacing={3} className="container-grid line-graph">
            <Grid container className="standard-grid">
                <Grid item xs={12}>
                    <div className="dataTables_filter">
                        <section className="date-filter-1">
                            <FilterButton status={frequency == 'hourly'} setFilter={() => setFrequency('hourly')} text="Hourly"/>
                            <FilterButton status={frequency == 'daily'}  setFilter={() => setFrequency('daily')} text="Daily"/>
                        </section>
                        <section className="date-filter-1">
                            <FilterButton status={duration == 'week'}  setFilter={() => setDuration('week') } text="1 Week"/>
                            <FilterButton status={duration == 'month'} setFilter={() => setDuration('month')} text="1 Month"/>
                            <FilterButton status={duration == 'year'}  setFilter={() => setDuration('year') } text="1 Year"/>
                            <FilterButton status={duration == 'mtd'}   setFilter={() => setDuration('mtd')  } text="Mtd"/>
                            <FilterButton status={duration == 'qtd'}   setFilter={() => setDuration('qtd')  } text="Qtd"/>
                            <FilterButton status={duration == 'ytd'}   setFilter={() => setDuration('ytd')  } text="Ytd"/>
                            <FilterButton status={duration == 'all'}   setFilter={() => setDuration('all')  } text="All"/>
                        </section>
                        <section className="date-filter-2">
                            <FilterDate direction="to" placeholder="Start Date" value={date1 || ''} set={setDate1} setRangeFilter={setDateFilter}/>
                            <span>vs</span>
                            <FilterDate direction="to" placeholder="Start Date" value={date2 || ''} set={setDate2} setRangeFilter={setDateFilter}/>
                        </section>
                    </div>
                </Grid>
            </Grid>
            <LineGraph isAmount
                title = {name}
                data  = {amounts || []}
            />
            <LineGraph
                title = {`${name} # of transactions`}
                data  = {counts || []}
            />
            <LineGraph isAmount
                title = {`${name} Average Amount per Transaction`}
                data  = {averages || []}
            />
            <LineGraph isAmount
                title = {`${name} Fees`}
                data  = {fees || []}
            />
            <LineGraph isAmount
                title = {`${name} Revenue`}
                data  = {revenues || []}
            />
        </Grid>
    </>
})

const LineGraph = memo(({title, data, isAmount}: any) => {
    const dataKey = isAmount? "amount": "count"
    const total = data?.reduce((t, d) => t +
        (d[dataKey] || 0) +
        (d[`${dataKey}1`] || 0) +
        (d[`${dataKey}2`] || 0)
    , 0)
    const amountFormatter = value => isAmount? `₱ ${numberFormat(value)}`: value

    return (
        <Grid container className="standard-grid">
            <Grid item xs={12}>
                <Typography variant="h5">{title}</Typography><br/>
                <Typography variant="h3" color="primary">{amountFormatter(total)}</Typography>
            </Grid>
            <Grid item xs={12} style={{position: "relative"}}>
                <ResponsiveContainer width={'108%'} height={180}>
                    <LineChart
                        data   = {data}
                        margin = {{
                            top   : 30,
                            right : 60,
                            left  : 60,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="4 0" horizontal={false}  />
                        <CartesianGrid />
                        <XAxis padding="no-gap" tickLine={false} label='' tick={{stroke: '#ccc'}} tickFormatter={_ => ''}/>
                        <YAxis hide/>
                        <Label />
                        <Line type="linear" dataKey={dataKey} stroke="#0099DD" dot={false}/>
                        <Line type="linear" dataKey={`${dataKey}1`} stroke="#0099DD" dot={false}/>
                        <Line type="linear" dataKey={`${dataKey}2`} stroke="#888888" dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    )
})

export default LineGraphs;